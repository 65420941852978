import ContentWrapper from '../../components/ContentWrapper';
import style from './TestForm.module.scss';
import DonateForm from '../../components/DonateForm';

const TestForm = () => {
  return (
    <ContentWrapper className={style.wrapper}>
      <DonateForm
        optionalPaymentMethods={{
          googlePay: true
        }}
        serverRequestAdditionalParams={{
          test_mode: true
        }}
      />
    </ContentWrapper>
  );
};

TestForm.propTypes = {};

export default TestForm;
