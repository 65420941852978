import style from './ErrorPayment.module.scss';
import PaymentResultNotification from '../../../components/PaymentResultNotification';
import Logo from '../../../components/Header/Logo';
import {useSearchParams} from 'react-router-dom';
import useSWR from '../../../hooks/swr/useSWR';
import NotFound from '../../NotFound';
import {useTranslation} from 'react-i18next';

const ErrorPayment = () => {
  const {t, i18n} = useTranslation('pages/payment-result');
  const [searchParams] = useSearchParams();
  const reference = searchParams.get('reference');
  let getPaymentSwrKey = null;

  if (reference) {
    getPaymentSwrKey = [
      'donate-payments',
      {
        params: {
          filters: {
            reference: {
              $eq: reference
            }
          }
        }
      }
    ];
  }

  const {data: donateData, isLoading, isError} = useSWR(getPaymentSwrKey);
  const payment = donateData?.data?.[0];

  if (!getPaymentSwrKey || (!isLoading && !isError && !payment)) {
    return <NotFound to={`/${i18n.resolvedLanguage}`} />;
  }

  let contentJsx;

  if (isLoading || isError) {
    contentJsx = (
      <div className={style.textWrapper}>
        <span className={style.text}>{isLoading ? t('loading') : t('smthgWentWrong')}</span>
      </div>
    );
  } else {
    contentJsx = (
      <PaymentResultNotification
        paymentId={payment.attributes.payment_id}
        paymentStatus={PaymentResultNotification.paymentStatuses.ERROR}
      />
    );
  }

  return (
    <div className={style.container}>
      <Logo className={style.logo} />
      {contentJsx}
    </div>
  );
};

ErrorPayment.propTypes = {};

export default ErrorPayment;
