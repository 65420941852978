import style from './Preloader.module.scss';
import LetterIcon from './LetterIcon';
import {useTranslation} from 'react-i18next';

const Preloader = () => {
  const {t} = useTranslation('pages/home');
  return (
    <div className={style.container}>
      <LetterIcon />
      <div>{t('contactUs.form.preloader.waitWhileSending')}</div>
    </div>
  );
};

Preloader.propTypes = {};

export default Preloader;
