import style from './Blog.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef, useState, useCallback, useRef, useEffect} from 'react';
import BlogArticlePreview from '../../../../components/ArticlePreview';
import BlogArticlePreviewPlaceholder from '../../../../components/ArticlePreview/Placeholder';
import ContentWrapper from '../../../../components/ContentWrapper';

import {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useWindowWidth} from '@react-hook/window-size';
import useSWR from '../../../../hooks/swr/useSWR';
import NavLink from '../../../../components/NavLink';
import classnames from 'classnames';
import {Waypoint} from 'react-waypoint';

const Blog = forwardRef((props, ref) => {
  const {t} = useTranslation('pages/home');
  const windowWidth = useWindowWidth();
  const [isSectionInViewport, setIsSectionInViewport] = useState(false);
  const swiperRef = useRef();

  useEffect(() => {
    if (isSectionInViewport) {
      swiperRef.current.swiper.autoplay.start();
    } else {
      swiperRef.current.swiper.autoplay.stop();
    }
  }, [swiperRef.current, isSectionInViewport]);

  const {data: blogArticlesData, isLoading, isError} = useSWR(['blog-articles', {params: {populate: ['cover']}}]);

  let swiperSlides;

  if (isLoading || isError) {
    swiperSlides = [
      <SwiperSlide className="fadeout" key="placeholder-blog-article-preview-1">
        <BlogArticlePreviewPlaceholder />
      </SwiperSlide>,
      <SwiperSlide className="fadeout" key="placeholder-blog-article-preview-2">
        <BlogArticlePreviewPlaceholder />
      </SwiperSlide>,
      <SwiperSlide className="fadeout" key="placeholder-blog-article-preview-3">
        <BlogArticlePreviewPlaceholder />
      </SwiperSlide>
    ];
  } else {
    const titleSymbolsLimit = 60;
    const previewTextSymbolsLimit = 200;

    swiperSlides = blogArticlesData.data.map((blogArticle, i) => {
      let title = blogArticle.attributes.title;
      let previewText = blogArticle.attributes.previewText;

      if (title.length > titleSymbolsLimit) {
        title = `${title.slice(0, titleSymbolsLimit)}...`;
      }

      if (previewText.length > previewTextSymbolsLimit) {
        previewText = `${previewText.slice(0, previewTextSymbolsLimit)}...`;
      }

      return (
        <SwiperSlide className="fadeout" key={`blog-article-preview-${i}`}>
          <BlogArticlePreview
            id={blogArticle.id}
            title={title}
            previewText={previewText}
            previewImage={blogArticle.attributes.cover.data.attributes.url}
            linkTemplate="blog/article/:id"
          />
        </SwiperSlide>
      );
    });
  }

  const onViewpointEnter = useCallback(() => {
    setIsSectionInViewport(true);
  }, []);

  const onViewpointLeave = useCallback(() => {
    setIsSectionInViewport(false);
  }, []);

  return (
    <Waypoint onEnter={onViewpointEnter} onLeave={onViewpointLeave} scrollableAncestor={window}>
      <div>
        <section className={style.container} ref={ref}>
          <ContentWrapper>
            <div className={style.titleWrapper}>
              <h1 className={style.title}>{t('blog.sectionTitle')}</h1>
              <NavLink to="blog" className={classnames('with-animated-underline', style.moreLink, style.upperMoreLink)}>
                {t('blog.more')}
              </NavLink>
            </div>
          </ContentWrapper>
          <div className={style.previews}>
            <Swiper
              ref={swiperRef}
              slidesPerView={2}
              spaceBetween={20}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
              }}
              slidesOffsetBefore={40}
              speed={15000}
              modules={[Autoplay]}
              breakpoints={{
                768: {
                  slidesOffsetBefore: 80,
                  slidesPerView: 3
                },
                1280: {
                  slidesOffsetBefore: 120,
                  slidesPerView: 3
                },
                1920: {
                  slidesOffsetBefore: (windowWidth - (1920 - 400)) / 2,
                  slidesPerView: 4
                }
              }}
              loop
              loopFillGroupWithBlank>
              {swiperSlides}
            </Swiper>
          </div>
          <NavLink to="blog" className={classnames('with-animated-underline', style.moreLink, style.bottomMoreLink)}>
            {t('blog.more')}
          </NavLink>
        </section>
      </div>
    </Waypoint>
  );
});

Blog.displayName = 'Blog';
Blog.hashRoute = '#blog';

export default Blog;
