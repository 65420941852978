import style from './Team.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import TeamMemberPreview from '../../../../components/TeamMemberPreview';
import TeamMemberPreviewPlaceholder from '../../../../components/TeamMemberPreview/Placeholder';
import ContentWrapper from '../../../../components/ContentWrapper';

import {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useWindowWidth} from '@react-hook/window-size';
import useSWR from '../../../../hooks/swr/useSWR';
import NavLink from '../../../../components/NavLink';
import classnames from 'classnames';
import {Waypoint} from 'react-waypoint';

const Team = forwardRef((props, ref) => {
  const {t} = useTranslation('pages/home');
  const windowWidth = useWindowWidth();
  const [isSectionInViewport, setIsSectionInViewport] = useState(false);
  const swiperRef = useRef();

  useEffect(() => {
    if (isSectionInViewport) {
      swiperRef.current.swiper.autoplay.start();
    } else {
      swiperRef.current.swiper.autoplay.stop();
    }
  }, [swiperRef.current, isSectionInViewport]);

  const {
    data: teamMembersData,
    isLoading,
    isError
  } = useSWR(['team-members', {params: {populate: ['preview_cover', 'social_medias', 'social_medias.logo']}}]);

  let swiperSlides;

  if (isLoading || isError) {
    swiperSlides = [
      <SwiperSlide className="fadeout" key="placeholder-team-preview-1">
        <TeamMemberPreviewPlaceholder />
      </SwiperSlide>,
      <SwiperSlide className="fadeout" key="placeholder-team-preview-2">
        <TeamMemberPreviewPlaceholder />
      </SwiperSlide>,
      <SwiperSlide className="fadeout" key="placeholder-team-preview-3">
        <TeamMemberPreviewPlaceholder />
      </SwiperSlide>
    ];
  } else {
    const nameSymbolsLimit = 60;
    const previewTextSymbolsLimit = 200;

    swiperSlides = teamMembersData.data.map((teamMember, i) => {
      let name = teamMember.attributes.name;
      let previewText = teamMember.attributes.preview_text;

      if (name.length > nameSymbolsLimit) {
        name = `${name.slice(0, nameSymbolsLimit)}...`;
      }

      if (previewText.length > previewTextSymbolsLimit) {
        previewText = `${previewText.slice(0, previewTextSymbolsLimit)}...`;
      }

      return (
        <SwiperSlide className="fadeout" key={`team-preview-${i}`}>
          <TeamMemberPreview
            id={teamMember.id}
            name={name}
            position={teamMember.attributes.position}
            previewText={previewText}
            previewImage={teamMember.attributes.preview_cover.data.attributes.url}
            linkTemplate="team/members/:id"
            socialMedias={teamMember.attributes.social_medias}
          />
        </SwiperSlide>
      );
    });
  }

  const onViewpointEnter = useCallback(() => {
    setIsSectionInViewport(true);
  }, []);

  const onViewpointLeave = useCallback(() => {
    setIsSectionInViewport(false);
  }, []);

  return (
    <Waypoint onEnter={onViewpointEnter} onLeave={onViewpointLeave} scrollableAncestor={window}>
      <div>
        <section className={style.container} ref={ref}>
          <ContentWrapper>
            <div className={style.nameWrapper}>
              <h1 className={style.name}>{t('team.sectionTitle')}</h1>
              <NavLink to="team" className={classnames('with-animated-underline', style.moreLink, style.upperMoreLink)}>
                {t('team.more')}
              </NavLink>
            </div>
          </ContentWrapper>
          <div className={style.previews}>
            <Swiper
              ref={swiperRef}
              slidesPerView={2}
              spaceBetween={20}
              autoplay={
                isSectionInViewport
                  ? {
                      delay: 1000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true
                    }
                  : false
              }
              slidesOffsetBefore={40}
              speed={15000}
              modules={[Autoplay]}
              breakpoints={{
                768: {
                  slidesOffsetBefore: 80,
                  slidesPerView: 3
                },
                1280: {
                  slidesOffsetBefore: 120,
                  slidesPerView: 3
                },
                1920: {
                  slidesOffsetBefore: (windowWidth - (1920 - 400)) / 2,
                  slidesPerView: 4
                }
              }}
              loop
              loopFillGroupWithBlank>
              {swiperSlides}
            </Swiper>
          </div>
          <NavLink to="team" className={classnames('with-animated-underline', style.moreLink, style.bottomMoreLink)}>
            {t('team.more')}
          </NavLink>
        </section>
      </div>
    </Waypoint>
  );
});

Team.displayName = 'Team';
Team.hashRoute = '#team';

export default Team;
