import style from './DonateSwift.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef} from 'react';
import SwiftDonateForm from '../../../../components/SwiftDonateForm';
import ContentWrapper from '../../../../components/ContentWrapper';
import useSWR from '../../../../hooks/swr/useSWR';

const DonateSwift = forwardRef((props, ref) => {
  const {t} = useTranslation('pages/home');

  const {
    data: swiftCreditsData,
    isLoading,
    isError
  } = useSWR(['swift-credits', {params: {populate: ['name', 'icon', 'company', 'correspondent_banks']}}]);

  let formattedSwiftCreditsData = [];

  if (!isLoading && !isError) {
    formattedSwiftCreditsData = swiftCreditsData.data.map(currencyItem => {
      const correspondentBanks = currencyItem.attributes.correspondent_banks.map(correspondentBank => ({
        account: correspondentBank.account,
        swiftCode: correspondentBank.swift_code,
        bank: correspondentBank.bank,
        purposeOfPayment: correspondentBank.purpose_of_payment,
        intermediaryBank: correspondentBank.intermediary_bank,
        intermediaryBankAccount: correspondentBank.intermediary_bank_account,
        intermediaryBankIdentificationCode: correspondentBank.intermediary_bank_identification_code
      }));

      return {
        abbr: currencyItem.attributes.abbr,
        name: {
          i18nKey: currencyItem.attributes.name.i18nKey
        },
        icon: currencyItem.attributes.icon?.data?.attributes?.url,
        company: {
          name: currencyItem.attributes.company.name,
          iban: currencyItem.attributes.company.iban,
          bank: currencyItem.attributes.company.bank,
          bankSwiftCode: currencyItem.attributes.company.bank_swift_code,
          address: currencyItem.attributes.company.address
        },
        correspondentBanks
      };
    });
  }

  return (
    <section className={style.container} ref={ref}>
      <ContentWrapper className={style.contentWrapper}>
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{t('donateSwift.sectionTitle')}</h1>
        </div>
      </ContentWrapper>
      <div className={style.content}>
        <SwiftDonateForm currencyItems={isLoading || isError ? [] : formattedSwiftCreditsData} />
      </div>
    </section>
  );
});

DonateSwift.displayName = 'DonateSwift';
DonateSwift.hashRoute = '#donate-swift';

export default DonateSwift;
