import style from './SwiftDonateForm.module.scss';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {animated, config, useSpring, useTransition} from '@react-spring/web';
import Stage2 from './stages/Stage2';
import useElementSize from '../../hooks/useElementSize';
import SearchForm from '../SearchForm';
import {useTranslation} from 'react-i18next';

const SwiftDonateForm = ({currencyItems}) => {
  const [stageNumber, setStageNumber] = useState(1);
  const [currencyAbbr, setCurrencyAbbr] = useState(null);
  const [contentRef, setContentRef] = useState(useRef(null));
  const [{height: containerHeight}] = useElementSize(contentRef);
  const [shouldAnimateHeightImmediately, setShouldAnimateHeightImmediately] = useState(true);
  const {t} = useTranslation();

  const translatedCurrencyItems = useMemo(
    () => currencyItems.map(currencyItem => ({...currencyItem, name: t(currencyItem.name.i18nKey)})),
    [currencyItems]
  );

  const containerSpringStyle = useSpring({
    minHeight: `${containerHeight}px`,
    immediate: shouldAnimateHeightImmediately,
    onRest: () => {
      shouldAnimateHeightImmediately && setShouldAnimateHeightImmediately(prevState => !prevState);
    },
    config: config.stiff
  });

  const onCurrencyResolvedHandler = useCallback(
    abbr => {
      setCurrencyAbbr(abbr);

      if (abbr) {
        setStageNumber(prevStageNumber => prevStageNumber + 1);
      } else {
        setStageNumber(prevStageNumber => prevStageNumber - 1);
      }
    },
    [setCurrencyAbbr]
  );

  const onBackButtonHandler = useCallback(
    () => setStageNumber(prevStageNumber => prevStageNumber - 1),
    [setStageNumber]
  );

  const transition = useTransition(stageNumber, {
    from: {
      x: '50%',
      opacity: 0.0
    },
    enter: {
      x: '0%',
      opacity: 1.0
    },
    leave: {
      x: '-50%',
      opacity: 0.0
    },
    onStart: () => {
      setContentRef(React.createRef());
    }
  });

  return (
    <animated.div className={style.container} style={containerSpringStyle}>
      {transition((spring, stageNumber) => {
        let stageJsx;

        if (stageNumber === 1) {
          stageJsx = (
            <SearchForm
              currencyItems={translatedCurrencyItems}
              searchInput={{
                label: t('swiftDonation.stages.1.searchInput.label'),
                placeholder: t('swiftDonation.stages.1.searchInput.placeholder')
              }}
              onItemResolved={onCurrencyResolvedHandler}
            />
          );
        } else if (stageNumber === 2) {
          const currency = currencyItems.find(currencyItem => currencyItem.abbr === currencyAbbr);
          stageJsx = <Stage2 currency={currency} onBackButtonClick={onBackButtonHandler} />;
        }

        return (
          <animated.div
            key={`Swift-StageWrapper${stageNumber}`}
            style={spring}
            className={style.stageWrapper}
            ref={contentRef}>
            {stageJsx}
          </animated.div>
        );
      })}
    </animated.div>
  );
};

SwiftDonateForm.propTypes = {
  currencyItems: PropTypes.arrayOf(
    PropTypes.shape({
      abbr: PropTypes.string.isRequired,
      name: PropTypes.shape({
        i18nKey: PropTypes.string.isRequired
      }).isRequired,
      icon: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        iban: PropTypes.string.isRequired,
        bank: PropTypes.string.isRequired,
        bankSwiftCode: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired
      }).isRequired,
      correspondentBanks: PropTypes.arrayOf(
        PropTypes.shape({
          account: PropTypes.string.isRequired,
          swiftCode: PropTypes.string.isRequired,
          bank: PropTypes.string.isRequired,
          purposeOfPayment: PropTypes.string.isRequired
        })
      ).isRequired
    }).isRequired
  )
};

export default SwiftDonateForm;
