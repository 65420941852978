import React from 'react';
import PropTypes from 'prop-types';
import style from './ErrorBoundary.module.scss';
import Logo from '../Header/Logo';
import {Trans} from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={style.container}>
          <Logo className={style.logo} />
          <div className={style.titleWrapper}>
            <h1 className={style.title}>
              <Trans i18nKey="errorBoundary.title">Sorry, there&apos;s a trouble</Trans>
            </h1>
          </div>
          <div className={style.textWrapper}>
            <span className={style.text}>
              <Trans i18nKey="errorBoundary.text">
                Please try refreshing the page. If this does not help, please contact us at{' '}
                <a href="mailto:info@geoscells.com" className="with-animated-underline">
                  info@geoscells.com
                </a>
                .
              </Trans>
            </span>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
};

export default ErrorBoundary;
