import style from './Welcome.module.scss';
import {useTranslation} from 'react-i18next';
import ButtonLink from '../../../../components/ButtonLink';
import {forwardRef} from 'react';
import ContentWrapper from '../../../../components/ContentWrapper';
import CellScene from '../../../../components/Cell/CellScene';
import Cell from '../../../../components/Cell';
import MonobankDonate from '../MonobankDonate';

const Welcome = forwardRef((props, ref) => {
  const {t} = useTranslation();
  return (
    <section className={style.container} ref={ref}>
      <ContentWrapper>
        <div className={style.cellSceneWrapper}>
          <CellScene>
            <Cell />
          </CellScene>
        </div>
        <div className={style.sloganAndButton}>
          <div className={style.sloganBlock}>{t('slogan')}</div>
          <div className={style.buttonBlock}>
            <ButtonLink to={MonobankDonate.hashRoute} size="lg">
              {t('donateButton')}
            </ButtonLink>
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
});

Welcome.displayName = 'Welcome';
Welcome.hashRoute = '';

export default Welcome;
