import * as Yup from 'yup';

export const NameFormSchema = Yup.object().shape({
  name: Yup.string().min(2, 'validationMessages.tooShort').max(60, 'tooLong').required('required')
});

export const EmailFormSchema = Yup.object().shape({
  email: Yup.string().email('validationMessages.invalidEmail').required('required')
});

export const MessageFormSchema = Yup.object().shape({
  message: Yup.string().min(20, 'validationMessages.tooShort').max(10000000, 'tooLong').required('required')
});
