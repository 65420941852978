import style from './XPaddingWrapper.module.scss';
import PropTypes from 'prop-types';

const XPaddingWrapper = ({children}) => <div className={style.XPaddingWrapper}>{children}</div>;

XPaddingWrapper.propTypes = {
  children: PropTypes.any
};

export default XPaddingWrapper;
