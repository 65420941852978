import style from './CryptoDonateForm.module.scss';
import PropTypes from 'prop-types';
import React, {useCallback, useRef, useState} from 'react';
import {animated, config, useSpring, useTransition} from '@react-spring/web';
import Stage2 from './stages/Stage2';
import Stage3 from './stages/Stage3';
import useElementSize from '../../hooks/useElementSize';
import SearchForm from '../SearchForm';
import {useTranslation} from 'react-i18next';

const CryptoDonateForm = ({coins}) => {
  const [stageNumber, setStageNumber] = useState(1);
  const [coinAbbr, setCoinAbbr] = useState(null);
  const [network, setNetwork] = useState(null);
  const [contentRef, setContentRef] = useState(useRef(null));
  const [{height: containerHeight}] = useElementSize(contentRef);
  const [shouldAnimateHeightImmediately, setShouldAnimateHeightImmediately] = useState(true);
  const {t} = useTranslation();

  const containerSpringStyle = useSpring({
    minHeight: `${containerHeight}px`,
    immediate: shouldAnimateHeightImmediately,
    onRest: () => {
      shouldAnimateHeightImmediately && setShouldAnimateHeightImmediately(prevState => !prevState);
    },
    config: config.stiff
  });

  const onCoinResolvedHandler = useCallback(
    abbr => {
      setCoinAbbr(abbr);

      if (abbr) {
        const coinItem = coins.find(item => item.abbr === abbr);
        const networks = Object.keys(coinItem.networks);
        if (networks.length === 1) {
          setNetwork(networks[0]);
          setStageNumber(3);
        } else {
          setStageNumber(prevStageNumber => prevStageNumber + 1);
        }
      } else {
        setStageNumber(prevStageNumber => prevStageNumber - 1);
      }
    },
    [setCoinAbbr, coins, setNetwork]
  );

  const onNetworkHandler = useCallback(
    network => {
      setNetwork(network);

      if (network) {
        setStageNumber(prevStageNumber => prevStageNumber + 1);
      } else {
        setStageNumber(prevStageNumber => prevStageNumber - 1);
      }
    },
    [setNetwork]
  );

  const onBackButtonHandler = useCallback(() => {
    if (stageNumber === 3) {
      const coinItem = coins.find(item => item.abbr === coinAbbr);
      const networks = Object.keys(coinItem.networks);

      if (networks.length === 1) {
        setStageNumber(1);
        return;
      }
    }

    setStageNumber(prevStageNumber => prevStageNumber - 1);
  }, [stageNumber, setStageNumber]);

  const transition = useTransition(stageNumber, {
    from: {
      x: '50%',
      opacity: 0.0
    },
    enter: {
      x: '0%',
      opacity: 1.0
    },
    leave: {
      x: '-50%',
      opacity: 0.0
    },
    onStart: () => {
      setContentRef(React.createRef());
    }
  });

  return (
    <animated.div className={style.container} style={containerSpringStyle}>
      {transition((spring, stageNumber) => {
        let stageJsx;

        if (stageNumber === 1) {
          stageJsx = (
            <SearchForm
              currencyItems={coins}
              searchInput={{
                label: t('cryptoDonation.stages.1.searchInput.label'),
                placeholder: t('cryptoDonation.stages.1.searchInput.placeholder')
              }}
              onItemResolved={onCoinResolvedHandler}
            />
          );
        } else if (stageNumber === 2) {
          const networks = Object.keys(coinAbbr ? coins.find(item => item.abbr === coinAbbr)?.networks : {});
          stageJsx = <Stage2 networks={networks} onNetworkResolved={onNetworkHandler} />;
        } else if (stageNumber === 3) {
          const coinItem = coins.find(item => item.abbr === coinAbbr);
          const wallet = coinItem.networks[network];
          stageJsx = (
            <Stage3
              wallet={wallet}
              onBackButtonClick={onBackButtonHandler}
              network={network}
              coinIcon={coinItem.icon}
            />
          );
        }

        return (
          <animated.div
            key={`StageWrapper${stageNumber}`}
            style={spring}
            className={style.stageWrapper}
            ref={contentRef}>
            {stageJsx}
          </animated.div>
        );
      })}
    </animated.div>
  );
};

CryptoDonateForm.propTypes = {
  coins: PropTypes.arrayOf(
    PropTypes.shape({
      abbr: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired
    }).isRequired
  )
};

export default CryptoDonateForm;
