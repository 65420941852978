import style from './Stage3.module.scss';
import {useCallback} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../../Button';
import {ReactComponent as CopyIcon} from './icons/copy.svg';
import copy from 'clipboard-copy';
import {useTranslation} from 'react-i18next';
import QRCode from 'qrcode.react';
import globalStyleVariables from '../../../../styles/variables.scss';
import useMatchMedia from 'use-match-media-hook';

const Stage3 = ({wallet, network, onBackButtonClick, coinIcon}) => {
  const {t} = useTranslation();

  const handleBackButtonClick = useCallback(() => {
    onBackButtonClick?.();
  }, [onBackButtonClick]);

  const [isTabletSize] = useMatchMedia(['(min-width: 768px)']);

  return (
    <div className={style.container}>
      <div className={style.backButtonWrapper}>
        <Button onClick={handleBackButtonClick} className={classnames(style.backButton, 'with-animated-underline')}>
          {t('cryptoDonation.stages.3.backButtonTitle')}
        </Button>
      </div>
      <label>{t('cryptoDonation.stages.3.networkTitle')}</label>
      <div className={classnames(style.data, style.twoColumnsWidth)}>{network}</div>
      <label>{t('cryptoDonation.stages.3.walletNumberInput.label')}</label>
      <div className={classnames(style.data, style.woPaddingBottom)}>{wallet}</div>
      <div>
        <Button onClick={() => copy(wallet)} className={classnames(style.valueCopyButton)}>
          <CopyIcon className={style.copyIcon} /> {t('cryptoDonation.stages.3.copyButtonTitle')}
        </Button>
      </div>
      <div className={classnames(style.twoColumnsWidth, style.qrCodeWrapper)}>
        <QRCode
          value={wallet}
          bgColor={globalStyleVariables.colorAltBlack}
          fgColor="#FFFFFF"
          size={isTabletSize ? 300 : 150}
          imageSettings={{
            src: coinIcon,
            width: isTabletSize ? 80 : 40,
            height: isTabletSize ? 80 : 40,
            excavate: true
          }}
          level="H"
          alt={t('cryptoDonation.stages.3.qrCodeAlt')}
        />
      </div>
    </div>
  );
};

Stage3.propTypes = {
  wallet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  network: PropTypes.string.isRequired,
  onBackButtonClick: PropTypes.func,
  coinIcon: PropTypes.string
};

export default Stage3;
