import style from './Footer.module.scss';
import ContentWrapper from '../ContentWrapper';
import {useTranslation} from 'react-i18next';
import EmailSubscriptionBlock from '../EmailSubscriptionBlock';
import PropTypes from 'prop-types';
import NavLink from '../NavLink';
import navOptions from './options';
import {ReactComponent as LogoIcon} from './logo.svg';
import BackToTopButton from '../BackToTopButton';
import {useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import useSWR from '../../hooks/swr/useSWR';
import PlaceholderTextLine from '../PlaceholderTextLine';

const Footer = ({navigationOptions}) => {
  const {t} = useTranslation();

  const {
    data: contactUsData,
    isContactUsLoading,
    isContactUsError
  } = useSWR([
    'contact-us',
    {params: {populate: ['socialMedias', 'socialMedias.logo', 'locations', 'locations.address', 'emails']}}
  ]);

  const {
    data: footerLogosData,
    isFooterLogosLoading,
    isFooterLogosError
  } = useSWR(['footer-logos', {params: {populate: ['logo']}}]);

  let contactInfoObjWithJsx;
  let footerLogosJsx;

  if (isContactUsLoading || isContactUsError) {
    contactInfoObjWithJsx = {
      phoneNumber: <PlaceholderTextLine />,
      email: <PlaceholderTextLine />,
      ourOffice: <PlaceholderTextLine />
    };
  } else {
    contactInfoObjWithJsx = {
      phoneNumber: (
        <a href={`tel:${contactUsData?.data?.attributes?.phoneNumber}`} className="with-animated-underline">
          {contactUsData?.data?.attributes?.phoneNumber}
        </a>
      ),
      emails: contactUsData?.data?.attributes?.emails?.map(email => (
        <div key={`contact-info-footer-email-${email.address}`} className={style.email}>
          <a href={`mailto:${email.address}`} className="with-animated-underline">
            {email.address}
          </a>
        </div>
      )),
      ourOffice: contactUsData?.data?.attributes?.locations?.map(location => {
        const addresses = location.address.map(address => {
          const addressLine2 = address.address_line_2 ? <div>{address.address_line_2}</div> : null;
          const addressLine3 = address.address_line_3 ? <div>{address.address_line_3}</div> : null;

          return (
            <div key={`contact-info-${location.id}-${address.id}`} className={style.address}>
              <div>{address.name}</div>
              <div>{address.address_line_1}</div>
              {addressLine2}
              {addressLine3}
            </div>
          );
        });

        return (
          <div key={`office-location-${location.id}`} className={style.location}>
            <div>{location.name.toUpperCase()}</div>
            {addresses}
          </div>
        );
      })
    };
  }

  if (!isFooterLogosLoading && !isFooterLogosError && footerLogosData) {
    footerLogosJsx = footerLogosData.data.map(fl => {
      const logoUrl = fl.attributes.logo.data.attributes.url;
      const imgJsx = (
        <img
          src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${logoUrl}`}
          alt={fl.attributes.alt}
          width={32}
          height={32}
          key={`footer-logo-${fl.id}`}
        />
      );

      if (fl.attributes.href) {
        return (
          <a
            href={fl.attributes.href}
            target="_blank"
            rel="noreferrer"
            key={`footer-logo-a-${fl.id}`}
            className="with-animated-underline">
            {imgJsx}
          </a>
        );
      } else {
        return imgJsx;
      }
    });
  }

  const socialMediasJsx = contactUsData?.data?.attributes?.socialMedias?.map(socialMedia => (
    <span key={`social-media-${socialMedia.id}`}>
      <a href={socialMedia.link} target="_blank" rel="noreferrer" className="with-animated-underline">
        <img
          src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${socialMedia.logo.data.attributes.url}`}
          alt={socialMedia.name}
          className={style.icon}
          width={32}
          height={32}
        />
      </a>
    </span>
  ));

  const linksJsx = navigationOptions.map(option => {
    const link = option.linkI18nKey
      ? `${process.env.REACT_APP_STRAPI_ENTRY_PATH}${t(option.linkI18nKey)}`
      : option.link;
    let content;

    if (!option.external) {
      content = (
        <NavLink to={link} className="with-animated-underline">
          {t(option.i18nKey)}
        </NavLink>
      );
    } else {
      content = (
        <a href={link} className="with-animated-underline" target="_blank" rel="noreferrer">
          {t(option.i18nKey)}
        </a>
      );
    }

    return <div key={`footer-nav-${link}}`}>{content}</div>;
  });

  const [emailSbnBlockYOffset, setEmailSbnBlockYOffset] = useState();
  const emailSubscriptionBlockRef = useRef();

  useResizeObserver(emailSubscriptionBlockRef, entry => {
    setEmailSbnBlockYOffset(entry.target.offsetTop);
  });

  return (
    <>
      <EmailSubscriptionBlock ref={emailSubscriptionBlockRef} />
      <BackToTopButton yOffsetToShow={emailSbnBlockYOffset} />

      <footer className={style.container}>
        <ContentWrapper>
          <div className={style.content}>
            <div className={style.group1}>{linksJsx}</div>
            <div className={style.group2}>
              <span className={style.label}>{t('contactInfo.phoneNumber')}</span>
              <span>{contactInfoObjWithJsx.phoneNumber}</span>
            </div>
            <div className={style.group3}>
              <span className={style.label}>{t('contactInfo.email')}</span>
              <span>{contactInfoObjWithJsx.emails}</span>
            </div>
            <div className={style.group4}>
              <span className={style.label}>{t('contactInfo.address')}</span>
              {contactInfoObjWithJsx.ourOffice}
            </div>
            <div className={style.group5}>
              <span className={style.label}>{t('contactInfo.socialMedia')}</span>
              <div className={style.socialIcons}>{socialMediasJsx}</div>
            </div>
            <div className={style.group6}>
              <span>
                <LogoIcon className={style.logo} />
              </span>
            </div>
            <div className={style.group7}>Geoscells © {new Date().getFullYear()}. All rights reserved.</div>
            <div className={style.group8}>
              <div className={style.group8ContentWrapper}>{footerLogosJsx}</div>
            </div>
          </div>
        </ContentWrapper>
      </footer>
    </>
  );
};

Footer.propTypes = {
  navigationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      i18nKey: PropTypes.string.isRequired,
      linkI18nKey: PropTypes.string,
      link: PropTypes.string,
      external: PropTypes.bool
    }).isRequired
  )
};

Footer.defaultProps = {
  navigationOptions: navOptions
};

export default Footer;
