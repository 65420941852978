import {usePaymentInputs} from 'react-payment-inputs';
import {Formik} from 'formik';
import ValidationSchema from './ValidationSchema';
import Form from './Form';
import PropTypes from 'prop-types';

const FormWrapper = ({optionalPaymentMethods, serverRequestAdditionalParams}) => {
  const {meta, getCardNumberProps, getExpiryDateProps, getCVCProps} = usePaymentInputs({
    errorMessages: {
      emptyCardNumber: 'validationMessages.paymentInputs.emptyCardNumber',
      invalidCardNumber: 'validationMessages.paymentInputs.invalidCardNumber',
      emptyExpiryDate: 'validationMessages.paymentInputs.emptyExpiryDate',
      monthOutOfRange: 'validationMessages.paymentInputs.monthOutOfRange',
      yearOutOfRange: 'validationMessages.paymentInputs.yearOutOfRange',
      dateOutOfRange: 'validationMessages.paymentInputs.dateOutOfRange',
      invalidExpiryDate: 'validationMessages.paymentInputs.invalidExpiryDate',
      emptyCVC: 'validationMessages.paymentInputs.emptyCVC',
      invalidCVC: 'validationMessages.paymentInputs.invalidCVC'
    }
  });

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        amount: '',
        cardNumber: '',
        expiryDate: '',
        cvc: ''
      }}
      initialTouched={{
        captchaToken: true
      }}
      validationSchema={ValidationSchema}
      onSubmit={() => undefined}
      validate={() => {
        let errors = {};
        if (meta.erroredInputs.cardNumber) {
          errors.cardNumber = meta.erroredInputs.cardNumber;
        }
        if (meta.erroredInputs.expiryDate) {
          errors.expiryDate = meta.erroredInputs.expiryDate;
        }
        if (meta.erroredInputs.cvc) {
          errors.cvc = meta.erroredInputs.cvc;
        }
        return errors;
      }}>
      {() => (
        <Form
          getCardNumberProps={getCardNumberProps}
          getExpiryDateProps={getExpiryDateProps}
          getCVCProps={getCVCProps}
          paymentInputMeta={meta}
          optionalPaymentMethods={optionalPaymentMethods}
          serverRequestAdditionalParams={serverRequestAdditionalParams}
        />
      )}
    </Formik>
  );
};

FormWrapper.propTypes = {
  optionalPaymentMethods: PropTypes.shape({
    applePay: PropTypes.bool,
    googlePay: PropTypes.bool
  }),
  serverRequestAdditionalParams: PropTypes.object
};

FormWrapper.paymentMethods = {
  CARD: 'CARD',
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY'
};

export default FormWrapper;
