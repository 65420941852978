import PropTypes from 'prop-types';
import style from './Functional.module.scss';
import ContentWrapper from '../../components/ContentWrapper';

const Functional = ({title, children}) => {
  return (
    <section className={style.container}>
      <ContentWrapper>
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{title}</h1>
        </div>
        <div className={style.content}>{children}</div>
      </ContentWrapper>
    </section>
  );
};

Functional.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default Functional;
