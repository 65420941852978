/* eslint-disable no-unused-vars */
// noinspection JSUnusedLocalSymbols

import PropTypes from 'prop-types';
import styles from './Tabs.module.scss';
import {useLayoutEffect, useRef, useState} from 'react';
import {animated, to, useSpring, useTransition} from '@react-spring/web';
import classNames from 'classnames';
import useResizeObserver from '@react-hook/resize-observer';

const Tabs = ({children}) => {
  const itemsEls = useRef([]);
  const [selected, setSelected] = useState(0);

  const [springProps, api] = useSpring(
    () => ({
      left: 0,
      top: 0,
      width: 0,
      height: 0,
      toggleProp: 0,
      rY: 'n'
    }),
    []
  );

  const updateCursor = target => {
    if (target) {
      const {width, height} = target.getBoundingClientRect();
      const {offsetTop, offsetLeft} = target;
      api.start((_, controller) => {
        const rY = controller.get().left - offsetLeft > 0 ? 1 : -1;
        return {
          width,
          height,
          top: offsetTop,
          left: offsetLeft,
          toggleProp: controller.get().toggleProp === 0 ? 1 : 0,
          rY
        };
      });
    }
  };

  useLayoutEffect(() => {
    const target = itemsEls.current[selected];
    updateCursor(target);
  }, [selected]);

  useResizeObserver(itemsEls.current?.[selected], entry => {
    const target = entry.target;
    updateCursor(target);
  });

  useLayoutEffect(() => {
    const target = itemsEls.current[selected];
    const {width, height} = target.getBoundingClientRect();
    const {offsetTop, offsetLeft} = target;
    api.set({
      width,
      height,
      top: offsetTop,
      left: offsetLeft
    });
    setSelected(0);
  }, [itemsEls]);

  const itemsJsx = children.map((item, itemIndex) => {
    const className = classNames(styles.item, {
      [styles.selected]: itemIndex === selected
    });

    return (
      <div
        role="button"
        tabIndex={0}
        className={className}
        key={`tab-item-${itemIndex}`}
        ref={el => itemsEls.current.push(el)}
        onClick={setSelected.bind(this, itemIndex)}
        onKeyDown={setSelected.bind(this, itemIndex)}>
        {item.label}
      </div>
    );
  });

  const contentTransition = useTransition(children[selected], {
    keys: item => item.label,
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    }
  });

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.tabs}>
          {itemsJsx}
          <animated.div
            className={styles.cursor}
            style={{
              ...springProps,
              transform: to(
                [
                  springProps.toggleProp.to([0, 0.5, 1], [1, 0.7, 1]),
                  springProps.rY.to([-1, -0.5, 0, 0.5, 1], [0, -50, 0, 50, 0])
                ],
                (scale, rY) => {
                  return `scale(${scale}) perspective(128px) rotateY(${rY}deg)`;
                }
              ),
              opacity: springProps.toggleProp.to([0, 0.5, 1], [1, 0.5, 1])
            }}
          />
        </div>
      </div>
      <div className={styles.content}>
        {contentTransition((springProps, item) => (
          <animated.div className={styles.contentItem} style={springProps}>
            {item.content}
          </animated.div>
        ))}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.any
    })
  ).isRequired
};

export default Tabs;
