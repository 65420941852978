import style from './Placeholder.module.scss';
import XPaddingWrapper from '../../../components/XPaddingWrapper';
import ContentWrapper from '../../../components/ContentWrapper';

const Placeholder = () => {
  return (
    <XPaddingWrapper>
      <ContentWrapper>
        <div className={style.container}>
          <div className={style.imageBlock}>
            <div className={style.canvasWrapper}>
              <div className={style.canvas} />
            </div>
          </div>
          <div className={style.textBlock}>
            <div className={style.title} />
            <div className={style.text}>
              <div className={style.row} />
              <div className={style.row} />
              <div className={style.row} />
              <div className={style.row} />
              <div className={style.row} />
              <div className={style.row} />
              <div className={style.row} />
              <div className={style.row} />
              <div className={style.row} />
            </div>
            <div className={style.buttonRow}>
              <div className={style.button} />
            </div>
          </div>
        </div>
      </ContentWrapper>
    </XPaddingWrapper>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
