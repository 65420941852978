import style from './Technology.module.scss';
import {forwardRef} from 'react';
import ContentWrapper from '../../../../components/ContentWrapper';
import VideoItem from '../../../../components/VideoItem';
import useSWR from '../../../../hooks/swr/useSWR';

const Technology = forwardRef((props, ref) => {
  const {data} = useSWR([
    'video',
    {params: {populate: ['tech_video_1', 'tech_video_1.cover', 'tech_video_2', 'tech_video_2.cover']}}
  ]);

  return (
    <ContentWrapper>
      <section className={style.container} ref={ref}>
        <VideoItem
          vimeoId={data?.data?.attributes?.tech_video_1?.vimeo_id?.toString?.()}
          title={data?.data?.attributes?.tech_video_1?.title}
          previewImageSources={data?.data?.attributes?.tech_video_1?.cover?.data?.map?.(coverItem => ({
            type: coverItem.attributes.mime,
            src: coverItem.attributes.url
          }))}
        />
        <VideoItem
          vimeoId={data?.data?.attributes?.tech_video_2?.vimeo_id?.toString?.()}
          title={data?.data?.attributes?.tech_video_2?.title}
          previewImageSources={data?.data?.attributes?.tech_video_2?.cover?.data?.map?.(coverItem => ({
            type: coverItem.attributes.mime,
            src: coverItem.attributes.url
          }))}
        />
      </section>
    </ContentWrapper>
  );
});

Technology.displayName = 'Technology';
Technology.hashRoute = '#technology';

export default Technology;
