import {Sling as Hamburger} from 'hamburger-react';
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import MenuPanel from './MenuPanel';
import style from './Menu.module.scss';
import styleVariables from '../../../styles/variables.scss';
import useMatchMedia from 'use-match-media-hook';

const Menu = (prop, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isOpen]);

  const onMenuItemClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  useImperativeHandle(ref, () => ({
    close: () => {
      isOpen && onMenuItemClick();
    }
  }));

  const [isDesktop2Size, isTabletSize] = useMatchMedia(['(min-width: 1920px)', '(min-width: 768px)']);

  const hamburgerSize = isDesktop2Size ? 40 : isTabletSize ? 35 : 16;

  return (
    <nav>
      <MenuPanel isOpen={isOpen} onItemClick={onMenuItemClick} />
      <div className={style.hamburgerWrapper}>
        <Hamburger
          label="Menu"
          direction="right"
          toggle={setIsOpen}
          toggled={isOpen}
          size={hamburgerSize}
          color={styleVariables.colorSolidWhite}
        />
      </div>
    </nav>
  );
};

export default forwardRef(Menu);
