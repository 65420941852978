import PropTypes from 'prop-types';
import Cards from 'react-credit-cards';
import './CreditCard.scss';
import Gradient from './Gradient';
import {createPortal} from 'react-dom';
import useElementOnDom from './useElementOnDom';
import {useEffect, useRef, useState} from 'react';
import colors from 'nice-color-palettes/500';
import './RevxNeue-Regular.ttf';
import './RevxNeue-SemiBold.ttf';

const CreditCard = props => {
  const commonCanvasClassname = 'card-gradient-bg-canvas';
  const frontBgId = 'card-gradient-bg-front-side';
  const backBgId = 'card-gradient-bg-back-side';
  const [isGradientsInit, setIsGradientsInit] = useState(false);
  const [isCanvasInjected, setIsCanvasInjected] = useState(false);
  const frontGradientRef = useRef();
  const backGradientRef = useRef();

  const getRandomColorIndex = () => Math.floor(Math.random() * 500);

  useEffect(() => {
    if (isCanvasInjected && !isGradientsInit) {
      const randomColorIndex = getRandomColorIndex();
      const sectionColors = colors[randomColorIndex];

      frontGradientRef.current = new Gradient(document.querySelector(`#${frontBgId}`), sectionColors);
      backGradientRef.current = new Gradient(document.querySelector(`#${backBgId}`), sectionColors);

      setIsGradientsInit(true);
    }
  }, [isCanvasInjected, isGradientsInit]);

  useEffect(() => {
    if (frontGradientRef.current && backGradientRef.current) {
      if (props.type) {
        const randomColorIndex = getRandomColorIndex();
        const sectionColors = colors[randomColorIndex];
        frontGradientRef.current.setColors(sectionColors);
        backGradientRef.current.setColors(sectionColors);
      }
    }
  }, [props.type]);

  const wrapperRef = useElementOnDom(backBgId, () => {
    if (!isCanvasInjected) {
      setIsCanvasInjected(true);
    }
  });

  const bgWrapperFront = document.getElementsByClassName('rccs__card__background')[0];
  const bgWrapperBack = document.getElementsByClassName('rccs__card__background')[1];

  const frontBgPortalJsx = bgWrapperFront
    ? createPortal(
        <canvas id={frontBgId} className={commonCanvasClassname} data-transition-in />,
        bgWrapperFront,
        frontBgId
      )
    : null;
  const backBgPortalJsx = bgWrapperBack
    ? createPortal(
        <canvas id={backBgId} className={commonCanvasClassname} data-transition-in />,
        bgWrapperBack,
        backBgId
      )
    : null;

  return (
    <>
      <div ref={wrapperRef}>
        <Cards {...props} />
      </div>
      {frontBgPortalJsx}
      {backBgPortalJsx}
    </>
  );
};

CreditCard.propTypes = {
  number: PropTypes.string,
  name: PropTypes.string,
  expiry: PropTypes.string,
  cvc: PropTypes.string,
  focused: PropTypes.string,
  type: PropTypes.string
};

export default CreditCard;
