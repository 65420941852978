import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    common: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
    }
  }
});

export default instance;
