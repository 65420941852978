const supportedLanguages = [
  {
    iso3166_2: 'ua',
    locale: 'uk-UA'
  },
  {
    iso639_1: 'en',
    locale: 'en-US'
  }
];
export default supportedLanguages;
