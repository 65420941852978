import style from './ContactUs.module.scss';
import {forwardRef, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ContactItem from './ContactItem';
import ContentWrapper from '../../../../components/ContentWrapper';
import ContactUsForm from '../../../../components/ContactUsForm';
import {animated, useTransition} from '@react-spring/web';
import classnames from 'classnames';

const ContactUs = forwardRef((_, ref) => {
  const {t} = useTranslation('pages/home');
  const [openedFormFor, setOpenedFormFor] = useState(null);

  const handleOnContactButtonClick = useCallback(
    contactAs => {
      setOpenedFormFor(contactAs);
    },
    [setOpenedFormFor]
  );

  const handleOnClickBack = useCallback(() => {
    setOpenedFormFor(null);
  }, [setOpenedFormFor]);

  const formFadeInOutTransition = useTransition(openedFormFor, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0}
  });

  return (
    <ContentWrapper>
      <section className={style.container} ref={ref}>
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{t('contactUs.sectionTitle')}</h1>
        </div>
        <div className={classnames(style.content, {[style.withForm]: !!openedFormFor})}>
          {formFadeInOutTransition((fadeInOutSpringStyle, openedFormFor) =>
            openedFormFor ? (
              <animated.div style={fadeInOutSpringStyle} className={style.formWrapper}>
                <ContactUsForm contactAs={openedFormFor} onClickClose={handleOnClickBack} />
              </animated.div>
            ) : (
              <animated.div style={fadeInOutSpringStyle} className={style.itemsContainer}>
                <ContactItem
                  contactAs="scientist"
                  title={t('contactUs.items.forScientist.title')}
                  subtitle={t('contactUs.items.forScientist.subtitle')}
                  buttonText={t('contactUs.items.forScientist.buttonText')}
                  onButtonClick={handleOnContactButtonClick}
                />
                <ContactItem
                  contactAs="investor"
                  title={t('contactUs.items.forInvestors.title')}
                  subtitle={t('contactUs.items.forInvestors.subtitle')}
                  buttonText={t('contactUs.items.forInvestors.buttonText')}
                  onButtonClick={handleOnContactButtonClick}
                />
                <ContactItem
                  contactAs="other"
                  title={t('contactUs.items.forThoseWhoWantToKnow.title')}
                  subtitle={t('contactUs.items.forThoseWhoWantToKnow.subtitle')}
                  buttonText={t('contactUs.items.forThoseWhoWantToKnow.buttonText')}
                  onButtonClick={handleOnContactButtonClick}
                />
              </animated.div>
            )
          )}
        </div>
      </section>
    </ContentWrapper>
  );
});

ContactUs.displayName = 'ContactUs';
ContactUs.hashRoute = '#contact-us';

export default ContactUs;
