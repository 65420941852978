import {animated, config, useTrail} from '@react-spring/web';
import style from './DnaLoader.module.scss';
// import dnaLoaderStyleVariables from './DnaLoader.module.scss';
import classnames from 'classnames';
import {useEffect} from 'react';
import styleVariables from '../../../styles/variables.scss';

const DnaLoader = () => {
  const ELEMENTS_PER_ROW = 5;
  const Y_MAX = 50;

  const [trail, trailApi] = useTrail(ELEMENTS_PER_ROW, () => ({
    y: -Y_MAX,
    width: 20,
    height: 20,
    borderRadius: '50%',
    upperDotsColor: styleVariables.colorGeoGreen,
    bottomDotsColor: styleVariables.colorGeoGreen,
    loop: {
      reverse: true
    },
    config: {
      ...config.stiff,
      tension: 190
    }
  }));

  const startAnimation = async () => {
    let animationResult;

    animationResult = await trailApi.start({
      y: Y_MAX,
      width: 20,
      height: 20,
      upperDotsColor: styleVariables.colorGeoGreen,
      bottomDotsColor: styleVariables.colorGeoGreen,
      borderRadius: '50%'
    })[0];

    !animationResult.cancelled &&
      (animationResult = await trailApi.start({
        y: -Y_MAX
      })[0]);

    !animationResult.cancelled &&
      (animationResult = await trailApi.start({
        y: Y_MAX
      })[0]);

    !animationResult.cancelled &&
      (await trailApi.start({
        y: -Y_MAX
      })[0]);

    !animationResult.cancelled &&
      (await trailApi.start({
        y: Y_MAX
      })[0]);

    // !animationResult.cancelled &&
    //   (animationResult = await trailApi.start({
    //     y: 0,
    //     width: 44,
    //     height: 50,
    //     upperDotsColor: dnaLoaderStyleVariables.uaFlagBlue,
    //     bottomDotsColor: dnaLoaderStyleVariables.uaFlagYellow,
    //     borderRadius: '0%'
    //   })[0]);

    // if (!animationResult.cancelled) {
    //   setTimeout(() => {
    //     startAnimation();
    //   }, 2000);
    // }

    await startAnimation();
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    startAnimation();

    return () => trailApi.stop();
  }, []);

  return (
    <div className={style.container}>
      <div className={classnames(style.row, style.upperRow)}>
        {trail.map(({y, upperDotsColor, bottomDotsColor, ...spring}, i) => (
          <animated.div
            key={`dot-upper--${i}`}
            style={{
              ...spring,
              y,
              scale: y.to({range: [-Y_MAX, 0, Y_MAX], output: [2, 1, 1]}),
              backgroundColor: upperDotsColor
            }}
            className={style.dot}
          />
        ))}
      </div>
      <div className={classnames(style.row, style.bottomRow)}>
        {trail.map(({y, upperDotsColor, bottomDotsColor, ...spring}, i) => (
          <animated.div
            key={`dot-bottom--${i}`}
            style={{
              ...spring,
              y: y.to(value => -value),
              scale: y.to(value => -value).to({range: [-Y_MAX, 0, Y_MAX], output: [2, 1, 1]}),
              backgroundColor: bottomDotsColor
            }}
            className={style.dot}
          />
        ))}
      </div>
    </div>
  );
};

DnaLoader.propTypes = {};

export default DnaLoader;
