import style from './EmailSubscriptionBlock.module.scss';
import SubscriptionForm from './SubscriptionForm';
import {Trans} from 'react-i18next';
import ContentWrapper from '../ContentWrapper';
import {forwardRef} from 'react';

const EmailSubscriptionBlock = forwardRef((_, ref) => {
  return (
    <ContentWrapper className={style.contentWrapper}>
      <div className={style.container} ref={ref}>
        <div className={style.block1}>
          <div className={style.agitationMessage}>
            <Trans i18nKey="emailSubscription.agitationMessage">
              <span className={style.part1}>Subscribe to our newsletters with respect</span>
              <span className={style.part2}>GEOSCELLS</span>
              <span className={style.part3}>company.</span>
            </Trans>
          </div>
        </div>
        <div className={style.block2}>
          <SubscriptionForm />
        </div>
      </div>
    </ContentWrapper>
  );
});

EmailSubscriptionBlock.displayName = 'EmailSubscriptionBlock';

export default EmailSubscriptionBlock;
