import {useEffect, useState} from 'react';
import useSWR from 'swr/immutable';

function useCountryCode() {
  const [countryCode, setCountryCode] = useState(null);

  const {data} = useSWR(['ip-lookup']);
  const fetchedCountryCode = data?.data?.country_code;

  useEffect(() => {
    if (fetchedCountryCode) {
      setCountryCode(fetchedCountryCode);
    }
  }, [fetchedCountryCode]);

  return countryCode;
}

export default useCountryCode;
