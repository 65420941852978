// import PropTypes from 'prop-types';
import style from './LetterIcon.module.scss';
import {useEffect, useRef, useState} from 'react';
import {animated, useSpring} from '@react-spring/web';

const LetterIcon = () => {
  const path1Ref = useRef();
  const path2Ref = useRef();
  const path3Ref = useRef();
  const path4Ref = useRef();

  const [pathLengths, setPathLengths] = useState({
    path1: null,
    path2: null,
    path3: null,
    path4: null
  });

  useEffect(() => {
    if (path1Ref) {
      setPathLengths({
        path1: path1Ref.current.getTotalLength(),
        path2: path2Ref.current.getTotalLength(),
        path3: path3Ref.current.getTotalLength(),
        path4: path4Ref.current.getTotalLength()
      });
    }
  }, [setPathLengths, path1Ref, path2Ref, path3Ref, path4Ref]);

  const path1AnimatedStyle = useSpring({
    from: {
      strokeDasharray: pathLengths.path1,
      strokeDashoffset: pathLengths.path1
    },
    to: {
      strokeDashoffset: 0
    },
    delay: 400
  });

  const path2AnimatedStyle = useSpring({
    from: {
      strokeDasharray: pathLengths.path2,
      strokeDashoffset: pathLengths.path2
    },
    to: {
      strokeDashoffset: 0
    },
    delay: 400
  });

  const path3AnimatedStyle = useSpring({
    from: {
      strokeDasharray: pathLengths.path3,
      strokeDashoffset: pathLengths.path3
    },
    to: {
      strokeDashoffset: 0
    },
    delay: 400
  });

  const path4AnimatedStyle = useSpring({
    from: {
      strokeDasharray: pathLengths.path4,
      strokeDashoffset: pathLengths.path4
    },
    to: {
      strokeDashoffset: 0
    },
    delay: 400
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 269.667 165.667"
      version="1.1"
      viewBox="0 0 269.667 165.667"
      xmlSpace="preserve"
      className={style.svg}>
      <animated.path
        fill="none"
        strokeLinecap="round"
        strokeWidth="9"
        d="M7.333 8l118.628 91.181a14.52 14.52 0 0017.726-.022L261.667 8"
        style={path1AnimatedStyle}
        ref={path1Ref}
        className={style.path}
      />
      <animated.path
        fill="none"
        strokeLinecap="round"
        strokeWidth="9"
        d="M262 157L154.233 91.011"
        style={path2AnimatedStyle}
        ref={path2Ref}
        className={style.path}
      />
      <animated.path
        fill="none"
        strokeLinecap="round"
        strokeWidth="9"
        d="M115.127 90.853L7.667 157"
        style={path3AnimatedStyle}
        ref={path3Ref}
        className={style.path}
      />
      <animated.path
        fill="none"
        strokeWidth="9"
        d="M253.5 160.778H16.056c-6.29 0-11.389-5.099-11.389-11.389v-133C4.667 10.099 9.766 5 16.056 5H253.5c6.29 0 11.389 5.099 11.389 11.389v133c0 6.29-5.099 11.389-11.389 11.389z"
        style={path4AnimatedStyle}
        ref={path4Ref}
        className={style.path}
      />
    </svg>
  );
};

LetterIcon.propTypes = {};

export default LetterIcon;
