import {Formik} from 'formik';
import ValidationSchema from './ValidationSchema';
import Form from './Form';

const FormWrapper = () => {
  return (
    <Formik
      initialValues={{
        amount: ''
      }}
      initialTouched={{
        captchaToken: true
      }}
      validationSchema={ValidationSchema}
      onSubmit={() => undefined}>
      {() => <Form />}
    </Formik>
  );
};

FormWrapper.propTypes = {};

export default FormWrapper;
