import WelcomeSection from './sections/Welcome';
import {useLocation} from 'react-router-dom';
import {useEffect, useRef} from 'react';
import AboutUsSection from './sections/AboutUs';
import ForInvestorsSection from './sections/ForInvestors';
import TechnologySection from './sections/Technology';
import SolutionSection from './sections/Solution';
import VideoSection from './sections/Video';
import RoadmapSection from './sections/Roadmap';
import ReviewsSection from './sections/Reviews';
import BlogSection from './sections/Blog';
import DonateSection from './sections/MonobankDonate';
import DonateCryptoSection from './sections/DonateCrypto';
import DonateSwiftSection from './sections/DonateSwift';
import ContactUsSection from './sections/ContactUs';
import TeamSection from './sections/Team';
import IncidenceTrendSection from './sections/IncidenceTrend';
import PartnersSection from './sections/Partners';
import MediaSection from './sections/Media';
import {config} from '@react-spring/web';
import useScrollTo from 'react-spring-scroll-to-hook';
import style from './Home.module.scss';
import usePageTitle from '../../hooks/usePageTitle';
import {useTranslation} from 'react-i18next';
import FundsCounter from './sections/FundsCounter';
import useMatchMedia from 'use-match-media-hook';

const Home = () => {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;
  const {scrollTo} = useScrollTo(config.molasses);
  const {t} = useTranslation('pages/home');

  usePageTitle(t('title'));

  const welcomeUsRef = useRef();
  const aboutUsRef = useRef();
  const teamRef = useRef();
  const forInvestorsRef = useRef();
  const incidenceTrendRef = useRef();
  const technologyRef = useRef();
  const solutionRef = useRef();
  const videoRef = useRef();
  const roadmapRef = useRef();
  const reviewsRef = useRef();
  const blogRef = useRef();
  const donateRef = useRef();
  const fundsCounterRef = useRef();
  const donateSwiftRef = useRef();
  const donateCryptoRef = useRef();
  const contactUsRef = useRef();
  const partnersUsRef = useRef();
  const mediaRef = useRef();

  const [isDesktop2Size, isDesktop1Size, isTabletSize] = useMatchMedia([
    '(min-width: 1920px)',
    '(min-width: 1280px)',
    '(min-width: 768px)'
  ]);

  useEffect(() => {
    let scrollFn = null;

    if (location.hash === WelcomeSection.hashRoute || (!location.hash && !!previousLocation)) {
      scrollFn = () => scrollTo(welcomeUsRef.current);
    } else if (location.hash === AboutUsSection.hashRoute) {
      scrollFn = () => scrollTo(aboutUsRef.current);
    } else if (location.hash === TeamSection.hashRoute) {
      scrollFn = () => scrollTo(teamRef.current);
    } else if (location.hash === ForInvestorsSection.hashRoute) {
      scrollFn = () => scrollTo(forInvestorsRef.current);
    } else if (location.hash === IncidenceTrendSection.hashRoute) {
      scrollFn = () => scrollTo(incidenceTrendRef.current);
    } else if (location.hash === TechnologySection.hashRoute) {
      scrollFn = () => scrollTo(technologyRef.current);
    } else if (location.hash === SolutionSection.hashRoute) {
      scrollFn = () => scrollTo(solutionRef.current);
    } else if (location.hash === VideoSection.hashRoute) {
      scrollFn = () => scrollTo(videoRef.current);
    } else if (location.hash === RoadmapSection.hashRoute) {
      scrollFn = () => scrollTo(roadmapRef.current);
    } else if (location.hash === ReviewsSection.hashRoute) {
      scrollFn = () => scrollTo(reviewsRef.current);
    } else if (location.hash === BlogSection.hashRoute) {
      scrollFn = () => scrollTo(blogRef.current);
    } else if (location.hash === FundsCounter.hashRoute) {
      scrollFn = () => scrollTo(fundsCounterRef.current);
    } else if (location.hash === DonateSection.hashRoute) {
      scrollFn = () => scrollTo(donateRef.current);
    } else if (location.hash === DonateSwiftSection.hashRoute) {
      scrollFn = () => scrollTo(donateSwiftRef.current);
    } else if (location.hash === DonateCryptoSection.hashRoute) {
      scrollFn = () => scrollTo(donateCryptoRef.current);
    } else if (location.hash === PartnersSection.hashRoute) {
      scrollFn = () => scrollTo(partnersUsRef.current);
    } else if (location.hash === ContactUsSection.hashRoute) {
      scrollFn = () => scrollTo(contactUsRef.current);
    } else if (location.hash === MediaSection.hashRoute) {
      scrollFn = () => scrollTo(mediaRef.current);
    }

    scrollFn?.();
  }, [location.key, isDesktop2Size, isDesktop1Size, isTabletSize]);

  return (
    <div className={style.sectionsWrapper}>
      <WelcomeSection ref={welcomeUsRef} />
      <TechnologySection ref={technologyRef} />
      <AboutUsSection ref={aboutUsRef} />
      <TeamSection ref={teamRef} />
      <SolutionSection ref={solutionRef} />
      <IncidenceTrendSection ref={incidenceTrendRef} />
      <VideoSection ref={videoRef} />
      <ReviewsSection ref={reviewsRef} />
      <BlogSection ref={blogRef} />
      <RoadmapSection ref={roadmapRef} />
      <FundsCounter ref={fundsCounterRef} />
      <ForInvestorsSection ref={forInvestorsRef} />
      <PartnersSection ref={partnersUsRef} />
      <DonateSection ref={donateRef} />
      <DonateSwiftSection ref={donateSwiftRef} />
      <DonateCryptoSection ref={donateCryptoRef} />
      <ContactUsSection ref={contactUsRef} />
      <MediaSection ref={mediaRef} />
    </div>
  );
};

Home.propTypes = {};

export default Home;
