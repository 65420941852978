import PropTypes from 'prop-types';
import style from './Navigation.module.scss';
import options from './options';
import {useTranslation} from 'react-i18next';
import HLanguageSwitcher from './HLanguageSwitcher';
import Link from '../../Link';

const Navigation = ({options}) => {
  const {t} = useTranslation();
  if (!options) {
    return null;
  }
  const linksJsx = options.map(option => (
    <Link key={`header-nav-${option.link}`} to={option.link} className="with-animated-underline">
      {t(option.i18nKey)}
    </Link>
  ));
  return (
    <nav className={style.container}>
      <div className={style.navLinks}>{linksJsx}</div>
      <HLanguageSwitcher />
    </nav>
  );
};

Navigation.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      i18nKey: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  )
};

Navigation.defaultProps = {
  options: options
};

export default Navigation;
