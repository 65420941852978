import PhoneInput, {getCountries} from 'react-phone-number-input';
import style from './PhonenumberInput.module.scss';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import uaLabels from 'react-phone-number-input/locale/ua';
import enLabels from 'react-phone-number-input/locale/en';
import useCountryCode from '../../../hooks/useCountryCode';
import PropTypes from 'prop-types';
import GlobeIcon from './GlobeIcon';
import {useEffect, useMemo, useState} from 'react';

const SHIT_CODES = ['RU', 'BY'];

const PhonenumberInput = ({onChange, className, ...otherProps}) => {
  let defaultCountryCode = useCountryCode();
  defaultCountryCode = SHIT_CODES.includes(defaultCountryCode) ? 'UA' : defaultCountryCode;

  const [country, setCountry] = useState();
  const countryCodes = useMemo(() => {
    return getCountries().filter(countryCode => !SHIT_CODES.includes(countryCode));
  }, [country]);
  const [sortedCountryCodes, setSortedCountryCodes] = useState(countryCodes);

  useEffect(() => {
    if (defaultCountryCode) {
      setCountry(defaultCountryCode);
      setSortedCountryCodes(
        sortedCountryCodes.sort((x, y) => (x === defaultCountryCode ? -1 : y === defaultCountryCode ? 1 : 0))
      );
    }
  }, [defaultCountryCode]);

  const onCountryChange = country => {
    setCountry(country);
    if (country) {
      setSortedCountryCodes(sortedCountryCodes.sort((x, y) => (x === country ? -1 : y === country ? 1 : 0)));
    }
  };

  const {i18n} = useTranslation();
  const labels = i18n.language === 'ua' ? uaLabels : enLabels;

  return (
    <PhoneInput
      {...otherProps}
      onChange={onChange}
      className={classNames(style.container, className)}
      defaultCountry={country}
      labels={labels}
      countries={sortedCountryCodes}
      internationalIcon={GlobeIcon}
      onCountryChange={onCountryChange}
      countryOptionsOrder={[...sortedCountryCodes, '|', '🌐']}
      focusInputOnCountrySelection
    />
  );
};

PhonenumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default PhonenumberInput;
