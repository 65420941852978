import style from './DonateCancel.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import Button from '../../components/Button';
import {useSearchParams} from 'react-router-dom';
import useSWR from '../../hooks/swr/useSWR';
import Logo from '../../components/Header/Logo';
import {useCallback, useState} from 'react';
import axios from '../../services/axios';
import NotFound from '../NotFound';

const DonateCancel = () => {
  const {t, i18n} = useTranslation('pages/donate-cancel');
  const [searchParams] = useSearchParams();
  const reference = searchParams.get('reference');
  let getSubscriptionSwrKey = null;
  const [cancelStatus, setCancelStatus] = useState(null);

  if (reference) {
    getSubscriptionSwrKey = [`donate-monthly-subscriptions/${reference}`, {}];
  }

  const {data: subscriptionData, isLoading} = useSWR(getSubscriptionSwrKey);

  const onButtonClickHandler = useCallback(async () => {
    try {
      await axios.patch(`/donate-monthly-subscriptions/${reference}`);

      setCancelStatus('canceled');
    } catch (e) {
      console.error(e);
      setCancelStatus('error');
    }
  }, [i18n.language, reference]);

  if (!getSubscriptionSwrKey) {
    return <NotFound to="/" />;
  }

  let contentJsx;
  let buttonJsx = null;

  if (isLoading) {
    contentJsx = t('loading');
  } else if (cancelStatus === 'error') {
    contentJsx = t('smthgWentWrong');
  } else if (cancelStatus === 'canceled') {
    contentJsx = t('canceled');
  } else {
    const subscription = subscriptionData?.data;

    if (subscription?.attributes?.status === 'canceled') {
      contentJsx = t('alreadyCanceled');
    } else {
      const payerName = subscription?.attributes?.payer_name;
      contentJsx = (
        <Trans i18nKey="text" ns="pages/donate-cancel">
          <span className={style.textLine}>
            Hello, <span className={style.payerName}>{{payerName}}</span>
          </span>
          <span className={style.textLine}>Do you really want to cancel donate subscription?</span>
        </Trans>
      );
      buttonJsx = <Button onClick={onButtonClickHandler}>{t('buttonTitle')}</Button>;
    }
  }

  return (
    <div className={style.container}>
      <Logo className={style.logo} />
      <div className={style.titleWrapper}>
        <h1 className={style.title}>{t('title')}</h1>
      </div>
      <div className={style.textWrapper}>
        <span className={style.text}>{contentJsx}</span>
      </div>
      {buttonJsx}
    </div>
  );
};

DonateCancel.propTypes = {};

export default DonateCancel;
