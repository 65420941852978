import PropTypes from 'prop-types';
import {useEffect, useId, useState} from 'react';
import style from './Checkbox.module.scss';
import classNames from 'classnames';
import {ImCheckmark} from 'react-icons/im';
import {animated, config, useTransition} from '@react-spring/web';

const AnimatedCheckmark = animated(ImCheckmark);

const Checkbox = ({checked = false, onChange, className}) => {
  const id = useId();
  const [isChecked, setIsChecked] = useState(checked);

  const toggleState = () => {
    setIsChecked(prevState => !prevState);
  };

  useEffect(() => {
    onChange?.(isChecked);
  }, [isChecked]);

  const _className = classNames(style.visibleCheckbox, className);

  const checkIconTransition = useTransition(isChecked, {
    from: {
      opacity: 0,
      transform: 'scale(0.5)'
    },
    enter: {
      opacity: 1,
      transform: 'scale(1)'
    },
    leave: {
      opacity: 0,
      transform: 'scale(0.5)'
    },
    config: config.wobbly
  });

  return (
    <label htmlFor={id} className={style.container}>
      <input type="checkbox" id={id} className={style.checkbox} checked={isChecked} onChange={toggleState} />
      <div className={_className} tabIndex={0} role="checkbox" aria-checked={isChecked}>
        {checkIconTransition((springStyle, isChecked) => {
          if (!isChecked) {
            return null;
          }

          return <AnimatedCheckmark style={springStyle} className={style.checkmark} />;
        })}
      </div>
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

export default Checkbox;
