import style from './Placeholder.module.scss';
import ContentWrapper from '../../../components/ContentWrapper';
import XPaddingWrapper from '../../../components/XPaddingWrapper';

const Placeholder = () => {
  return (
    <XPaddingWrapper>
      <ContentWrapper>
        <section className={style.container}>
          <div className={style.imageBlock}>
            <div className={style.canvasWrapper} />
          </div>
          <div className={style.textBlock}>
            <div className={style.title} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
            <div className={style.textRow} />
          </div>
          <div className={style.metaInfo}>
            <div className={style.metaItem}>
              <div className={style.propertyName} />
              <div className={style.author}>
                <div className={style.avatar} />
                <div className={style.name} />
              </div>
            </div>
            <div className={style.metaItem}>
              <div className={style.propertyName} />
              <div className={style.propertyValue} />
            </div>
          </div>
        </section>
      </ContentWrapper>
    </XPaddingWrapper>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
