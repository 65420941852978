import PropTypes from 'prop-types';
import style from './Review.module.scss';
import classnames from 'classnames';

const Review = ({author, comment, className}) => {
  return (
    <div className={classnames(style.container, className)}>
      <img
        src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${author.avatarSrc}`}
        alt="Reviewer Avatar"
        className={style.avatar}
        width={70}
        height={70}
      />
      <div className={style.authorName}>{author.name}</div>
      <div className={style.authorPosition}>{author.position}</div>
      <blockquote className={style.comment}>{comment}</blockquote>
    </div>
  );
};

Review.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.string,
    avatarSrc: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
  comment: PropTypes.string.isRequired
};

export default Review;
