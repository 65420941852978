import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import supportedLanguages from '../services/i18n/supportedLanguages';
import PropTypes from 'prop-types';
import {useSWRConfig} from 'swr';

const LanguageSwitcher = ({children}) => {
  const {i18n} = useTranslation();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {mutate: mutateSWR} = useSWRConfig();

  const _getNewPath = useCallback(
    lang => {
      const langPrefixInUse = supportedLanguages
        .map(l => l.iso639_1 || l.iso3166_2)
        .find(lang => pathname.startsWith(`/${lang}`));
      if (langPrefixInUse) {
        const pathWithoutLangPrefix = pathname.replace(`/${langPrefixInUse}`, '');
        return `/${lang}${pathWithoutLangPrefix}`;
      } else {
        return `/${lang}${pathname}`;
      }
    },
    [i18n, pathname]
  );
  const _changeLanguage = useCallback(
    lang => {
      i18n.changeLanguage(lang);
      mutateSWR();
    },
    [i18n]
  );
  const _changePath = useCallback(
    lang => {
      const newPath = _getNewPath(lang);
      navigate(newPath);
    },
    [i18n]
  );
  const _onClick = useCallback(lang => {
    _changeLanguage(lang);
    _changePath(lang);
  });

  return supportedLanguages.map(lang => {
    const changeLanguage = () => _changeLanguage(lang.iso639_1 || lang.iso3166_2);
    const changePath = () => _changePath(lang.iso639_1 || lang.iso3166_2);
    const onClick = () => _onClick(lang.iso639_1 || lang.iso3166_2);
    const getNewPath = () => _getNewPath(lang.iso639_1 || lang.iso3166_2);
    const isActive = (lang.iso639_1 || lang.iso3166_2) === i18n.resolvedLanguage;
    return children?.({
      lang: lang.iso639_1 || lang.iso3166_2,
      isActive,
      getNewPath,
      changeLanguage,
      changePath,
      onClick
    });
  });
};

LanguageSwitcher.propTypes = {
  children: PropTypes.func.isRequired
};
export default LanguageSwitcher;
