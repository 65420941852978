import {useGLTF, Float, meshBounds} from '@react-three/drei';

const Index = props => {
  const {nodes, materials} = useGLTF('/stem-cell.gltf');
  return (
    <group {...props} dispose={null}>
      <group>
        <group name="Cube001" rotation={[0.3, -0.2, 2.81]} scale={1} userData={{name: 'Cube.001'}}>
          <Float speed={3} rotationIntensity={3} floatIntensity={2}>
            <mesh raycast={meshBounds} name="mesh_0" geometry={nodes.mesh_0.geometry} material={materials.Material} />
            <mesh
              raycast={meshBounds}
              name="mesh_0_1"
              geometry={nodes.mesh_0_1.geometry}
              material={materials['Material.001']}
            />
          </Float>
        </group>
      </group>
    </group>
  );
};

export default Index;
