import style from './Header.module.scss';
import Wrapper from './Wrapper';
import Logo from './Logo';
import Navigation from './Navigation';
import Menu from './Menu';
import {useCallback, useRef, useSyncExternalStore} from 'react';
import classnames from 'classnames';
import state from '../MainContainer/scrollTopStore';
import SCROLL_DIRECTION from '../MainContainer/constants';

const Header = () => {
  const scrolledDownMoreThen100 = useSyncExternalStore(state.subscribe, () => {
    const actualScrollTop = state.getScrollTop();
    return actualScrollTop > 100;
  });

  const scrollDirection = useSyncExternalStore(state.subscribe, () => state.getDirection());

  const menuRef = useRef();

  const onLogoClickHandler = useCallback(() => {
    menuRef.current.close();
  }, []);

  return (
    <header
      className={classnames(style.header, {
        [style.hidden]: scrollDirection === SCROLL_DIRECTION.DOWN,
        [style.withBgColor]: scrolledDownMoreThen100
      })}>
      <Wrapper>
        <Menu ref={menuRef} />
        <div className={style.content}>
          <Logo className={style.logoWrapper} onClick={onLogoClickHandler} />
          <Navigation />
        </div>
      </Wrapper>
    </header>
  );
};

Header.propTypes = {};

export default Header;
