import {useEffect, useRef} from 'react';

function useElementOnDom(id, callback) {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = document.getElementById(id);

    if (element) {
      callback(element);
    } else {
      // Якщо елемент ще не з'явився в DOM, спостерігаємо за ним
      const observer = new MutationObserver(mutationsList => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            const addedNode = mutation.addedNodes[0];
            if (addedNode.id === id) {
              callback(addedNode);
              observer.disconnect();
            }
          }
        }
      });

      observer.observe(document.body, {childList: true, subtree: true});
    }
  }, [id, callback]);

  return elementRef;
}

export default useElementOnDom;
