import classnames from 'classnames';
import style from './BackToTopButton.module.scss';
import {ReactComponent as UpArrowIcon} from '../Footer/upArrow.svg';
import {animated, config, useSpring} from '@react-spring/web';
import {useCallback, useSyncExternalStore} from 'react';
import {useWindowHeight} from '@react-hook/window-size';
import state from '../MainContainer/scrollTopStore';
import useMatchMedia from 'use-match-media-hook';
import useScrollTo from 'react-spring-scroll-to-hook';
import PropTypes from 'prop-types';

const BackToTopButton = ({yOffsetToShow}) => {
  const {scrollTo} = useScrollTo(config.molasses);

  const scrollToTopHandler = useCallback(() => {
    scrollTo?.();
  }, [scrollTo]);

  const windowHeight = useWindowHeight();

  const scrollPercent = useSyncExternalStore(state.subscribe, () => {
    const scrollTopOffset = state.getScrollTop();
    const scrollHeight = document.body.scrollHeight - windowHeight;
    return scrollHeight ? Math.round((scrollTopOffset / scrollHeight) * 100) : 0;
  });

  const scrollTopOffset = useSyncExternalStore(state.subscribe, () => (state.getScrollTop() || 0) + windowHeight);

  const [isDesktop2Size, isDesktop1Size, isTabletSize] = useMatchMedia([
    '(min-width: 1920px)',
    '(min-width: 1280px)',
    '(min-width: 768px)'
  ]);

  const backToTopButtonStyle = useSpring({scrollPercent});
  const backToTopIntOutput = isDesktop2Size ? [80, 14] : isDesktop1Size ? [80, 38] : isTabletSize ? [80, 26] : [80, 80];

  return (
    <animated.button
      type="button"
      className={classnames(style.toTopButton, {[style.toTopButtonVisible]: scrollTopOffset > yOffsetToShow})}
      onClick={scrollToTopHandler}
      style={{
        top: backToTopButtonStyle.scrollPercent.to([0, 100], backToTopIntOutput).to(value => `${value}%`)
      }}>
      <UpArrowIcon className={style.upArrow} />
    </animated.button>
  );
};

BackToTopButton.propTypes = {
  yOffsetToShow: PropTypes.number
};

BackToTopButton.defaultProps = {
  yOffsetToShow: 1000
};

export default BackToTopButton;
