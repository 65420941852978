import {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import PreviewSection from '../../../../components/PreviewSection';
import useSWR from '../../../../hooks/swr/useSWR';
import PreviewSectionPlaceholder from '../../../../components/PreviewSection/Placeholder';

const Solution = forwardRef((_, ref) => {
  const {t} = useTranslation('pages/home');

  const {
    data: solutionPage,
    isLoading,
    isError
  } = useSWR(['solution', {params: {populate: ['article', 'article.cover']}}]);

  return isLoading || isError ? (
    <PreviewSectionPlaceholder />
  ) : (
    <PreviewSection
      title={solutionPage?.data?.attributes?.article?.title}
      text={solutionPage?.data?.attributes?.article?.previewText}
      button={{title: t('solution.readMoreButton'), to: 'solution'}}
      imageSrc={solutionPage?.data?.attributes?.article?.cover?.data?.attributes?.url}
      ref={ref}
    />
  );
});

Solution.displayName = 'Solution';
Solution.hashRoute = '#solution';

export default Solution;
