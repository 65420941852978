import style from './ContactItem.module.scss';
import PropTypes from 'prop-types';
import Button from '../../../../../components/Button';
import {useCallback} from 'react';

const ContactItem = ({contactAs, title, subtitle, buttonText, onButtonClick}) => {
  const handleButtonClick = useCallback(() => {
    onButtonClick(contactAs);
  }, [onButtonClick, contactAs]);

  return (
    <div className={style.container}>
      <div className={style.block1}>
        <div className={style.title}>{title}</div>
        <div className={style.subtitle}>{subtitle}</div>
      </div>
      <div className={style.block2}>
        <Button onClick={handleButtonClick}>{buttonText}</Button>
      </div>
    </div>
  );
};

ContactItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  contactAs: PropTypes.oneOf(['scientist', 'investor', 'other']).isRequired
};

export default ContactItem;
