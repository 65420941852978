const options = [
  {
    i18nKey: 'menuNavigation.aboutUs',
    link: 'about-us'
  },
  {
    i18nKey: 'menuNavigation.platform',
    link: 'platform'
  },
  {
    i18nKey: 'menuNavigation.investors',
    link: 'for-investors'
  },
  {
    i18nKey: 'menuNavigation.businessPlan',
    link: 'business-plan'
  },
  {
    i18nKey: 'menuNavigation.faq',
    link: 'faq'
  },
  {
    i18nKey: 'menuNavigation.blog',
    link: 'blog'
  },
  {
    i18nKey: 'menuNavigation.contactUs',
    link: 'contact-us'
  }
];

export default options;
