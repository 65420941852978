import PropTypes from 'prop-types';
import style from './PreviewSection.module.scss';
import ButtonLink from '../ButtonLink';
import classnames from 'classnames';
import {forwardRef} from 'react';

const PreviewSection = forwardRef(({title, text, button, imageSrc, reverseLayoutDirection, cell}, ref) => {
  return (
    <section
      className={classnames(style.container, {
        [style.reversedLayout]: reverseLayoutDirection
      })}
      ref={ref}>
      <div className={style.titleWrapper}>
        <h1 className={style.title}>{title}</h1>
      </div>
      <div className={style.imageWrapper}>
        <img
          src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${imageSrc}`}
          alt={title}
          className={style.image}
          width={320}
          height={213}
        />
      </div>
      <div className={style.textWrapper}>
        <p className={style.text}>{text}</p>
        {cell}
      </div>
      <div className={style.buttonWrapper}>
        <ButtonLink to={button.to}>{button.title}</ButtonLink>
      </div>
    </section>
  );
});

PreviewSection.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired,
  imageSrc: PropTypes.string.isRequired,
  reverseLayoutDirection: PropTypes.bool,
  cell: PropTypes.element
};

PreviewSection.displayName = 'PreviewSection';

export default PreviewSection;
