import PropTypes from 'prop-types';
import style from './Placeholder.module.scss';
import classnames from 'classnames';

const Placeholder = ({size}) => {
  return <div className={classnames(style.container, style[`${size}Size`])} />;
};

Placeholder.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

Placeholder.defaultProps = {
  size: 'md'
};

export default Placeholder;
