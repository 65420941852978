import PropTypes from 'prop-types';
import style from './RoadmapDateView.module.scss';
import classnames from 'classnames';
import {useSwiper} from 'swiper/react';
import {useCallback} from 'react';

const RoadmapDateView = ({index, year, description, isActive, isPrev, isNext, hidePoint}) => {
  const swiper = useSwiper();

  const onClickHandler = useCallback(() => {
    if (!isNaN(index) && index >= 0) {
      swiper.slideTo(index, 1000);
      swiper.activeIndex = index;
    }
  }, [index]);

  const pointJsx = !hidePoint ? <div className={style.point} /> : null;

  return (
    <div
      className={classnames(style.container, {
        [style.isPrev]: isPrev,
        [style.isActive]: isActive,
        [style.isNext]: isNext
      })}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      role="button"
      tabIndex={0}>
      <div className={style.upperBlock}>
        <div className={style.year}>{year}</div>
        {pointJsx}
      </div>
      <div className={style.bottomBlock}>
        <div className={style.description}>{description}</div>
      </div>
    </div>
  );
};

RoadmapDateView.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  isNext: PropTypes.bool,
  index: PropTypes.number,
  hidePoint: PropTypes.bool
};

export default RoadmapDateView;
