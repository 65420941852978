import {useCallback, useEffect, useState} from 'react';

const useCount = (intervalTime, getSnapshot, shouldCount = true) => {
  const [result, setResult] = useState([]);

  const runAction = useCallback(() => {
    const callbackResult = getSnapshot?.();
    setResult(callbackResult);
  }, [setResult]);

  useEffect(() => {
    let interval;

    if (shouldCount) {
      interval = setInterval(() => {
        runAction();
      }, intervalTime);
    } else {
      runAction();
    }

    return () => interval && clearInterval(interval);
  }, [intervalTime, runAction, shouldCount]);

  return result;
};

export default useCount;
