import PropTypes from 'prop-types';
import style from './ContactUsForm.module.scss';
import Button from '../Button';
import {useCallback, useState} from 'react';
import classnames from 'classnames';
import {animated, useTransition} from '@react-spring/web';
import SingleFieldForm from './SingleFieldForm';
import {EmailFormSchema, MessageFormSchema, NameFormSchema} from './ValidationSchemas';
import Preloader from './Preloader';
import {useTranslation} from 'react-i18next';
import axios from '../../services/axios';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const ContactUsForm = ({contactAs, onClickClose}) => {
  const [step, setStep] = useState(1);
  const {i18n} = useTranslation();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const {executeRecaptcha} = useGoogleReCaptcha();

  const handleBackClick = useCallback(() => onClickClose?.(), []);
  const submitForm = useCallback(
    async (data, actions) => {
      try {
        let captchaToken;

        if (executeRecaptcha) {
          captchaToken = await executeRecaptcha(process.env.REACT_APP_RE_CAPTCHA_CONTACT_US_MSG_ACTION);
        }

        const lang = i18n.language.replace('en-US', 'en').replace('uk-UA', 'ua');

        await axios.post('/messages', {
          email: data.email,
          contact_as: contactAs,
          author_name: data.name,
          message: data.message,
          lang,
          captcha_token: captchaToken
        });

        setStep(prevStep => prevStep + 1);

        setTimeout(() => setStep(prevStep => prevStep + 1), 2500);
      } catch (e) {
        const responseData = e?.response?.data;
        if (responseData?.i18nKey) {
          actions.setFieldError('message', responseData.i18nKey);
        } else {
          actions.setFieldError('message', 'contactUs.messageUnsent');
        }
      }
    },
    [i18n.language]
  );
  const handleOnSingleFormSubmit = useCallback(
    (value, actions) => {
      if (step === 1) {
        setName(value);
        setStep(prevStep => prevStep + 1);
      } else if (step === 2) {
        setEmail(value);
        setStep(prevStep => prevStep + 1);
      } else {
        submitForm(
          {
            name,
            email,
            message: value
          },
          actions
        );
      }
    },
    [step, setStep, setName, setEmail]
  );
  const handleOnCloseForm = useCallback(() => onClickClose?.(), []);

  const transition = useTransition(step, {
    from: {
      y: '150%',
      opacity: 0.0
    },
    enter: {
      y: '0%',
      opacity: 1.0
    },
    leave: {
      y: '-50%',
      opacity: 0.0
    }
  });

  const {t} = useTranslation('pages/home');

  const closeFormButtonJsx = [1, 2, 3].includes(step) ? (
    <div className={style.backButtonWrapper}>
      <Button onClick={handleBackClick} className={classnames(style.backButton, 'with-animated-underline')}>
        {t('contactUs.form.closeButtonTitle')}
      </Button>
    </div>
  ) : null;

  return (
    <div className={style.container}>
      {closeFormButtonJsx}
      <div className={style.formWrapper}>
        {transition((springStyle, step) => {
          if (step === 1) {
            return (
              <animated.div style={springStyle} className={style.contentWrapper}>
                <SingleFieldForm
                  label={t('contactUs.form.fields.name.label')}
                  name="name"
                  placeholder={t('contactUs.form.fields.name.inputPlaceholder')}
                  type="text"
                  onSubmit={handleOnSingleFormSubmit}
                  buttonTitle={t('contactUs.form.nextButtonTitle')}
                  validationSchema={NameFormSchema}
                  steps={{
                    current: step,
                    total: 3
                  }}
                />
              </animated.div>
            );
          } else if (step === 2) {
            return (
              <animated.div style={springStyle} className={style.contentWrapper}>
                <SingleFieldForm
                  label={t('contactUs.form.fields.email.label')}
                  name="email"
                  placeholder={t('contactUs.form.fields.email.inputPlaceholder')}
                  type="text"
                  onSubmit={handleOnSingleFormSubmit}
                  buttonTitle={t('contactUs.form.nextButtonTitle')}
                  validationSchema={EmailFormSchema}
                  steps={{
                    current: step,
                    total: 3
                  }}
                />
              </animated.div>
            );
          } else if (step === 3) {
            return (
              <animated.div style={springStyle} className={style.contentWrapper}>
                <SingleFieldForm
                  label={t('contactUs.form.fields.message.label')}
                  name="message"
                  placeholder={t('contactUs.form.fields.message.inputPlaceholder')}
                  type="text"
                  component="textarea"
                  onSubmit={handleOnSingleFormSubmit}
                  buttonTitle={t('contactUs.form.sendButtonTitle')}
                  validationSchema={MessageFormSchema}
                  steps={{
                    current: step,
                    total: 3
                  }}
                />
              </animated.div>
            );
          } else if (step === 4) {
            return (
              <animated.div style={springStyle} className={style.contentWrapper}>
                <Preloader />
              </animated.div>
            );
          } else {
            return (
              <animated.div style={springStyle} className={style.contentWrapper}>
                <div className={style.messageSent}>
                  <div className={style.messageSentText}>{t('contactUs.form.notification.messageSent')}</div>
                  <Button onClick={handleOnCloseForm}>{t('contactUs.form.okButtonTitle')}</Button>
                </div>
              </animated.div>
            );
          }
        })}
      </div>
    </div>
  );
};

ContactUsForm.propTypes = {
  contactAs: PropTypes.oneOf(['scientist', 'investor', 'other']).isRequired,
  onClickClose: PropTypes.func
};

export default ContactUsForm;
