import PropTypes from 'prop-types';
import style from './DataWithLabel.module.scss';
import classNames from 'classnames';

const DataWithLabel = ({label, children, className, titleProps, dataProps}) => {
  const labelJsx = label ? (
    <div
      className={classNames(style.header, titleProps?.className, {
        [style.withoutPadding]: titleProps?.removePadding
      })}>
      {label}
    </div>
  ) : null;

  return (
    <div className={classNames(style.container, className)}>
      {labelJsx}
      <div
        className={classNames(style.data, dataProps?.className, {
          [style.withoutPadding]: dataProps?.removePadding
        })}>
        {children}
      </div>
    </div>
  );
};

DataWithLabel.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  titleProps: PropTypes.shape({
    removePadding: PropTypes.bool,
    className: PropTypes.string
  }),
  dataProps: PropTypes.shape({
    removePadding: PropTypes.bool,
    className: PropTypes.string
  })
};

export default DataWithLabel;
