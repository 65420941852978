import PropTypes from 'prop-types';
import {SlGlobe} from 'react-icons/sl';
import classNames from 'classnames';
import style from './GlobeIcon.module.scss';

const GlobeIcon = ({aspectRatio, className, ...otherProps}) => {
  return <SlGlobe className={classNames(style.icon, className)} {...otherProps} />;
};

GlobeIcon.propTypes = {
  className: PropTypes.string,
  aspectRatio: PropTypes.any
};

export default GlobeIcon;
