import * as Yup from 'yup';
import {isPossiblePhoneNumber} from 'react-phone-number-input';

Yup.addMethod(Yup.string, 'phone', function (message) {
  return this.test('phone', message, function (value) {
    return value && isPossiblePhoneNumber(value);
  });
});

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('validationMessages.required')
    .min(2, 'validationMessages.tooShort')
    .max(60, 'validationMessages.tooLong'),
  email: Yup.string().required('validationMessages.required').email('validationMessages.invalidValue'),
  phone: Yup.string().required('validationMessages.required').phone('validationMessages.invalidValue'),
  amount: Yup.number().required('validationMessages.required').positive('validationMessages.positive').integer()
});

export default FormSchema;
