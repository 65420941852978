import style from './Stage2.module.scss';
import {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../../Button';
import {useTranslation} from 'react-i18next';
import SearchForm from '../../../SearchForm';

const Stage2 = ({networks, onNetworkResolved}) => {
  const {t} = useTranslation();

  const handleBackButtonClick = useCallback(() => {
    onNetworkResolved?.(null);
  }, [onNetworkResolved]);

  const items = useMemo(() => networks.map(network => ({abbr: network, icon: network, iconType: 'text'})), [networks]);

  return (
    <div className={style.container}>
      <div className={style.backButtonWrapper}>
        <Button onClick={handleBackButtonClick} className={classnames(style.backButton, 'with-animated-underline')}>
          {t('cryptoDonation.stages.2.backButtonTitle')}
        </Button>
      </div>
      <SearchForm
        currencyItems={items}
        searchInput={{
          label: t('cryptoDonation.stages.2.searchInput.label'),
          placeholder: t('cryptoDonation.stages.2.searchInput.placeholder')
        }}
        onItemResolved={onNetworkResolved}
      />
    </div>
  );
};

Stage2.propTypes = {
  networks: PropTypes.arrayOf(PropTypes.string).isRequired,
  onNetworkResolved: PropTypes.func
};

export default Stage2;
