import style from './FundsCounter.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef, useCallback, useEffect, useState} from 'react';
import ContentWrapper from '../../../../components/ContentWrapper';
import useSWR from '../../../../hooks/swr/useSWR';
import {animated, useChain, useSpringRef, useTransition} from '@react-spring/web';
import {Waypoint} from 'react-waypoint';
import Placeholder from './Placeholder/Placeholder';
import Chart from 'react-apexcharts';
import globalStyleVariables from '../../../../styles/variables.scss';
import useMatchMedia from 'use-match-media-hook';
import Link from '../../../../components/Link';
import classnames from 'classnames';

const FundsCounter = forwardRef((props, ref) => {
  const {t} = useTranslation('pages/home');
  const [donationsTotalAmount, setDonationsTotalAmount] = useState(0);
  const [goal, setGoal] = useState(0);
  const [remainsToTheGoal, setRemainsToTheGoal] = useState(0);
  const [donationsNumber, setDonationsNumber] = useState(0);
  const [averageDonation, setAverageDonation] = useState(0);
  const [chartData, setChartData] = useState(null);
  const {
    data: fundraisingProgressData,
    isLoading,
    isError
  } = useSWR(['fundraising-progress', {params: {populate: ['donationSources']}}]);
  const [isSectionInViewport, setIsSectionInViewport] = useState(false);

  useEffect(() => {
    if (!isLoading && !isError) {
      const {donationsTotalAmount, goal, donationsNumber, donationSources} = fundraisingProgressData.data.attributes;
      setDonationsTotalAmount(`$${t('fundsCounter.numberValue', {val: Math.round(donationsTotalAmount), lng: 'en'})}`);
      setGoal(`$${t('fundsCounter.numberValue', {val: Math.round(goal), lng: 'en'})}`);
      setRemainsToTheGoal(
        `$${t('fundsCounter.numberValue', {val: Math.round(goal - donationsTotalAmount), lng: 'en'})}`
      );
      setDonationsNumber(t('fundsCounter.numberValue', {val: Math.round(donationsNumber), lng: 'en'}));
      setAverageDonation(
        `$${t('fundsCounter.numberValue', {val: Math.round(donationsTotalAmount / donationsNumber), lng: 'en'})}`
      );
      const sortedDonationSources = donationSources.sort((a, b) => b.amount - a.amount);
      const chartCategories = sortedDonationSources.map(donationSource => t(donationSource.i18nKeyName));
      const chartDataLabels = sortedDonationSources.map(donationSource => donationSource.amount);
      setChartData({
        categories: chartCategories,
        seriesData: chartDataLabels
      });
    }
  }, [isLoading, isError]);

  const onViewpointEnter = useCallback(() => !isSectionInViewport && setIsSectionInViewport(true));
  const onViewpointLeave = useCallback(() => isSectionInViewport && setIsSectionInViewport(false));

  const numberTransitionProps = {
    from: {opacity: 0, transform: 'translate3d(0,-50%,0)', display: 'inline-block'},
    enter: {opacity: 1, transform: 'translate3d(0,0%,0)'},
    config: {duration: 300},
    exitBeforeEnter: true,
    immediate: !isSectionInViewport,
    trail: 90
  };

  const fnMapForNumStr = keyPrefix => (character, i, arr) => {
    let prevItemsSum = arr.slice(0, i + 1).filter(c => !isNaN(c));

    if (prevItemsSum.length) {
      prevItemsSum = prevItemsSum.reduce((a, b) => a + b);
    }

    return {
      value: character,
      key: `${keyPrefix}-${i}-${character}-${prevItemsSum}`
    };
  };

  const donationsTotalAmountRef = useSpringRef();
  const donationsTotalAmountTransitions = useTransition(
    [...donationsTotalAmount.toString()].map(fnMapForNumStr('donationsTotalAmount')),
    {...numberTransitionProps, ref: donationsTotalAmountRef}
  );
  const goalRef = useSpringRef();
  const goalTransitions = useTransition([...goal.toString()].map(fnMapForNumStr('goal')), numberTransitionProps);
  const remainsToTheGoalRef = useSpringRef();
  const remainsToTheGoalTransitions = useTransition(
    [...remainsToTheGoal.toString()].map(fnMapForNumStr('remainsToTheGoal')),
    {...numberTransitionProps, ref: remainsToTheGoalRef}
  );
  const donationsNumberRef = useSpringRef();
  const donationsNumberTransitions = useTransition(
    [...donationsNumber.toString()].map(fnMapForNumStr('donationsNumber')),
    {...numberTransitionProps, ref: donationsNumberRef}
  );
  const averageDonationRef = useSpringRef();
  const averageDonationTransitions = useTransition(
    [...averageDonation.toString()].map(fnMapForNumStr('averageDonation')),
    {...numberTransitionProps, ref: averageDonationRef}
  );

  useChain([donationsTotalAmountRef, goalRef, remainsToTheGoalRef, donationsNumberRef, averageDonationRef]);

  const [isDesktop2Size, isDesktop1Size, isTabletSize] = useMatchMedia([
    '(min-width: 1920px)',
    '(min-width: 1280px)',
    '(min-width: 768px)'
  ]);

  let pieChartWidth = '100%';
  let pieChartHeight = 'auto';
  let barChartHeight = 310;

  if (isDesktop2Size) {
    pieChartWidth = 320;
    pieChartHeight = 380;
    barChartHeight = 380;
  } else if (isDesktop1Size) {
    pieChartWidth = 300;
    pieChartHeight = 360;
    barChartHeight = 360;
  } else if (isTabletSize) {
    pieChartWidth = 240;
    pieChartHeight = 300;
    barChartHeight = 300;
  }

  if (isLoading || isError) {
    return <Placeholder ref={ref} />;
  } else {
    return (
      <Waypoint onEnter={onViewpointEnter} onLeave={onViewpointLeave} scrollableAncestor={window}>
        <ContentWrapper>
          <section className={style.container} ref={ref}>
            <div className={style.titleWrapper}>
              <h1 className={style.title}>{t('fundsCounter.sectionTitle')}</h1>
            </div>
            <div className={style.donationsAmount}>
              <div className={style.counterTitle}>{t('fundsCounter.donationsAmount.title')}</div>
              <div className={style.counter}>
                {donationsTotalAmountTransitions((springStyle, item) => (
                  <animated.div key={item.key} style={springStyle} className={style.digit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>
            </div>
            <div className={style.goal}>
              <div className={style.commonTitleValue}>
                <div className={style.commonTitle}>{t('fundsCounter.goal.title')}</div>
                <div className={style.commonValue}>
                  {goalTransitions((springStyle, item) => (
                    <animated.div key={item.key} style={springStyle} className={style.commonValueDigit}>
                      {item.value}
                    </animated.div>
                  ))}
                </div>
              </div>
              <div className={style.commonTitleValue}>
                <div className={style.commonTitle}>{t('fundsCounter.remainsToTheGoal.title')}</div>
                <div className={style.commonValue}>
                  {remainsToTheGoalTransitions((springStyle, item) => (
                    <animated.div key={item.key} style={springStyle} className={style.commonValueDigit}>
                      {item.value}
                    </animated.div>
                  ))}
                </div>
              </div>
            </div>
            <div className={style.other}>
              <div className={style.commonTitleValue}>
                <div className={style.commonTitle}>{t('fundsCounter.donationsNumber.title')}</div>
                <div className={style.commonValue}>
                  {donationsNumberTransitions((springStyle, item) => (
                    <animated.div key={item.key} style={springStyle} className={style.commonValueDigit}>
                      {item.value}
                    </animated.div>
                  ))}
                </div>
              </div>
              <div className={style.commonTitleValue}>
                <div className={style.commonTitle}>{t('fundsCounter.averageDonation.title')}</div>
                <div className={style.commonValue}>
                  {averageDonationTransitions((springStyle, item) => (
                    <animated.div key={item.key} style={springStyle} className={style.commonValueDigit}>
                      {item.value}
                    </animated.div>
                  ))}
                </div>
              </div>
            </div>
            <div className={style.chart}>
              <div className={style.chartDonut}>
                <Chart
                  options={{
                    chart: {
                      fontFamily: 'Gilroy, sans-serif',
                      toolbar: {
                        show: false
                      },
                      background: 'transparent',
                      selection: {
                        enabled: false
                      },
                      offsetY: 0
                    },
                    labels: chartData?.categories || [],
                    legend: {
                      fontSize: 16,
                      position: 'bottom',
                      offsetY: 15,
                      labels: {
                        colors: globalStyleVariables.colorSolidWhite
                      },
                      markers: {
                        radius: 3,
                        offsetY: -1,
                        offsetX: -2
                      }
                    },
                    dataLabels: {
                      style: {
                        fontSize: 36
                      },
                      dropShadow: {
                        enabled: false
                      }
                    },
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        customScale: 1.07,
                        startAngle: -120,
                        endAngle: 240,
                        donut: {
                          size: '56%'
                        }
                      }
                    },
                    colors: [
                      globalStyleVariables.colorGeoGreen,
                      globalStyleVariables.colorGeoGreen30Opacity,
                      globalStyleVariables.colorGeoGreen60Opacity
                    ],
                    stroke: {
                      show: true,
                      width: 2,
                      colors: globalStyleVariables.colorAltBlack
                    },
                    tooltip: {
                      enabled: false
                    },
                    noData: {
                      text: t('fundsCounter.chart.noData')
                    },
                    responsive: [
                      {
                        breakpoint: 1920,
                        options: {
                          chart: {
                            offsetY: 0
                          },
                          legend: {
                            fontSize: 14,
                            offsetY: 22,
                            position: 'bottom',
                            markers: {
                              offsetY: -1
                            }
                          },
                          plotOptions: {
                            pie: {
                              customScale: 1.08,
                              donut: {
                                size: '56%'
                              }
                            }
                          },
                          dataLabels: {
                            style: {
                              fontSize: 32
                            }
                          }
                        }
                      },
                      {
                        breakpoint: 1280,
                        options: {
                          chart: {
                            offsetY: 0
                          },
                          legend: {
                            fontSize: 14,
                            offsetY: 20,
                            position: 'bottom',
                            markers: {
                              offsetY: -1
                            }
                          },
                          plotOptions: {
                            pie: {
                              customScale: 1.09
                            }
                          },
                          dataLabels: {
                            style: {
                              fontSize: 32
                            }
                          }
                        }
                      },
                      {
                        breakpoint: 768,
                        options: {
                          legend: {
                            fontSize: 12,
                            position: 'bottom',
                            offsetY: 10,
                            markers: {
                              offsetY: -1
                            }
                          },
                          plotOptions: {
                            pie: {
                              customScale: 1.07,
                              donut: {
                                size: '50%'
                              }
                            }
                          },
                          dataLabels: {
                            style: {
                              fontSize: 28
                            }
                          }
                        }
                      }
                    ]
                  }}
                  series={chartData?.seriesData || []}
                  type="donut"
                  width={pieChartWidth}
                  height={pieChartHeight}
                />
              </div>
              <div className={style.chartBar}>
                <Chart
                  options={{
                    chart: {
                      fontFamily: 'Gilroy, sans-serif',
                      toolbar: {
                        show: false
                      },
                      background: 'transparent',
                      selection: {
                        enabled: false
                      }
                    },
                    xaxis: {
                      categories: chartData?.categories || [],
                      title: {
                        text: t('fundsCounter.chart.barChartTitle'),
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          color: globalStyleVariables.colorGeoGray
                        }
                      },
                      labels: {
                        show: true,
                        style: {
                          fontSize: 16,
                          colors: globalStyleVariables.colorGeoGray
                        }
                      },
                      axisBorder: {
                        color: globalStyleVariables.colorGeoGray
                      },
                      axisTicks: {
                        color: globalStyleVariables.colorGeoGray
                      }
                    },
                    yaxis: {
                      title: {
                        style: {
                          fontSize: 16
                        }
                      },
                      labels: {
                        style: {
                          fontSize: 16
                        }
                      }
                    },
                    legend: {
                      show: false
                    },
                    dataLabels: {
                      style: {
                        fontSize: 36
                      }
                    },
                    plotOptions: {
                      bar: {
                        horizontal: true,
                        distributed: true,
                        borderRadius: 10,
                        borderRadiusApplication: 'end',
                        barHeight: '58%'
                      }
                    },
                    tooltip: {
                      enabled: false
                    },
                    grid: {
                      borderColor: globalStyleVariables.colorGeoGray
                    },
                    colors: [
                      globalStyleVariables.colorGeoGreen,
                      globalStyleVariables.colorGeoGreen30Opacity,
                      globalStyleVariables.colorGeoGreen60Opacity
                    ],
                    theme: {
                      mode: 'dark'
                    },
                    noData: {
                      text: t('fundsCounter.chart.noData')
                    },
                    states: {
                      active: {
                        filter: {
                          type: 'none'
                        }
                      }
                    },
                    responsive: [
                      {
                        breakpoint: 1920,
                        options: {
                          dataLabels: {
                            style: {
                              fontSize: 32
                            }
                          },
                          xaxis: {
                            title: {
                              text: t('fundsCounter.chart.barChartTitle'),
                              style: {
                                color: globalStyleVariables.colorGeoGray,
                                fontSize: 14,
                                fontWeight: 400
                              }
                            },
                            labels: {
                              style: {
                                fontSize: 14,
                                colors: globalStyleVariables.colorGeoGray
                              }
                            }
                          },
                          yaxis: {
                            title: {
                              text: ''
                            },
                            labels: {
                              style: {
                                fontSize: 14,
                                colors: globalStyleVariables.colorSolidWhite
                              }
                            }
                          },
                          plotOptions: {
                            bar: {
                              horizontal: true,
                              barHeight: '58%'
                            }
                          }
                        }
                      },
                      {
                        breakpoint: 1280,
                        options: {
                          dataLabels: {
                            style: {
                              fontSize: 32
                            }
                          },
                          xaxis: {
                            title: {
                              text: ''
                            },
                            labels: {
                              style: {
                                fontSize: 14,
                                colors: globalStyleVariables.colorSolidWhite
                              }
                            }
                          },
                          yaxis: {
                            title: {
                              text: t('fundsCounter.chart.barChartTitle'),
                              style: {
                                color: globalStyleVariables.colorGeoGray,
                                fontSize: 14,
                                fontWeight: 400
                              }
                            },
                            labels: {
                              style: {
                                fontSize: 14,
                                colors: globalStyleVariables.colorGeoGray
                              }
                            }
                          },
                          plotOptions: {
                            bar: {
                              horizontal: false,
                              columnWidth: '52%'
                            }
                          }
                        }
                      },
                      {
                        breakpoint: 768,
                        options: {
                          dataLabels: {
                            style: {
                              fontSize: 28
                            }
                          },
                          xaxis: {
                            title: {
                              text: ''
                            },
                            labels: {
                              style: {
                                fontSize: 12,
                                colors: globalStyleVariables.colorSolidWhite
                              }
                            }
                          },
                          yaxis: {
                            title: {
                              text: t('fundsCounter.chart.barChartTitle'),
                              style: {
                                color: globalStyleVariables.colorGeoGray,
                                fontSize: 12,
                                fontWeight: 400
                              }
                            },
                            labels: {
                              show: false
                            }
                          },
                          plotOptions: {
                            bar: {
                              horizontal: false
                            }
                          }
                        }
                      }
                    ]
                  }}
                  series={[
                    {
                      data: chartData?.seriesData || []
                    }
                  ]}
                  type="bar"
                  height={barChartHeight}
                />
              </div>
            </div>
            <div className={style.bottom}>
              <Link to="/business-plan" className={classnames('with-animated-underline', style.link)}>
                {t('fundsCounter.businessPlanLink.title')}
              </Link>
            </div>
          </section>
        </ContentWrapper>
      </Waypoint>
    );
  }
});

FundsCounter.displayName = 'FundsCounter';
FundsCounter.hashRoute = '#funds-counter';

export default FundsCounter;
