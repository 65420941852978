import style from './Placeholder.module.scss';
import originStyle from '../Review.module.scss';
import classnames from 'classnames';
import PlaceholderTextLine from '../../PlaceholderTextLine';

const Placeholder = () => {
  return (
    <div className={classnames(originStyle.container, style.container)}>
      <div className={classnames(originStyle.avatar, style.avatar)} />
      <div className={classnames(originStyle.authorName, style.authorName)}>
        <PlaceholderTextLine className={style.authorNameTextLine} />
      </div>
      <blockquote className={classnames(originStyle.comment, style.comment)}>
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
      </blockquote>
    </div>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
