import style from './Placeholder.module.scss';
import ContentWrapper from '../../../components/ContentWrapper';
import BlogArticlePreviewPlaceholder from '../../../components/ArticlePreview/Placeholder';
import XPaddingWrapper from '../../../components/XPaddingWrapper';

const Placeholder = () => {
  return (
    <XPaddingWrapper>
      <ContentWrapper>
        <section className={style.container}>
          <div className={style.titleBlock}>
            <div className={style.title} />
          </div>
          <div className={style.contentWrapper}>
            <div className={style.row}>
              <BlogArticlePreviewPlaceholder />
              <BlogArticlePreviewPlaceholder />
              <BlogArticlePreviewPlaceholder />
            </div>
          </div>
        </section>
      </ContentWrapper>
    </XPaddingWrapper>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
