const options = [
  {
    i18nKey: 'headerNavigation.platform',
    link: 'platform'
  },
  {
    i18nKey: 'headerNavigation.aboutUs',
    link: '#about-us'
  },
  {
    i18nKey: 'headerNavigation.video',
    link: '#video'
  },
  {
    i18nKey: 'headerNavigation.roadmap',
    link: '#roadmap'
  },
  {
    i18nKey: 'headerNavigation.contactUs',
    link: '#contact-us'
  }
];

export default options;
