import style from './DonateCrypto.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef} from 'react';
import CryptoDonateForm from '../../../../components/CryptoDonateForm';
import ContentWrapper from '../../../../components/ContentWrapper';
import useSWR from '../../../../hooks/swr/useSWR';

const DonateCrypto = forwardRef((props, ref) => {
  const {t} = useTranslation('pages/home');

  const {
    data: cryptoCoinsData,
    isLoading,
    isError
  } = useSWR(['crypto-coins', {params: {populate: ['icon', 'networks'], pagination: {pageSize: 1000}}}]);

  let formattedCryptoCoinsData = [];

  if (!isLoading && !isError) {
    formattedCryptoCoinsData = cryptoCoinsData.data.map(cryptoCoin => {
      const networks = cryptoCoin.attributes.networks.reduce((acc, network) => {
        acc[network.name] = network.wallet;
        return acc;
      }, {});
      return {
        name: cryptoCoin.attributes.name,
        abbr: cryptoCoin.attributes.abbr,
        icon: cryptoCoin.attributes.icon?.data?.attributes?.url,
        networks
      };
    });
  }

  return (
    <section className={style.container} ref={ref}>
      <ContentWrapper className={style.contentWrapper}>
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{t('donateCrypto.sectionTitle')}</h1>
        </div>
      </ContentWrapper>
      <div className={style.content}>
        <CryptoDonateForm coins={formattedCryptoCoinsData} />
      </div>
    </section>
  );
});

DonateCrypto.displayName = 'DonateCrypto';
DonateCrypto.hashRoute = '#donate-crypto';

export default DonateCrypto;
