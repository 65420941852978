import PropTypes from 'prop-types';
import style from './Placeholder.module.scss';
import originalStyle from '../PreviewSection.module.scss';
import ButtonLinkPlaceholder from '../../ButtonLink/Placeholder';
import classnames from 'classnames';
import {forwardRef} from 'react';
import PlaceholderTextLine from '../../PlaceholderTextLine';

const Placeholder = forwardRef(({reverseLayoutDirection}, ref) => {
  return (
    <section
      className={classnames(originalStyle.container, style.container, {
        [originalStyle.reversedLayout]: reverseLayoutDirection
      })}
      ref={ref}>
      <div className={originalStyle.titleWrapper}>
        <div className={style.title} />
      </div>
      <div className={originalStyle.imageWrapper}>
        <div className={classnames(originalStyle.image, style.image)} />
      </div>
      <div className={originalStyle.textWrapper}>
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />

        <PlaceholderTextLine className={style.desktop1Line} />
        <PlaceholderTextLine className={style.desktop1Line} />
        <PlaceholderTextLine className={style.desktop1Line} />

        <PlaceholderTextLine className={style.desktop2Line} />
        <PlaceholderTextLine className={style.desktop2Line} />
        <PlaceholderTextLine className={style.desktop2Line} />
      </div>
      <div className={originalStyle.buttonWrapper}>
        <ButtonLinkPlaceholder />
      </div>
    </section>
  );
});

Placeholder.propTypes = {
  reverseLayoutDirection: PropTypes.bool
};

Placeholder.displayName = 'PreviewSectionPlaceholder';

export default Placeholder;
