/* eslint-disable */
// noinspection ES6UnusedImports

import PropTypes from 'prop-types';
import style from './Form.module.scss';
import {animated, useSpring, useTransition} from '@react-spring/web';
import {ErrorMessage, Field, Form as FormikForm, useFormikContext} from 'formik';
import AnimatedErrorMessage from '../../AnimatedMessage';
import classnames from 'classnames';
// import Button from '../../Button';
import LoadingButton from '../../Button/LoadingButton';
import {useCallback, useEffect, useId, useState} from 'react';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {Trans, useTranslation} from 'react-i18next';
import Loader from '../Loader';
import ApplePayButton from '../ApplePayButton';
import GooglePayButton from '../GooglePayButton';
import useHeight from '../../../hooks/useHeight';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import axios from '../../../services/axios';
import PaymentResultNotification from '../../PaymentResultNotification';
import Tabs from '../../Tabs';
import Button from '../../Button';
import SubscriptionAmountCheckbox from '../SubscriptionAmountCheckbox';
import Link from '../../Link';
import Checkbox from '../../Checkbox';
import PhonenumberInput from '../PhonenumberInput';
import CreditCard from './CreditCard';

const cyrillicToTranslit = new CyrillicToTranslit({preset: 'uk'});

const Form = ({
  getCardNumberProps,
  getExpiryDateProps,
  getCVCProps,
  paymentInputMeta,
  optionalPaymentMethods,
  serverRequestAdditionalParams
}) => {
  const {t, i18n} = useTranslation();

  const nameInputId = useId();
  const emailInputId = useId();
  const phoneInputId = useId();
  const amountInputId = useId();
  const cardNumberInputId = useId();
  const expDateInputId = useId();
  const cvvInputId = useId();

  const {
    values,
    isSubmitting,
    setTouched,
    dirty,
    errors,
    isValid,
    setSubmitting,
    setFieldError,
    resetForm,
    setFieldValue,
    validateForm
  } = useFormikContext();

  const {executeRecaptcha} = useGoogleReCaptcha();

  const [isCaughtError, setIsCaughtError] = useState(false);
  const [agreedWithTerms, setAgreedWithTerms] = useState(false);

  const transition = useTransition(
    {isSubmitting, isCaughtError},
    {
      from: {
        y: '10%',
        opacity: 0.0
      },
      enter: {
        y: '0%',
        opacity: 1.0
      },
      leave: {
        y: '-10%',
        opacity: 0.0
      },
      keys: item => `${item.isSubmittedSuccessfully}-${item.isSubmitting}`
    }
  );

  const onClickAmountSuggestionButton = useCallback(amount => {
    setFieldValue('amount', amount);
  }, []);

  const processPayment = useCallback(
    async (paymentMethod, payload) => {
      const values = payload.formData;
      let captchaToken;
      let paymentResult;

      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha(process.env.REACT_APP_RE_CAPTCHA_SUBSCRIPTION_ACTION);
      }

      const lang = i18n.resolvedLanguage.replace('en-US', 'en').replace('uk-UA', 'ua');

      const data = {
        payer_name: values.name,
        payer_email: values.email,
        amount: values.amount,
        payer_phone: values.phone,
        card: {
          number: values.cardNumber,
          exp: values.expiryDate,
          cvv: values.cvc
        },
        captcha_token: captchaToken,
        payment_method: paymentMethod,
        lang,
        browser_details: {
          browser_accept_header: 'text/html',
          browser_color_depth: screen?.colorDepth?.toString?.(),
          browser_language: window.navigator.language,
          browser_screen_height: screen?.height?.toString?.(),
          browser_screen_width: screen?.width?.toString?.(),
          browser_timezone: new Date().getTimezoneOffset().toString(),
          browser_user_agent: navigator?.userAgent,
          browser_java_enabled: navigator?.javaEnabled?.().toString?.(),
          window_height: window?.outerHeight?.toString?.(),
          window_width: window?.outerWidth?.toString?.()
        }
      };

      if (values.is_subscription) {
        data.save_card = '1';
      }

      if (paymentMethod === Form.paymentMethods.GOOGLE_PAY) {
        data.card.token = values.googlePayToken;
      }

      if (serverRequestAdditionalParams) {
        Object.assign(data, serverRequestAdditionalParams);
      }

      setSubmitting(true);

      try {
        paymentResult = await axios.post('/payments', data);
        window.location.replace(paymentResult.data.url);
      } catch (e) {
        const responseData = e?.response?.data;

        if (responseData?.name === 'RequestParamsError') {
          responseData.errors.forEach(error => {
            let fieldKey = error.fieldKey;

            if (fieldKey === 'payer_name') {
              fieldKey = 'name';
            } else if (fieldKey === 'payer_email') {
              fieldKey = 'email';
            } else if (fieldKey === 'payer_phone') {
              fieldKey = 'phone';
            } else if (fieldKey === 'card.number') {
              fieldKey = 'cardNumber';
            } else if (fieldKey === 'card.exp') {
              fieldKey = 'expiryDate';
            } else if (fieldKey === 'card.cvv') {
              fieldKey = 'cvc';
            } else if (fieldKey === 'captcha_token') {
              fieldKey = 'captchaToken';
            }

            setFieldError(fieldKey, error.i18nKey);
          });
        } else {
          setIsCaughtError(true);
        }

        setSubmitting(false);
      }
    },
    [setSubmitting, executeRecaptcha, i18n?.language]
  );

  const onClickPayButton = useCallback(
    async (isSubscription = false) => {
      if (!dirty || !isValid) {
        setTouched(
          {
            name: true,
            email: true,
            phone: true,
            amount: true,
            cardNumber: true,
            expiryDate: true,
            cvc: true
          },
          true
        );
        return;
      }

      const payload = {
        formData: {
          ...values,
          is_subscription: isSubscription
        }
      };

      await processPayment(Form.paymentMethods.CARD, payload);
    },
    [dirty, isValid, processPayment, setTouched, values]
  );

  const onClickAppleOrGoogleButtonHandler = useCallback(
    event => {
      const isValid = Object.keys(errors).every(errorFieldKey => !['name', 'email', 'amount'].includes(errorFieldKey));
      if (!dirty) {
        event.preventDefault();
        setTouched(
          {
            name: true,
            email: true,
            phone: true,
            amount: true,
            cardNumber: false,
            expiryDate: false,
            cvc: false
          },
          true
        );
      } else {
        const isCardDataErrorExist = Object.keys(errors).some(errorFieldKey =>
          ['cardNumber', 'expiryDate', 'cvc'].includes(errorFieldKey)
        );
        if (isCardDataErrorExist) {
          setTouched(
            {
              name: true,
              email: true,
              phone: true,
              amount: true,
              cardNumber: false,
              expiryDate: false,
              cvc: false
            },
            false
          );
        }

        if (!isValid) {
          event.preventDefault?.();
        }
      }
    },
    [setTouched, dirty, errors]
  );

  const onLoadGooglePayPaymentData = useCallback(
    async paymentData => {
      const payload = {
        formData: {
          ...values,
          googlePayToken: paymentData.paymentMethodData.tokenizationData.token
        }
      };

      await processPayment(Form.paymentMethods.GOOGLE_PAY, payload);
    },
    [values]
  );

  const onClickGoogleButtonHandler = useCallback(
    event => {
      onClickAppleOrGoogleButtonHandler(event);
    },
    [onClickAppleOrGoogleButtonHandler, values]
  );

  const onClickAppleButtonHandler = useCallback(
    async event => {
      onClickAppleOrGoogleButtonHandler(event);

      if (event.defaultPrevented) {
        return;
      }

      const payload = {
        formData: values
      };

      await processPayment(Form.paymentMethods.APPLE_PAY, payload);
    },
    [onClickAppleOrGoogleButtonHandler, processPayment, values]
  );

  const [cardFocus, setCardFocus] = useState(null);

  const handleOnFieldFocus = useCallback(
    e => {
      const fieldName = e.target.name;
      if (fieldName === 'name') {
        setCardFocus('name');
      } else if (fieldName === 'cardNumber') {
        setCardFocus('number');
      } else if (fieldName === 'expiryDate') {
        setCardFocus('expiry');
      } else if (fieldName === 'cvc') {
        setCardFocus('cvc');
      } else {
        setCardFocus(null);
      }
    },
    [setCardFocus]
  );

  const onBlurFieldHandler = useCallback(() => {
    setCardFocus(null);
  }, [setCardFocus]);

  const backToFormHandler = useCallback(() => {
    resetForm();
    setIsCaughtError(false);
  }, [resetForm]);

  // Workaround for paymentInputs validation
  const validateFormUseEffectFn = () => {
    // noinspection JSIgnoredPromiseFromCall
    validateForm();
  };
  useEffect(validateFormUseEffectFn, [paymentInputMeta.erroredInputs.cardNumber]);
  useEffect(validateFormUseEffectFn, [paymentInputMeta.erroredInputs.expiryDate]);
  useEffect(validateFormUseEffectFn, [paymentInputMeta.erroredInputs.cvc]);

  const [heightRef, height] = useHeight();
  const containerSpringStyle = useSpring({height});

  const optionalPaymentButtons = [];
  let optionalPaymentButtonsGroupJsx = null;
  if (optionalPaymentMethods?.applePay) {
    optionalPaymentButtons.push(
      <ApplePayButton
        onClick={onClickAppleButtonHandler}
        disabled={isSubmitting}
        totalPrice={values.amount}
        key="applePayButton"
      />
    );
  }
  if (optionalPaymentMethods?.googlePay) {
    optionalPaymentButtons.push(
      <GooglePayButton
        onClick={onClickGoogleButtonHandler}
        onLoadPaymentData={onLoadGooglePayPaymentData}
        disabled={isSubmitting}
        totalPrice={values.amount}
        key="googlePayButton"
      />
    );
  }
  if (optionalPaymentButtons.length) {
    optionalPaymentButtonsGroupJsx = (
      <div className={style.applePayAndGooglePayButtonsGroup}>{optionalPaymentButtons}</div>
    );
  }

  return (
    <animated.div className={style.container} style={containerSpringStyle}>
      {transition((springStyle, {isSubmitting, isCaughtError}) => {
        if (isSubmitting && !isCaughtError) {
          return (
            <animated.div style={springStyle} className={style.animatedContentWrapper}>
              <Loader />
            </animated.div>
          );
        } else if (isCaughtError === true) {
          return (
            <animated.div style={springStyle} className={style.animatedContentWrapper}>
              <PaymentResultNotification
                onClickOk={backToFormHandler}
                buttonTitle={t('creditCardDonation.paymentResult.error.backToFormButtonTitle')}
                paymentStatus={PaymentResultNotification.paymentStatuses.ERROR}
              />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={springStyle} className={style.animatedContentWrapper} ref={heightRef}>
              <FormikForm className={style.form}>
                <div className={style.cardWrapper}>
                  <CreditCard
                    cvc={values.cvc}
                    expiry={values.expiryDate.replaceAll(' ', '')}
                    focused={cardFocus}
                    name={cyrillicToTranslit.transform(values.name)}
                    number={values.cardNumber}
                    type={paymentInputMeta.cardType?.type}
                  />
                  <ErrorMessage name="captchaToken">
                    {errorI18Key => (
                      <div className={style.commonErrorInfo}>
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className={style.fieldsWrapper}>
                  <label htmlFor={nameInputId}>{t('creditCardDonation.name.label')}</label>
                  <div className={style.fieldWrapper}>
                    <Field type="text" name="name">
                      {({field}) => {
                        return (
                          <input
                            id={nameInputId}
                            type="text"
                            name="name"
                            placeholder={t('creditCardDonation.name.inputPlaceholder')}
                            onFocus={handleOnFieldFocus}
                            className={style.inputName}
                            {...field}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="name">
                      {errorI18Key => (
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      )}
                    </ErrorMessage>
                  </div>
                  <label htmlFor={emailInputId}>{t('creditCardDonation.email.label')}</label>
                  <div className={style.fieldWrapper}>
                    <Field type="text" name="email">
                      {({field}) => {
                        return (
                          <input
                            id={emailInputId}
                            type="email"
                            name="email"
                            placeholder={t('creditCardDonation.email.inputPlaceholder')}
                            onFocus={handleOnFieldFocus}
                            className={style.inputEmail}
                            {...field}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="email">
                      {errorI18Key => (
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      )}
                    </ErrorMessage>
                  </div>
                  <label htmlFor={phoneInputId}>{t('creditCardDonation.phone.label')}</label>
                  <div className={style.fieldWrapper}>
                    <Field type="tel" name="phone">
                      {({field}) => {
                        return (
                          <PhonenumberInput
                            id={phoneInputId}
                            placeholder={t('creditCardDonation.phone.inputPlaceholder')}
                            onFocus={handleOnFieldFocus}
                            {...field}
                            onChange={val => setFieldValue('phone', val)}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="phone">
                      {errorI18Key => (
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      )}
                    </ErrorMessage>
                  </div>
                  <label htmlFor={amountInputId}>{t('creditCardDonation.amount.label')}</label>
                  <div className={style.fieldWrapper}>
                    <Field type="number" step={10} name="amount">
                      {({field}) => {
                        return (
                          <input
                            id={amountInputId}
                            name="amount"
                            type="number"
                            step={10}
                            placeholder={t('creditCardDonation.amount.inputPlaceholder')}
                            onFocus={handleOnFieldFocus}
                            className={style.inputAmount}
                            {...field}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="amount">
                      {errorI18Key => (
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      )}
                    </ErrorMessage>
                  </div>
                  <label htmlFor={cardNumberInputId}>{t('creditCardDonation.cardNumber.label')}</label>
                  <div className={style.fieldWrapper}>
                    <Field
                      type="number"
                      name="cardNumber"
                      placeholder={t('creditCardDonation.cardNumber.inputPlaceholder')}>
                      {({field}) => (
                        <input
                          {...field}
                          {...getCardNumberProps({
                            onBlur: e => {
                              field.onBlur(e);
                              onBlurFieldHandler();
                            },
                            onChange: field.onChange,
                            onFocus: handleOnFieldFocus
                          })}
                          id={cardNumberInputId}
                          placeholder={t('creditCardDonation.cardNumber.inputPlaceholder')}
                          className={style.inputCard}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="cardNumber">
                      {errorI18Key => (
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      )}
                    </ErrorMessage>
                  </div>
                  <label htmlFor={expDateInputId} className={style.leftSideField}>
                    {t('creditCardDonation.expiryDate.label')}
                  </label>
                  <div className={classnames(style.fieldWrapper, style.leftSideField)}>
                    <Field
                      type="text"
                      name="expiryDate"
                      placeholder={t('creditCardDonation.expiryDate.inputPlaceholder')}>
                      {({field}) => (
                        <input
                          {...field}
                          {...getExpiryDateProps({
                            onBlur: e => {
                              field.onBlur(e);
                              onBlurFieldHandler();
                            },
                            onChange: field.onChange,
                            onFocus: handleOnFieldFocus
                          })}
                          id={expDateInputId}
                          placeholder={t('creditCardDonation.expiryDate.inputPlaceholder')}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="expiryDate">
                      {errorI18Key => (
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      )}
                    </ErrorMessage>
                  </div>
                  <label htmlFor={cvvInputId} className={style.rightSideField}>
                    {t('creditCardDonation.cvc.label')}
                  </label>
                  <div className={classnames(style.fieldWrapper, style.rightSideField)}>
                    <Field type="text" name="cvc">
                      {({field}) => (
                        <input
                          {...field}
                          {...getCVCProps({
                            onBlur: e => {
                              field.onBlur(e);
                              onBlurFieldHandler();
                            },
                            onChange: field.onChange,
                            onFocus: handleOnFieldFocus
                          })}
                          id={cvvInputId}
                          placeholder={t('creditCardDonation.cvc.inputPlaceholder')}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="cvc">
                      {errorI18Key => (
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className={style.controlsWrapper}>
                  <Tabs>
                    {[
                      {
                        label: t('creditCardDonation.monthlySubscription.tabTitle'),
                        content: (
                          <div className={style.subscriptionWrapper}>
                            <div className={style.amountProposalsBlock}>
                              <div className={style.amountProposalsRow}>
                                <SubscriptionAmountCheckbox
                                  actualAmount={values.amount}
                                  checked={values.amount === 2}
                                  onChange={onClickAmountSuggestionButton}>
                                  2
                                </SubscriptionAmountCheckbox>
                                <SubscriptionAmountCheckbox
                                  actualAmount={values.amount}
                                  onChange={onClickAmountSuggestionButton}>
                                  10
                                </SubscriptionAmountCheckbox>
                                <SubscriptionAmountCheckbox
                                  actualAmount={values.amount}
                                  onChange={onClickAmountSuggestionButton}>
                                  100
                                </SubscriptionAmountCheckbox>
                              </div>
                              <div className={style.amountProposalsRow}>
                                <SubscriptionAmountCheckbox
                                  actualAmount={values.amount}
                                  checked={values.amount === 2}
                                  onChange={onClickAmountSuggestionButton}>
                                  125
                                </SubscriptionAmountCheckbox>
                                <SubscriptionAmountCheckbox
                                  actualAmount={values.amount}
                                  onChange={onClickAmountSuggestionButton}>
                                  150
                                </SubscriptionAmountCheckbox>
                                <SubscriptionAmountCheckbox
                                  actualAmount={values.amount}
                                  onChange={onClickAmountSuggestionButton}>
                                  200
                                </SubscriptionAmountCheckbox>
                              </div>
                            </div>
                            <div className={style.payButtonBlock}>
                              <div className={style.termsNotice}>
                                <Checkbox onChange={setAgreedWithTerms} />
                                <div>
                                  <Trans i18nKey="creditCardDonation.monthlySubscription.termsNotice">
                                    I agree to the{' '}
                                    <a
                                      href={`/${i18n.resolvedLanguage}/docs/terms`}
                                      className="with-animated-underline green"
                                      target="_blank"
                                      rel="noreferrer">
                                      terms of use
                                    </a>{' '}
                                    and{' '}
                                    <span className={style.noBreaks}>
                                      <a
                                        href={`/${i18n.resolvedLanguage}/docs/privacy-policy`}
                                        className="with-animated-underline green"
                                        target="_blank"
                                        rel="noreferrer">
                                        privacy policy
                                      </a>
                                      .
                                    </span>
                                  </Trans>
                                </div>
                              </div>
                              <LoadingButton
                                className={style.button}
                                color="red"
                                disabled={isSubmitting || !agreedWithTerms}
                                onClick={() => onClickPayButton(true)}
                                loadingTitle={t('creditCardDonation.loadingButton')}>
                                {t('creditCardDonation.monthlySubscription.donateButton')}
                              </LoadingButton>
                            </div>
                          </div>
                        )
                      },
                      {
                        label: t('creditCardDonation.oneTime.tabTitle'),
                        content: (
                          <div className={style.oneTimeWrapper}>
                            <LoadingButton
                              className={style.button}
                              color="red"
                              disabled={isSubmitting}
                              onClick={onClickPayButton}
                              loadingTitle={t('creditCardDonation.loadingButton')}>
                              {t('creditCardDonation.oneTime.donateButton')}
                            </LoadingButton>
                            {optionalPaymentButtonsGroupJsx}
                          </div>
                        )
                      }
                    ]}
                  </Tabs>
                </div>
              </FormikForm>
            </animated.div>
          );
        }
      })}
    </animated.div>
  );
};

Form.propTypes = {
  getCardNumberProps: PropTypes.func.isRequired,
  getExpiryDateProps: PropTypes.func.isRequired,
  getCVCProps: PropTypes.func.isRequired,
  paymentInputMeta: PropTypes.shape({
    erroredInputs: PropTypes.shape({
      cardNumber: PropTypes.string,
      expiryDate: PropTypes.string,
      cvc: PropTypes.string
    }).isRequired
  }).isRequired,
  optionalPaymentMethods: PropTypes.shape({
    applePay: PropTypes.bool,
    googlePay: PropTypes.bool
  }),
  serverRequestAdditionalParams: PropTypes.object
};

Form.defaultProps = {
  optionalPaymentMethods: {
    applePay: false,
    googlePay: false
  },
  serverRequestAdditionalParams: null
};

Form.paymentMethods = {
  CARD: 'CARD',
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY'
};

export default Form;
