import PropTypes from 'prop-types';
import {animated, config, useSpring} from '@react-spring/web';
import style from './AnimatedMessage.module.scss';
import classnames from 'classnames';

const AnimatedMessage = ({children, type, className}) => {
  const springFadeInStyle = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    config: config.slow
  });

  return (
    <animated.div
      className={classnames(
        style.message,
        {
          [style.error]: type === 'error',
          [style.success]: type === 'success'
        },
        className
      )}
      style={springFadeInStyle}>
      {children}
    </animated.div>
  );
};

AnimatedMessage.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf([null, undefined, 'error', 'success'])
};

export default AnimatedMessage;
