import PropTypes from 'prop-types';
import style from './SubscriptionAmountCheckbox.module.scss';
import classnames from 'classnames';
import {animated, useSpring} from '@react-spring/web';
import {useTranslation} from 'react-i18next';

const SubscriptionAmountCheckbox = ({children, actualAmount, className, size = 'md', onChange}) => {
  const {t} = useTranslation();
  const containerClassName = classnames(style.container, style[`${size}Size`], className);
  const isChecked = actualAmount.toString() === children.toString();

  const springStyle = useSpring({
    borderColor: isChecked ? style.checkedBorderColor : style.borderColor
  });

  const _onChange = () => {
    onChange?.(children);
  };

  return (
    <label className={containerClassName}>
      <input type="checkbox" checked={isChecked} onChange={_onChange} className={style.checkbox} />
      <animated.div style={springStyle} className={style.contentWrapper}>
        <div>
          <div className={style.firstLine}>
            {t('creditCardDonation.monthlySubscription.amountPropose.firstLine', {val: children})}
          </div>
          <div className={style.secondLine}>{t('creditCardDonation.monthlySubscription.amountPropose.secondLine')}</div>
        </div>
      </animated.div>
    </label>
  );
};

SubscriptionAmountCheckbox.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onChange: PropTypes.func,
  actualAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default SubscriptionAmountCheckbox;
