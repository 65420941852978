import style from './Video.module.scss';
import {forwardRef} from 'react';
import ContentWrapper from '../../../../components/ContentWrapper';
import VideoItem from '../../../../components/VideoItem';
import useSWR from '../../../../hooks/swr/useSWR';

const Video = forwardRef((props, ref) => {
  const {data} = useSWR(['video', {params: {populate: ['about_video', 'about_video.cover']}}]);

  return (
    <ContentWrapper>
      <section className={style.container} ref={ref}>
        <VideoItem
          vimeoId={data?.data?.attributes?.about_video?.vimeo_id?.toString?.()}
          title={data?.data?.attributes?.about_video?.title}
          previewImageSources={data?.data?.attributes?.about_video?.cover?.data?.map?.(coverItem => ({
            type: coverItem.attributes.mime,
            src: coverItem.attributes.url
          }))}
        />
      </section>
    </ContentWrapper>
  );
});

Video.displayName = 'Video';
Video.hashRoute = '#video';

export default Video;
