import {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import PreviewSection from '../../../../components/PreviewSection';
import PreviewSectionPlaceholder from '../../../../components/PreviewSection/Placeholder';
import useSWR from '../../../../hooks/swr/useSWR';

const AboutUs = forwardRef((_, ref) => {
  const {t} = useTranslation('pages/home');
  const {
    data: aboutUsPage,
    isLoading,
    isError
  } = useSWR(['about-us', {params: {populate: ['article', 'article.cover']}}]);

  return isLoading || isError ? (
    <PreviewSectionPlaceholder />
  ) : (
    <PreviewSection
      title={aboutUsPage?.data?.attributes?.article?.title}
      text={aboutUsPage?.data?.attributes?.article?.previewText}
      button={{title: t('aboutUs.readMoreButton'), to: 'about-us'}}
      imageSrc={aboutUsPage?.data?.attributes?.article?.cover?.data?.attributes?.url}
      ref={ref}
    />
  );
});

AboutUs.displayName = 'AboutUs';
AboutUs.hashRoute = '#about-us';

export default AboutUs;
