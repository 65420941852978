import originStyle from '../CFAmlKycPolicy.module.scss';
import PlaceholderTextLine from '../../../components/PlaceholderTextLine';
import FunctionalPagePlaceholder from '../../Functional/Placeholder';

const Placeholder = () => {
  return (
    <FunctionalPagePlaceholder>
      <article className={originStyle.article}>
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
      </article>
    </FunctionalPagePlaceholder>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
