import PropTypes from 'prop-types';
import GGooglePayButton from '@google-pay/button-react';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';
import style from './GooglePayButton.module.scss';
import {useUserAgent} from '@oieduardorabelo/use-user-agent';
import {useEffect, useState} from 'react';
import useCountryCode from '../../../hooks/useCountryCode';

const GooglePayButton = ({className, onClick, onLoadPaymentData, totalPrice, browserMatch}) => {
  const [shouldToShow, setShouldToShow] = useState(false);

  const countyCode = useCountryCode();

  const details = useUserAgent(window.navigator.userAgent);
  const browserName = details?.browser?.name?.toLowerCase?.();

  useEffect(() => {
    if (browserName) {
      const {allowedKeywords, deniedKeywords} = browserMatch;
      const isAllowed = allowedKeywords.some(allowedKeyword => browserName.includes(allowedKeyword.toLowerCase()));
      const isDenied = deniedKeywords.some(deniedKeyword => browserName.includes(deniedKeyword.toLowerCase()));

      setShouldToShow(isAllowed && !isDenied);
    }
  }, [browserName]);

  const {t, i18n} = useTranslation();

  const onLoadPaymentDataHandler = paymentData => {
    onLoadPaymentData?.(paymentData);
  };

  const onClickHandler = e => {
    onClick?.(e);
  };

  return !shouldToShow ? null : (
    <GGooglePayButton
      environment={process.env.REACT_APP_GOOGLE_PAY_ENVIRONMENT}
      buttonColor="white"
      buttonLocale={i18n.resolvedLanguage.replace('ua', 'uk')}
      buttonSizeMode="fill"
      className={classnames(style.container, className)}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA']
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: process.env.REACT_APP_GOOGLE_PAY_GATEWAY,
                gatewayMerchantId: process.env.REACT_APP_GOOGLE_PAY_GATEWAY_MERCHANT_ID
              }
            }
          }
        ],
        merchantInfo: {
          merchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID,
          merchantName: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_NAME
        },
        transactionInfo: {
          totalPriceStatus: totalPrice ? 'FINAL' : 'NOT_CURRENTLY_KNOWN',
          totalPriceLabel: t('creditCardDonation.googlePay.totalPriceLabel'),
          totalPrice: totalPrice.toString(),
          currencyCode: process.env.REACT_APP_DONATE_CURRENCY,
          countryCode: countyCode
        }
      }}
      onClick={onClickHandler}
      onLoadPaymentData={onLoadPaymentDataHandler}
    />
  );
};

GooglePayButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onLoadPaymentData: PropTypes.func,
  totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  browserMatch: PropTypes.shape({
    allowedKeywords: PropTypes.arrayOf(PropTypes.string).isRequired,
    deniedKeywords: PropTypes.arrayOf(PropTypes.string).isRequired
  })
};

GooglePayButton.defaultProps = {
  // https://platon.atlassian.net/wiki/spaces/docs/pages/1346994177/Google+Pay+API#%D0%9E%D0%B1%D0%BC%D0%B5%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F
  browserMatch: {
    allowedKeywords: ['chrome', 'firefox', 'safari', 'ucbrowser', 'opera', 'edge', 'android'],
    deniedKeywords: ['webview']
  }
};

export default GooglePayButton;
