import style from './Unsubscribe.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import Button from '../../components/Button';
import {useSearchParams} from 'react-router-dom';
import NotFound from '../NotFound';
import useSWR from '../../hooks/swr/useSWR';
import Logo from '../../components/Header/Logo';
import {useCallback, useState} from 'react';
import axios from '../../services/axios';

const Unsubscribe = () => {
  const {t, i18n} = useTranslation('pages/unsubscribe');
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get('email');
  let getSubscriptionSwrKey = null;
  const [unsubscribeStatus, setUnsubscribeStatus] = useState(null);

  if (userEmail) {
    getSubscriptionSwrKey = [
      'subscriptions',
      {
        params: {
          email: userEmail
        }
      }
    ];
  }

  const {data: subscriptionData, isLoading} = useSWR(getSubscriptionSwrKey);

  const onButtonClickHandler = useCallback(async () => {
    try {
      await axios.patch('/unsubscribe', {
        email: userEmail,
        lang: i18n.language
      });

      setUnsubscribeStatus('unsubscribed');
    } catch (e) {
      console.error(e);
      setUnsubscribeStatus('error');
    }
  }, [i18n.language, userEmail, setUnsubscribeStatus]);

  if (!getSubscriptionSwrKey) {
    return <NotFound to="/" />;
  }

  let contentJsx;
  let buttonJsx = null;

  if (isLoading) {
    contentJsx = t('loading');
  } else if (unsubscribeStatus === 'error') {
    contentJsx = t('smthgWentWrong');
  } else if (unsubscribeStatus === 'unsubscribed') {
    contentJsx = t('unsubscribed');
  } else {
    const subscription = subscriptionData?.data?.[0];

    if (subscription?.attributes?.status === 'unsubscribed') {
      contentJsx = t('alreadyUnsubscribed');
    } else {
      contentJsx = (
        <Trans i18nKey="text" ns="pages/unsubscribe">
          <span className={style.textLine}>
            Your address: <span className={style.userEmail}>{{userEmail}}</span>
          </span>
          <span className={style.textLine}>Do you really want to unsubscribe?</span>
        </Trans>
      );
      buttonJsx = <Button onClick={onButtonClickHandler}>{t('buttonTitle')}</Button>;
    }
  }

  return (
    <div className={style.container}>
      <Logo className={style.logo} />
      <div className={style.titleWrapper}>
        <h1 className={style.title}>{t('title')}</h1>
      </div>
      <div className={style.textWrapper}>
        <span className={style.text}>{contentJsx}</span>
      </div>
      {buttonJsx}
    </div>
  );
};

Unsubscribe.propTypes = {};

export default Unsubscribe;
