import PropTypes from 'prop-types';
import style from './ScreenBreakpointBadge.module.scss';
import styleVariables from '../../styles/variables.scss';
import useMatchMedia from 'use-match-media-hook';

const ScreenBreakpointBadge = ({trackBreakpoints}) => {
  let mediaQueries = [];

  if (trackBreakpoints.length) {
    mediaQueries = trackBreakpoints.map(breakpointKey => {
      const styleBreakpointKey = `breakpoint${breakpointKey[0].toUpperCase()}${breakpointKey.substring(1)}`;
      return `(min-width: ${styleVariables[styleBreakpointKey] || '0px'})`;
    });
  }

  const [...matchMediaBreakpoint] = useMatchMedia(mediaQueries);

  const matchedIndex = matchMediaBreakpoint.lastIndexOf(true);
  let nameToShow = trackBreakpoints[matchedIndex];

  if (!nameToShow) {
    nameToShow = 'None';
  }

  return <div className={style.container}>{nameToShow}</div>;
};

ScreenBreakpointBadge.propTypes = {
  trackBreakpoints: PropTypes.arrayOf(PropTypes.oneOf(['default', 'sm', 'md', 'lg', 'xl', 'xxl']))
};

ScreenBreakpointBadge.defaultProps = {
  trackBreakpoints: ['default', 'sm', 'md', 'lg', 'xl', 'xxl']
};

export default ScreenBreakpointBadge;
