import style from './Placeholder.module.scss';
import ContentWrapper from '../../../components/ContentWrapper';
import ContactItemPlaceholder from '../../Home/sections/ContactUs/ContactItem/Placeholder';
import XPaddingWrapper from '../../../components/XPaddingWrapper';

const Placeholder = () => {
  return (
    <XPaddingWrapper>
      <ContentWrapper>
        <section className={style.container}>
          <div className={style.title} />
          <div className={style.content}>
            <div className={style.itemsContainer}>
              <ContactItemPlaceholder />
              <ContactItemPlaceholder />
              <ContactItemPlaceholder />
            </div>
          </div>
        </section>
      </ContentWrapper>
    </XPaddingWrapper>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
