import PropTypes from 'prop-types';
import {GoogleReCaptchaProvider as Provider} from 'react-google-recaptcha-v3';
import {useTranslation} from 'react-i18next';

const GoogleReCaptchaProvider = ({children}) => {
  const {i18n} = useTranslation();
  return (
    <Provider
      reCaptchaKey={process.env.REACT_APP_RE_CAPTCHA_KEY}
      language={i18n.language}
      useEnterprise
      scriptProps={{
        async: true,
        defer: true
      }}>
      {children}
    </Provider>
  );
};

GoogleReCaptchaProvider.propTypes = {
  children: PropTypes.any
};

export default GoogleReCaptchaProvider;
