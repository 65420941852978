import style from './IncidenceTrend.module.scss';
import scssStyle from './IncidenceTrend.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef, useCallback, useState} from 'react';
import ContentWrapper from '../../../../components/ContentWrapper';
import useCount from '../../../../hooks/useCount';
import secondsOfYear from '../../../../services/dateTime';
import data from './data';
import {animated, useTransition} from '@react-spring/web';
import {Waypoint} from 'react-waypoint';

const IncidenceTrend = forwardRef((props, ref) => {
  const {t: homeT} = useTranslation('pages/home');
  const {t} = useTranslation();
  const [isSectionInViewport, setIsSectionInViewport] = useState(false);

  const [
    commonDeath,
    commonSick,
    womenSick,
    menSick,
    breastSick,
    lungSick,
    colorectumSick,
    prostateSick,
    stomachSick,
    otherSick
  ] = useCount(
    1_000,
    () => {
      const secondsInYear = 31_536_000;
      const secondOfYear = secondsOfYear();

      const commonDeathInSec = data.diedInAYear.common / secondsInYear;
      const commonDeath = Math.round(commonDeathInSec * secondOfYear);

      const commonSickInSec = data.sickInAYear.common / secondsInYear;
      const commonSick = Math.round(commonSickInSec * secondOfYear);

      const womenSickPercent = (data.sickInAYear.women / data.sickInAYear.common) * 100;

      const womenSick = Math.round((commonSick / 100) * womenSickPercent);
      const menSick = commonSick - womenSick;

      const breastSick = Math.round((commonSick / 100) * data.reasons.breast);
      const lungSick = Math.round((commonSick / 100) * data.reasons.lung);
      const colorectumSick = Math.round((commonSick / 100) * data.reasons.colorectum);
      const prostateSick = Math.round((commonSick / 100) * data.reasons.prostate);
      const stomachSick = Math.round((commonSick / 100) * data.reasons.stomach);
      const otherSick = Math.round((commonSick / 100) * data.reasons.other);

      return [
        commonDeath,
        commonSick,
        womenSick,
        menSick,
        breastSick,
        lungSick,
        colorectumSick,
        prostateSick,
        stomachSick,
        otherSick
      ];
    },
    isSectionInViewport
  );

  const numberTransitionProps = {
    keys: item => item.key,
    from: {opacity: 0, transform: 'translate3d(0,-50%,0)', display: 'inline-block'},
    enter: {opacity: 1, transform: 'translate3d(0,0%,0)'},
    leave: {opacity: 0, transform: 'translate3d(0,50%,0)'},
    config: {duration: 300},
    exitBeforeEnter: true,
    immediate: !isSectionInViewport
  };

  const fnMapForNumStr = keyPrefix => (character, i, arr) => {
    let prevItemsSum = arr.slice(0, i + 1).filter(c => !isNaN(c));

    if (prevItemsSum.length) {
      prevItemsSum = prevItemsSum.reduce((a, b) => a + b);
    }

    return {
      value: character,
      key: `${keyPrefix}-${i}-${character}-${prevItemsSum}`
    };
  };

  const formattedCommonDeathsArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: commonDeath, lng: 'en'})].map(fnMapForNumStr('common-deaths-char'))
    : [];
  const commonDeathsTransitions = useTransition(formattedCommonDeathsArr, numberTransitionProps);

  const formattedCommonSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: commonSick, lng: 'en'})].map(fnMapForNumStr('common-sick-char'))
    : [];
  const commonSickTransitions = useTransition(formattedCommonSickArr, numberTransitionProps);

  const formattedWomenSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: womenSick, lng: 'en'})].map(fnMapForNumStr('women-sick-char'))
    : [];
  const womenSickTransitions = useTransition(formattedWomenSickArr, numberTransitionProps);

  const formattedMenSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: menSick, lng: 'en'})].map(fnMapForNumStr('men-sick-char'))
    : [];
  const menSickTransitions = useTransition(formattedMenSickArr, numberTransitionProps);

  const formattedBreastSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: breastSick, lng: 'en'})].map(fnMapForNumStr('breast-sick-char'))
    : [];
  const breastSickTransitions = useTransition(formattedBreastSickArr, numberTransitionProps);

  const formattedLungSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: lungSick, lng: 'en'})].map(fnMapForNumStr('lung-sick-char'))
    : [];
  const lungSickTransitions = useTransition(formattedLungSickArr, numberTransitionProps);

  const formattedColorectumSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: colorectumSick, lng: 'en'})].map(fnMapForNumStr('colorectum-sick-char'))
    : [];
  const colorectumSickTransitions = useTransition(formattedColorectumSickArr, numberTransitionProps);

  const formattedProstateSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: prostateSick, lng: 'en'})].map(fnMapForNumStr('prostate-sick-char'))
    : [];
  const prostateSickTransitions = useTransition(formattedProstateSickArr, numberTransitionProps);

  const formattedStomachSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: stomachSick, lng: 'en'})].map(fnMapForNumStr('stomach-sick-char'))
    : [];
  const stomachSickTransitions = useTransition(formattedStomachSickArr, numberTransitionProps);

  const formattedOtherSickArr = isSectionInViewport
    ? [...t('illnessCounter.numberValue', {val: otherSick, lng: 'en'})].map(fnMapForNumStr('other-sick-char'))
    : [];
  const otherSickTransitions = useTransition(formattedOtherSickArr, numberTransitionProps);

  const onViewpointEnter = useCallback(() => {
    setIsSectionInViewport(true);
  }, []);

  const onViewpointLeave = useCallback(() => {
    setIsSectionInViewport(false);
  }, []);

  return (
    <Waypoint onEnter={onViewpointEnter} onLeave={onViewpointLeave} scrollableAncestor={window}>
      <ContentWrapper>
        <section className={style.container} ref={ref}>
          <div className={style.titleWrapper}>
            <h1 className={style.title}>{homeT('incidenceTrend.sectionTitle')}</h1>
          </div>
          <div className={style.deathCounter}>
            <div className={style.counterTitle}>
              {t('illnessCounter.commonDeathsThisYear.title', {
                val: new Date(),
                formatParams: {
                  val: {year: 'numeric', month: 'long', day: 'numeric'}
                }
              })}
            </div>
            <div className={style.counter}>
              {commonDeathsTransitions((style, item) => (
                <animated.div key={item.key} style={style} className={scssStyle.digit}>
                  {item.value}
                </animated.div>
              ))}
            </div>
          </div>
          <div className={style.incidenceCommon}>
            <div className={style.blockTitle}>
              {t('illnessCounter.sickTable.header', {
                val: new Date(),
                formatParams: {
                  val: {year: 'numeric', month: 'long', day: 'numeric'}
                }
              })}
            </div>
            <div className={style.sickCommonTitleValue}>
              <div className={style.sickCommonTitle}>{t('illnessCounter.sickTable.rowTitles.common')}</div>
              <div className={style.sickCommonValue}>
                {commonSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>
            </div>
            <div className={style.sickCommonTitleValue}>
              <div className={style.sickCommonTitle}>{t('illnessCounter.sickTable.rowTitles.women')}</div>
              <div className={style.sickCommonValue}>
                {womenSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>
            </div>
            <div className={style.sickCommonTitleValue}>
              <div className={style.sickCommonTitle}>{t('illnessCounter.sickTable.rowTitles.mens')}</div>
              <div className={style.sickCommonValue}>
                {menSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>
            </div>
          </div>
          <div className={style.incidenceTypes}>
            <div className={style.blockTitle}>
              {t('illnessCounter.reasonsTable.colHeaderTitles.main', {
                val: new Date(),
                formatParams: {
                  val: {year: 'numeric', month: 'long', day: 'numeric'}
                }
              })}
            </div>
            <div className={style.sickPseudoTable}>
              <div className={style.titleHeader}>{t('illnessCounter.reasonsTable.colHeaderTitles.cancerSite')}</div>
              <div className={style.valueHeader}>{t('illnessCounter.reasonsTable.colHeaderTitles.value')}</div>

              <div className={style.titleDataCell}>{t('illnessCounter.reasonsTable.rowTitles.breast')}</div>
              <div className={style.valueDataCell}>
                {breastSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>

              <div className={style.titleDataCell}>{t('illnessCounter.reasonsTable.rowTitles.lung')}</div>
              <div className={style.valueDataCell}>
                {lungSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>

              <div className={style.titleDataCell}>{t('illnessCounter.reasonsTable.rowTitles.colorectum')}</div>
              <div className={style.valueDataCell}>
                {colorectumSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>

              <div className={style.titleDataCell}>{t('illnessCounter.reasonsTable.rowTitles.prostate')}</div>
              <div className={style.valueDataCell}>
                {prostateSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>

              <div className={style.titleDataCell}>{t('illnessCounter.reasonsTable.rowTitles.stomach')}</div>
              <div className={style.valueDataCell}>
                {stomachSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>

              <div className={style.titleDataCell}>{t('illnessCounter.reasonsTable.rowTitles.other')}</div>
              <div className={style.valueDataCell}>
                {otherSickTransitions((style, item) => (
                  <animated.div key={item.key} style={style} className={scssStyle.sickCommonValueDigit}>
                    {item.value}
                  </animated.div>
                ))}
              </div>
            </div>
          </div>
          <div className={style.bottom}>
            <a
              href="https://gco.iarc.fr/today/home"
              target="_blank"
              rel="noreferrer"
              className="with-animated-underline">
              {t('illnessCounter.illnessCounterWebsiteLink.title')}
            </a>
          </div>
        </section>
      </ContentWrapper>
    </Waypoint>
  );
});

IncidenceTrend.displayName = 'IncidenceTrend';
IncidenceTrend.hashRoute = '#incidence-trend';

export default IncidenceTrend;
