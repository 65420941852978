import PlaceholderTextLine from '../../../../components/PlaceholderTextLine';
import FunctionalPagePlaceholder from '../../../Functional/Placeholder';

const Placeholder = () => {
  return (
    <FunctionalPagePlaceholder>
      <PlaceholderTextLine />
      <PlaceholderTextLine />
      <PlaceholderTextLine />
    </FunctionalPagePlaceholder>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
