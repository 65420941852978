import originStyle from '../ArticlePreview.module.scss';
import style from './Placeholder.module.scss';
import classnames from 'classnames';
import PlaceholderTextLine from '../../PlaceholderTextLine';

const Placeholder = () => {
  return (
    <div className={classnames(originStyle.container, style.container)}>
      <div className={classnames(originStyle.imagePreviewWrapper, style.imagePreviewWrapper)}>
        <div className={classnames(originStyle.img, style.img)} />
      </div>
      <div className={originStyle.articleHeader}>
        <PlaceholderTextLine className={style.title} />
      </div>
      <div className={originStyle.textPreview}>
        <PlaceholderTextLine className={style.textPreviewLine} />
        <PlaceholderTextLine className={style.textPreviewLine} />
        <PlaceholderTextLine className={style.textPreviewLine} />
        <PlaceholderTextLine className={style.textPreviewLine} />
        <PlaceholderTextLine className={style.textPreviewLine} />
        <PlaceholderTextLine className={style.textPreviewLine} />
        <PlaceholderTextLine className={style.textPreviewLine} />
      </div>
      <div className={classnames(originStyle.dotsWrapper, style.dotsWrapper)}>
        <div className={classnames(originStyle.dots, style.dots)}>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
