import PropTypes from 'prop-types';
import style from './PartnerItem.module.scss';

const LogoExternalLink = ({img, url, name}) => (
  <a className={style.container} href={url} target="_blank" rel="noreferrer">
    <img
      src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${img}`}
      alt={name}
      className={style.img}
      width="100%"
      height="100%"
    />
    <div className={style.titleContainer}>
      <span className={style.title}>{name}</span>
    </div>
  </a>
);

LogoExternalLink.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string
};

export default LogoExternalLink;
