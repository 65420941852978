/* eslint-disable */
import PropTypes from 'prop-types';
import style from './Placeholder.module.scss';
import originStyle from '../RoadmapDateView.module.scss';
import PlaceholderTextLine from '../../PlaceholderTextLine';
import classnames from 'classnames';

const Placeholder = ({isPrev, isActive, isNext}) => {
  return (
    <div
      className={classnames(originStyle.container, style.container, {
        [style.isPrev]: isPrev,
        [style.isActive]: isActive,
        [style.isNext]: isNext
      })}>
      <div className={classnames(originStyle.upperBlock, style.upperBlock)}>
        <PlaceholderTextLine className={classnames(originStyle.year, style.year)} />
        <div className={classnames(originStyle.point, style.point)} />
      </div>
      <div className={classnames(originStyle.bottomBlock, style.bottomBlock)}>
        <PlaceholderTextLine className={classnames(originStyle.description, style.description)} />
        <PlaceholderTextLine className={classnames(originStyle.description, style.description)} />
      </div>
    </div>
  );
};

Placeholder.propTypes = {
  isFinalSlide: PropTypes.bool,
  isPrev: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isNext: PropTypes.bool.isRequired
};

export default Placeholder;
