/* eslint-disable */
// noinspection ES6UnusedImports

import PropTypes from 'prop-types';
import style from './Form.module.scss';
import {animated, useSpring, useTransition} from '@react-spring/web';
import {ErrorMessage, Field, Form as FormikForm, useFormikContext} from 'formik';
import AnimatedErrorMessage from '../../AnimatedMessage';
import classnames from 'classnames';
// import Button from '../../Button';
import LoadingButton from '../../Button/LoadingButton';
import {useCallback, useEffect, useId, useState} from 'react';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {Trans, useTranslation} from 'react-i18next';
import Loader from '../Loader';
import useHeight from '../../../hooks/useHeight';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import axios from '../../../services/axios';
import PaymentResultNotification from '../../PaymentResultNotification';
import Tabs from '../../Tabs';
import Button from '../../Button';
import Link from '../../Link';
import Checkbox from '../../Checkbox';
import CreditCard from './CreditCard';

const Form = () => {
  const {t, i18n} = useTranslation();

  const amountInputId = useId();

  const {values, isSubmitting, setTouched, dirty, isValid, setSubmitting, setFieldError, resetForm} =
    useFormikContext();

  const {executeRecaptcha} = useGoogleReCaptcha();

  const [isCaughtError, setIsCaughtError] = useState(false);

  const transition = useTransition(
    {isSubmitting, isCaughtError},
    {
      from: {
        y: '10%',
        opacity: 0.0
      },
      enter: {
        y: '0%',
        opacity: 1.0
      },
      leave: {
        y: '-10%',
        opacity: 0.0
      },
      keys: item => `${item.isCaughtError}-${item.isSubmitting}`
    }
  );

  const processPayment = useCallback(
    async payload => {
      const values = payload.formData;
      let captchaToken;
      let paymentResult;

      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha(process.env.REACT_APP_RE_CAPTCHA_SUBSCRIPTION_ACTION);
      }

      const data = {
        amount: Number(values.amount) * 100,
        captcha_token: captchaToken
      };

      setSubmitting(true);

      try {
        paymentResult = await axios.post('/monobank/merchant/invoice/create', data);
        window.location.replace(paymentResult.data.pageUrl);
      } catch (e) {
        const responseData = e?.response?.data;

        if (responseData?.name === 'RequestParamsError') {
          responseData.errors.forEach(error => {
            let fieldKey = error.fieldKey;

            if (fieldKey === 'captcha_token') {
              fieldKey = 'captchaToken';
            }

            setFieldError(fieldKey, error.i18nKey);
          });
        } else {
          setIsCaughtError(true);
        }

        setSubmitting(false);
      }
    },
    [setSubmitting, executeRecaptcha, i18n?.language]
  );

  const onClickPayButton = useCallback(
    async (isSubscription = false) => {
      if (!dirty || !isValid) {
        setTouched(
          {
            amount: true
          },
          true
        );
        return;
      }

      const payload = {
        formData: {
          ...values,
          is_subscription: isSubscription
        }
      };

      await processPayment(payload);
    },
    [dirty, isValid, processPayment, setTouched, values]
  );

  const backToFormHandler = useCallback(() => {
    resetForm();
    setIsCaughtError(false);
  }, [resetForm]);

  const [heightRef, height] = useHeight();
  const containerSpringStyle = useSpring({height});

  return (
    <animated.div className={style.container} style={containerSpringStyle}>
      {transition((springStyle, {isSubmitting, isCaughtError}) => {
        if (isSubmitting && !isCaughtError) {
          return (
            <animated.div style={springStyle} className={style.animatedContentWrapper}>
              <Loader />
            </animated.div>
          );
        } else if (isCaughtError === true) {
          return (
            <animated.div style={springStyle} className={style.animatedContentWrapper}>
              <PaymentResultNotification
                onClickOk={backToFormHandler}
                buttonTitle={t('creditCardDonation.paymentResult.error.backToFormButtonTitle')}
                paymentStatus={PaymentResultNotification.paymentStatuses.ERROR}
              />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={springStyle} className={style.animatedContentWrapper} ref={heightRef}>
              <FormikForm className={style.form}>
                <div className={style.fieldsWrapper}>
                  <label htmlFor={amountInputId}>{t('creditCardDonation.amount.label')}</label>
                  <div className={style.fieldWrapper}>
                    <Field type="number" step={10} name="amount">
                      {({field}) => {
                        return (
                          <input
                            id={amountInputId}
                            name="amount"
                            type="number"
                            min={1}
                            step={10}
                            placeholder={t('creditCardDonation.amount.inputPlaceholder')}
                            className={style.inputAmount}
                            {...field}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="amount">
                      {errorI18Key => (
                        <AnimatedErrorMessage className={style.errorMessage} type="error">
                          {t(errorI18Key)}
                        </AnimatedErrorMessage>
                      )}
                    </ErrorMessage>
                  </div>
                  <LoadingButton
                    className={style.button}
                    color="red"
                    disabled={isSubmitting}
                    onClick={onClickPayButton}
                    loadingTitle={t('creditCardDonation.loadingButton')}>
                    {t('creditCardDonation.oneTime.donateButton')}
                  </LoadingButton>
                </div>
              </FormikForm>
            </animated.div>
          );
        }
      })}
    </animated.div>
  );
};

export default Form;
