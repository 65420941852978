import {useLocation} from 'react-router-dom';
import supportedLanguages from '../services/i18n/supportedLanguages';

const useNewPathWithLangPrefix = to => {
  const {pathname} = useLocation();

  const usedLangPrefix = supportedLanguages
    .map(l => l.iso639_1 || l.iso3166_2)
    .find(lang => pathname.startsWith(`/${lang}`));
  if (usedLangPrefix) {
    if (to) {
      if (to.startsWith('#')) {
        return `/${usedLangPrefix}${to}`;
      } else if (to.startsWith('/')) {
        return `/${usedLangPrefix}${to}`;
      } else {
        return `/${usedLangPrefix}/${to}`;
      }
    } else {
      return `/${usedLangPrefix}`;
    }
  } else {
    if (to.startsWith('#')) {
      return `/${to}`;
    } else {
      return to;
    }
  }
};

export default useNewPathWithLangPrefix;
