import style from './Wrapper.module.scss';
import PropTypes from 'prop-types';

const Wrapper = ({children}) => <div className={style.wrapper}>{children}</div>;

Wrapper.propTypes = {
  children: PropTypes.any
};

export default Wrapper;
