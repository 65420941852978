import style from './HLanguageSwitcher.module.scss';
import classnames from 'classnames';
import LanguageSwitcher from '../../../LanguageSwitcher';
import {NavLink} from 'react-router-dom';
import useMatchMedia from 'use-match-media-hook';

const HLanguageSwitcher = () => {
  const [isTabletSize] = useMatchMedia(['(min-width: 768px)']);

  return (
    <div className={style.wrapper}>
      <LanguageSwitcher>
        {({lang, isActive, getNewPath, changeLanguage}) => {
          if (!isActive || isTabletSize) {
            return (
              <NavLink
                key={getNewPath()}
                to={getNewPath()}
                className={() =>
                  classnames('with-animated-underline', {
                    active: isActive
                  })
                }
                onClick={changeLanguage}>
                {lang.charAt(0).toUpperCase() + lang.slice(1)}
              </NavLink>
            );
          } else {
            return null;
          }
        }}
      </LanguageSwitcher>
    </div>
  );
};

export default HLanguageSwitcher;
