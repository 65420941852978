/* eslint-disable */
import style from './TeamMemberPreview.module.scss';
import PropTypes from 'prop-types';
import useNewPathWithLangPrefix from '../../hooks/useNewPathWithLangPrefix';
import {useLocation, useNavigate} from 'react-router-dom';
import {useCallback} from 'react';

const TeamMemberPreview = ({
  id,
  name,
  position,
  previewText,
  previewImage,
  previewImageSources,
  linkTemplate,
  socialMedias
}) => {
  const pictureSourcesJsx = previewImageSources.map(previewImageSource => (
    <source
      type={previewImageSource.type}
      srcSet={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${previewImageSource.src}`}
      key={`${previewImageSource.src}-source`}
    />
  ));

  const toFullArticle = useNewPathWithLangPrefix(linkTemplate.replace(':id', id));
  const currentLocation = useLocation();
  const state = {
    previousLocation: currentLocation
  };
  const navigate = useNavigate();

  const onMoreClickHandler = useCallback(() => {
    navigate(toFullArticle, {state});
  }, [toFullArticle, currentLocation.key]);

  const socialMediasJsx = socialMedias?.map(socialMedia => (
    <span key={`team-member-${id}-social-media-${socialMedia.id}`}>
      <a href={socialMedia.link} target="_blank" rel="noreferrer" className="with-animated-underline">
        <img
          src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${socialMedia.logo.data.attributes.url}`}
          alt={socialMedia.name}
          className={style.socialMediaIcon}
          width={16}
          height={16}
        />
      </a>
    </span>
  ));

  return (
    <div
      className={style.container}
      role="link"
      tabIndex={0}
      onClick={onMoreClickHandler}
      onKeyDown={onMoreClickHandler}>
      <div className={style.imagePreviewWrapper}>
        <picture className={style.img}>
          {pictureSourcesJsx}
          <img
            src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${previewImage}`}
            className={style.img}
            alt="Team Member Bio Preview"
            width={240}
            height={136}
          />
        </picture>
      </div>
      <div className={style.memberName}>{name}</div>
      <div className={style.position}>{position}</div>
      <div className={style.socialMedias}>{socialMediasJsx}</div>
      <div className={style.textPreview}>{previewText}</div>
    </div>
  );
};

TeamMemberPreview.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  previewText: PropTypes.string.isRequired,
  previewImage: PropTypes.string.isRequired,
  previewImageSources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string
    })
  ),
  linkTemplate: PropTypes.string,
  socialMedias: PropTypes.arrayOf(PropTypes.object)
};

TeamMemberPreview.defaultProps = {
  previewImageSources: [],
  linkTemplate: '/article/:id'
};

export default TeamMemberPreview;
