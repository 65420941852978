import style from './CookieConsentModal.module.scss';
import CookieConsent from 'react-cookie-consent';
import {Trans, useTranslation} from 'react-i18next';
import {animated, config, useSpring} from '@react-spring/web';
import Link from '../Link';

const CookieConsentModal = () => {
  const {t} = useTranslation();

  const springFadeInStyle = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    config: config.slow
  });

  return (
    <animated.div className={style.container} style={springFadeInStyle}>
      <CookieConsent
        location="none"
        disableStyles
        containerClasses={style.consentContainer}
        contentClasses={style.content}
        buttonClasses={style.button}
        buttonText={t('consentModal.acceptButtonText')}
        sameSite="strict">
        <Trans i18nKey="consentModal.message">
          This website uses cookies to enhance the user experience. Please check our
          <Link to="terms" className="with-animated-underline">
            Terms of Use.
          </Link>
        </Trans>
      </CookieConsent>
    </animated.div>
  );
};

CookieConsentModal.propTypes = {};

export default CookieConsentModal;
