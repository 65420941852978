import PropTypes from 'prop-types';
import style from './PlaceholderTextLine.module.scss';
import classnames from 'classnames';

const PlaceholderTextLine = ({className}) => {
  return <div className={classnames(style.container, className)} />;
};

PlaceholderTextLine.propTypes = {
  className: PropTypes.string
};

export default PlaceholderTextLine;
