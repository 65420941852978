import PropTypes from 'prop-types';
import style from './PaymentResultNotification.module.scss';
import SuccessIcon from './icons/SuccessIcon';
import ErrorIcon from './icons/ErrorIcon';
import {useTranslation} from 'react-i18next';
import Button from '../Button';
import {useLocation, useNavigate} from 'react-router-dom';

const PaymentResultNotification = ({paymentId, paymentStatus, onClickOk, buttonTitle, payerName}) => {
  const {t, i18n} = useTranslation();
  const currentLocation = useLocation();
  const navigate = useNavigate();

  let iconJsx;
  let title;
  let paymentIdJsx = null;
  let secondaryInfoJsx = null;
  let _buttonTitle;

  if (paymentStatus === PaymentResultNotification.paymentStatuses.SUCCESS) {
    title = t('creditCardDonation.paymentResult.success.title');
    iconJsx = <SuccessIcon className={style.icon} />;
    secondaryInfoJsx = (
      <div className={style.secondaryInfoWrapper}>
        <span className={style.text}>
          {t('creditCardDonation.paymentResult.success.thanksWords', {name: payerName})}
        </span>
      </div>
    );
    _buttonTitle = buttonTitle || t('creditCardDonation.paymentResult.success.buttonTitle');
  } else {
    title = t('creditCardDonation.paymentResult.error.title');
    iconJsx = <ErrorIcon className={style.icon} />;
    _buttonTitle = buttonTitle || t('creditCardDonation.paymentResult.error.backToHomeButtonTitle');
  }

  if (paymentId) {
    paymentIdJsx = (
      <div className={style.paymentId}>{`${t('creditCardDonation.paymentResult.paymentIdTitle')} ${paymentId}`}</div>
    );
  }

  const onClickHandler = () => {
    if (onClickOk) {
      onClickOk();
    } else {
      navigate(`/${i18n.resolvedLanguage}`, {previousLocation: currentLocation});
    }
  };

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.iconWrapper}>{iconJsx}</div>
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{title}</h1>
        </div>
        {paymentIdJsx}
        {secondaryInfoJsx}
        <Button onClick={onClickHandler} className={style.button}>
          {_buttonTitle}
        </Button>
      </div>
    </div>
  );
};

PaymentResultNotification.paymentStatuses = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

PaymentResultNotification.propTypes = {
  paymentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentStatus: PropTypes.oneOf([...Object.values(PaymentResultNotification.paymentStatuses)]).isRequired,
  onClickOk: PropTypes.func,
  buttonTitle: PropTypes.string,
  payerName: PropTypes.string
};

export default PaymentResultNotification;
