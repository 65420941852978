import styled from './SitePreloader.module.scss';
import {animated, config, useSpring} from '@react-spring/web';
import DnaLoader from './DnaLoader';

const SitePreloader = () => {
  const springStyle = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    config: config.slow
  });

  return (
    <div className={styled.sitePreloader}>
      <animated.div style={springStyle}>
        <DnaLoader />
      </animated.div>
    </div>
  );
};

export default SitePreloader;
