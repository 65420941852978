import PropTypes from 'prop-types';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {useId} from 'react';
import AnimatedMessage from '../../AnimatedMessage';
import {Trans, useTranslation} from 'react-i18next';
import style from './SingleFieldForm.module.scss';
import classnames from 'classnames';
import Button from '../../Button';

const SingleFieldForm = ({
  label,
  name,
  placeholder,
  type,
  onSubmit,
  validationSchema,
  buttonTitle,
  component,
  steps
}) => {
  const id = useId();
  const {t} = useTranslation();
  const {t: tHomePage} = useTranslation('pages/home');

  return (
    <Formik
      initialValues={{
        [name]: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(data, actions) => onSubmit?.(data[name], actions)}>
      {() => {
        return (
          <Form className={style.form}>
            <div className={style.fieldWrapper}>
              <label htmlFor={id}>{label}</label>
              <Field
                id={id}
                type={type}
                name={name}
                component={component}
                placeholder={placeholder}
                className={classnames('md-size', style.field)}
              />
              <ErrorMessage name={name}>
                {errorI18Key => (
                  <AnimatedMessage className={style.errorMessage} type="error">
                    {t(errorI18Key)}
                  </AnimatedMessage>
                )}
              </ErrorMessage>
            </div>
            <div className={style.termsNotice}>
              <Trans i18nKey="contactUs.termsNotice" ns="pages/home">
                This site is protected by reCAPTCHA and the Google{' '}
                <a
                  href="https://policies.google.com/privacy"
                  className="with-animated-underline green"
                  target="_blank"
                  rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://policies.google.com/terms"
                  className="with-animated-underline green"
                  target="_blank"
                  rel="noreferrer">
                  Terms of Service
                </a>{' '}
                apply.
              </Trans>
            </div>
            <div className={style.bottomBlock}>
              <div className={style.leftSide}>
                <div className={style.tip}>
                  <span className={style.enter}>{steps.current}</span>/{steps.total}{' '}
                  {tHomePage('contactUs.form.tips.step')}
                </div>
                <div className={style.tip}>
                  <Trans t={tHomePage} i18nKey="contactUs.form.tips.pressEnter">
                    press <span className={style.enter}>Enter ↵</span> or click
                  </Trans>
                </div>
              </div>
              <div className={style.rightSide}>
                <Button type="submit" className={style.button}>
                  {buttonTitle}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

SingleFieldForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.object,
  buttonTitle: PropTypes.string,
  component: PropTypes.oneOf(['input', 'select', 'textarea']),
  steps: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number
  }).isRequired
};

SingleFieldForm.defaultProps = {
  buttonTitle: 'Next',
  component: 'input'
};

export default SingleFieldForm;
