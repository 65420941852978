import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import Application from './App';
import {BrowserRouter} from 'react-router-dom';
import './services/i18n';
import SitePreloader from './components/SitePreloader';
import './fonts';
import ReactGA from 'react-ga4';
import ErrorBoundary from './components/ErrorBoundary';
import GoogleReCaptchaProvider from './components/GoogleReCaptchaProvider';

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider>
      <React.Suspense fallback={<SitePreloader />}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ErrorBoundary>
            <Application />
          </ErrorBoundary>
        </BrowserRouter>
      </React.Suspense>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
