import PropTypes from 'prop-types';
import style from './SearchItem.module.scss';
import classnames from 'classnames';

const SearchItem = ({name, icon, iconType, onClick}) => {
  const clickProps = onClick
    ? {
        onClick,
        onKeyDown: onClick,
        tabIndex: 0,
        role: 'button'
      }
    : {};

  const iconJsx =
    iconType === 'image' ? (
      <img
        src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${icon}`}
        alt={`${name} Icon`}
        className={style.icon}
        width={40}
        height={40}
      />
    ) : (
      <span className={classnames(style.icon, style.iconText)}>{icon}</span>
    );

  const metaJsx =
    iconType === 'image' ? (
      <div className={style.meta}>
        <span className={style.name}>{name}</span>
      </div>
    ) : null;

  return (
    <div className={style.container} {...clickProps}>
      {iconJsx}
      {metaJsx}
    </div>
  );
};

SearchItem.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconType: PropTypes.oneOf(['image', 'text']),
  onClick: PropTypes.func
};

SearchItem.defaultProps = {
  iconType: 'image'
};

export default SearchItem;
