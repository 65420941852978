import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

const usePageTitle = (pageName = null) => {
  const {t} = useTranslation('ui');
  const location = useLocation();
  useEffect(() => {
    document.title = pageName ? `${pageName} | ${t('name')}` : t('name');
  }, [location.key, pageName]);
};

export default usePageTitle;
