import style from './Reviews.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef, useState, useCallback, useRef, useEffect} from 'react';
import Review from '../../../../components/Review';
import ReviewPlaceholder from '../../../../components/Review/Placeholder';
import ContentWrapper from '../../../../components/ContentWrapper';
import '../../../../styles/swiper.scss';
import 'swiper/scss/mousewheel';
import {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useWindowWidth} from '@react-hook/window-size';
import useSWR from '../../../../hooks/swr/useSWR';
import {Waypoint} from 'react-waypoint';

const Reviews = forwardRef((props, ref) => {
  const {t} = useTranslation('pages/home');
  const [isSectionInViewport, setIsSectionInViewport] = useState(false);
  const swiperRef = useRef();

  useEffect(() => {
    if (isSectionInViewport) {
      swiperRef.current.swiper.autoplay.start();
    } else {
      swiperRef.current.swiper.autoplay.stop();
    }
  }, [swiperRef.current, isSectionInViewport]);

  const {
    data: reviewsData,
    isLoading,
    isError
  } = useSWR(['reviews', {params: {populate: ['reviewer', 'reviewer.avatar']}}]);

  const windowWidth = useWindowWidth();

  let swiperSlides;
  if (isLoading || isError) {
    swiperSlides = [
      <SwiperSlide className="fadeout" key="placeholder-review-slide-1">
        <ReviewPlaceholder />
      </SwiperSlide>,
      <SwiperSlide className="fadeout" key="placeholder-review-slide-2">
        <ReviewPlaceholder />
      </SwiperSlide>,
      <SwiperSlide className="fadeout" key="placeholder-review-slide-3">
        <ReviewPlaceholder />
      </SwiperSlide>
    ];
  } else {
    swiperSlides = reviewsData.data.map((review, i) => {
      const reviewer = review.attributes.reviewer.data;
      return (
        <SwiperSlide className="fadeout" key={`review-slide-${i}`}>
          <Review
            author={{
              name: reviewer.attributes.fullName,
              position: reviewer.attributes.position,
              avatarSrc: reviewer.attributes.avatar.data.attributes.url
            }}
            comment={review.attributes.quote}
          />
        </SwiperSlide>
      );
    });
  }

  const onViewpointEnter = useCallback(() => {
    setIsSectionInViewport(true);
  }, []);

  const onViewpointLeave = useCallback(() => {
    setIsSectionInViewport(false);
  }, []);

  return (
    <Waypoint onEnter={onViewpointEnter} onLeave={onViewpointLeave} scrollableAncestor={window}>
      <div>
        <section className={style.container} ref={ref}>
          <ContentWrapper>
            <div className={style.titleWrapper}>
              <h1 className={style.title}>{t('reviews.sectionTitle')}</h1>
            </div>
          </ContentWrapper>
          <div className={style.reviews}>
            <Swiper
              ref={swiperRef}
              slidesPerView={2}
              spaceBetween={20}
              autoplay={
                isSectionInViewport
                  ? {
                      delay: 1000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true
                    }
                  : false
              }
              speed={15000}
              modules={[Autoplay]}
              breakpoints={{
                768: {
                  slidesOffsetBefore: 80
                },
                1280: {
                  slidesOffsetBefore: 120,
                  slidesPerView: 4
                },
                1920: {
                  slidesOffsetBefore: (windowWidth - (1920 - 400)) / 2,
                  slidesPerView: 4
                }
              }}
              loop
              loopFillGroupWithBlank>
              {swiperSlides}
            </Swiper>
          </div>
        </section>
      </div>
    </Waypoint>
  );
});

Reviews.displayName = 'Reviews';
Reviews.hashRoute = '#reviews';

export default Reviews;
