/* eslint-disable no-unused-vars */
import style from './Stage2.module.scss';
import {Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../../Button';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CopyIcon} from '../../../CryptoDonateForm/stages/Stage3/icons/copy.svg';
import copy from 'clipboard-copy';

const Stage2 = ({currency, onBackButtonClick}) => {
  const {t} = useTranslation();

  const handleBackButtonClick = useCallback(() => {
    onBackButtonClick?.();
  }, [onBackButtonClick]);

  const swiftCodeFieldJsx = currency.company.bankSwiftCode ? (
    <>
      <label>{t('swiftDonation.stages.2.bankSwiftCodeTitle')}</label>
      <div className={style.data}>{currency.company.bankSwiftCode}</div>
      <div>
        <Button onClick={() => copy(currency.company.bankSwiftCode)} className={classnames(style.valueCopyButton)}>
          <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
        </Button>
      </div>
    </>
  ) : null;

  const recipientCodeFieldJsx = currency.company.recipientCode ? (
    <>
      <label>{t('swiftDonation.stages.2.recipientCodeTitle')}</label>
      <div className={style.data}>{currency.company.recipientCode}</div>
      <div>
        <Button onClick={() => copy(currency.company.recipientCode)} className={classnames(style.valueCopyButton)}>
          <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
        </Button>
      </div>
    </>
  ) : null;

  let correspondentBanksBlockJsx = null;

  if (currency.correspondentBanks?.length) {
    const correspondentBankItemsJsx = currency.correspondentBanks.map((correspondentBank, i, array) => {
      const orJsx =
        i + 1 !== array.length ? (
          <div className={style.orContainer}>
            <span className={style.orContentWrapper}>{t('swiftDonation.stages.2.or')}</span>
          </div>
        ) : null;

      const intermediaryBankJsx = correspondentBank.intermediaryBank ? (
        <>
          <label>{t('swiftDonation.stages.2.intermediaryBank')}</label>
          <div className={style.data}>{correspondentBank.intermediaryBank}</div>
          <div>
            <Button
              onClick={() => copy(correspondentBank.intermediaryBank)}
              className={classnames(style.valueCopyButton)}>
              <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
            </Button>
          </div>
          <label>{t('swiftDonation.stages.2.intermediaryBankAccount')}</label>
          <div className={style.data}>{correspondentBank.intermediaryBankAccount}</div>
          <div>
            <Button
              onClick={() => copy(correspondentBank.intermediaryBankAccount)}
              className={classnames(style.valueCopyButton)}>
              <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
            </Button>
          </div>
          <label>{t('swiftDonation.stages.2.intermediaryBankIdentificationCode')}</label>
          <div className={style.data}>{correspondentBank.intermediaryBankIdentificationCode}</div>
          <div>
            <Button
              onClick={() => copy(correspondentBank.intermediaryBankIdentificationCode)}
              className={classnames(style.valueCopyButton)}>
              <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
            </Button>
          </div>
        </>
      ) : null;

      return (
        <Fragment key={`correspondentBank-item-${i}`}>
          <label>{t('swiftDonation.stages.2.bankNameTitle')}</label>
          <div className={style.data}>{correspondentBank.bank}</div>
          <div>
            <Button onClick={() => copy(correspondentBank.bank)} className={classnames(style.valueCopyButton)}>
              <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
            </Button>
          </div>
          <label>{t('swiftDonation.stages.2.bankAccountTitle')}</label>
          <div className={style.data}>{correspondentBank.account}</div>
          <div>
            <Button onClick={() => copy(correspondentBank.account)} className={classnames(style.valueCopyButton)}>
              <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
            </Button>
          </div>
          <label>{t('swiftDonation.stages.2.bankSwiftCodeTitle')}</label>
          <div className={style.data}>{correspondentBank.swiftCode}</div>
          <div>
            <Button onClick={() => copy(correspondentBank.swiftCode)} className={classnames(style.valueCopyButton)}>
              <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
            </Button>
          </div>
          <label>{t('swiftDonation.stages.2.purposeOfPayment')}</label>
          <div className={style.data}>{correspondentBank.purposeOfPayment}</div>
          <div>
            <Button
              onClick={() => copy(correspondentBank.purposeOfPayment)}
              className={classnames(style.valueCopyButton)}>
              <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
            </Button>
          </div>
          {intermediaryBankJsx}
          {orJsx}
        </Fragment>
      );
    });

    correspondentBanksBlockJsx = (
      <>
        <div className={style.sectionTitle}>{t('swiftDonation.stages.2.correspondentBanksTitle')}</div>

        {correspondentBankItemsJsx}
      </>
    );
  }

  return (
    <div className={style.container}>
      <div className={classnames(style.backButtonWrapper, style.twoColumnsWidth)}>
        <Button onClick={handleBackButtonClick} className={classnames(style.backButton, 'with-animated-underline')}>
          {t('swiftDonation.stages.2.backButtonTitle')}
        </Button>
      </div>
      <label>{t('swiftDonation.stages.2.currencyTitle')}</label>
      <div className={classnames(style.data, style.twoColumnsWidth)}>
        {t(currency.name.i18nKey)} ({currency.abbr})
      </div>
      <div className={classnames(style.sectionTitle, style.twoColumnsWidth)}>
        {t('swiftDonation.stages.2.companyDetailsTitle')}
      </div>
      <label>{t('swiftDonation.stages.2.companyNameTitle')}</label>
      <div className={style.data}>{currency.company.name}</div>
      <div>
        <Button onClick={() => copy(currency.company.name)} className={classnames(style.valueCopyButton)}>
          <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
        </Button>
      </div>
      <label>{t('swiftDonation.stages.2.ibanTitle')}</label>
      <div className={style.data}>{currency.company.iban}</div>
      <div>
        <Button onClick={() => copy(currency.company.iban)} className={classnames(style.valueCopyButton)}>
          <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
        </Button>
      </div>
      <label>{t('swiftDonation.stages.2.bankNameTitle')}</label>
      <div className={style.data}>{currency.company.bank}</div>
      <div>
        <Button onClick={() => copy(currency.company.bank)} className={classnames(style.valueCopyButton)}>
          <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
        </Button>
      </div>
      {swiftCodeFieldJsx}
      {recipientCodeFieldJsx}
      <label>{t('swiftDonation.stages.2.companyAddressTitle')}</label>
      <div className={style.data}>{currency.company.address}</div>
      <div>
        <Button onClick={() => copy(currency.company.address)} className={classnames(style.valueCopyButton)}>
          <CopyIcon className={style.copyIcon} /> {t('swiftDonation.stages.2.copyButtonTitle')}
        </Button>
      </div>
      {correspondentBanksBlockJsx}
    </div>
  );
};

Stage2.propTypes = {
  currency: PropTypes.shape({
    abbr: PropTypes.string.isRequired,
    name: PropTypes.shape({
      i18nKey: PropTypes.string.isRequired
    }).isRequired,
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
      iban: PropTypes.string.isRequired,
      bank: PropTypes.string.isRequired,
      bankSwiftCode: PropTypes.string,
      recipientCode: PropTypes.string,
      address: PropTypes.string.isRequired
    }).isRequired,
    correspondentBanks: PropTypes.arrayOf(
      PropTypes.shape({
        account: PropTypes.string.isRequired,
        swiftCode: PropTypes.string.isRequired,
        bank: PropTypes.string.isRequired,
        purposeOfPayment: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  onBackButtonClick: PropTypes.func
};

export default Stage2;
