import {useLayoutEffect, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useElementSize = target => {
  const [size, setSize] = useState({width: 0, height: 0});

  useLayoutEffect(() => {
    if (target.current) {
      const {width, height} = target.current.getBoundingClientRect();
      setSize({width, height});
    }
  }, [target]);

  useResizeObserver(target, entry => {
    const {inlineSize: width, blockSize: height} = entry.contentBoxSize[0];
    setSize({width, height});
  });

  return [size];
};

export default useElementSize;
