import PropTypes from 'prop-types';
import style from './Navigation.module.scss';
import options from './options';
import {useTranslation} from 'react-i18next';
import NavLink from '../../../../NavLink';
import classnames from 'classnames';
import {animated, config, useTrail} from '@react-spring/web';

const Navigation = ({isOpen, onItemClick, options}) => {
  const {t} = useTranslation();
  const trail = useTrail(options.length, {
    from: {
      opacity: 0,
      x: 20,
      maskScreenTop: '0%'
    },
    to: {
      opacity: isOpen ? 1 : 0,
      x: isOpen ? 0 : 20,
      maskScreenTop: isOpen ? '100%' : '0%'
    },
    config: config.default
  });

  const linksJsx = trail.map(({maskScreenTop, ...itemWrapperSpringStyle}, index) => {
    const option = options[index];
    return (
      <animated.div key={`menu-nav-${option.link}}`} style={itemWrapperSpringStyle} className={style.itemWrapper}>
        <NavLink to={option.link} className={classnames('with-animated-underline', style.item)} onClick={onItemClick}>
          {t(option.i18nKey)}
        </NavLink>
        <animated.div style={{top: maskScreenTop}} className={style.maskScreen} />
      </animated.div>
    );
  });
  return <nav className={style.container}>{linksJsx}</nav>;
};

Navigation.propTypes = {
  isOpen: PropTypes.bool,
  onItemClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      i18nKey: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  )
};

Navigation.defaultProps = {
  options: options
};

export default Navigation;
