import style from './SubscriptionForm.module.scss';
import Button from '../../Button';
import {useCallback, useId} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Link from '../../Link';
import axios from '../../../services/axios';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import ValidationSchema from './ValidationSchema';
import AnimatedMessage from '../../AnimatedMessage';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import classnames from 'classnames';

const SubscriptionForm = () => {
  const inputId = useId();
  const {t, i18n} = useTranslation();
  const {executeRecaptcha} = useGoogleReCaptcha();

  const subscribe = useCallback(
    async (value, actions) => {
      let captchaToken;

      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha(process.env.REACT_APP_RE_CAPTCHA_SUBSCRIPTION_ACTION);
      }

      try {
        const lang = i18n.language.replace('en-US', 'en').replace('uk-UA', 'ua');
        await axios.post('/subscribe', {...value, lang, captcha_token: captchaToken});
        actions.resetForm({email: ''});
        actions.setStatus({success: 'emailSubscription.result.success'});

        setTimeout(() => {
          actions.resetForm({email: ''});
        }, 10000);
      } catch (e) {
        const responseData = e?.response?.data;
        if (responseData?.i18nKey) {
          actions.setFieldError('email', responseData.i18nKey);
        } else {
          actions.setFieldError('email', 'emailSubscription.result.wrongEmail');
        }
      }
    },
    [i18n.language, executeRecaptcha]
  );

  return (
    <div className={style.container}>
      <label htmlFor={inputId} className={style.title}>
        {t('emailSubscription.input.label')}
      </label>
      <Formik initialValues={{email: ''}} onSubmit={subscribe} validationSchema={ValidationSchema}>
        {({status, isSubmitting}) => (
          <Form className={style.controls}>
            <Field
              id={inputId}
              name="email"
              type="email"
              placeholder={t('emailSubscription.input.placeholder')}
              className={style.input}
            />
            <ErrorMessage name="email">
              {i18nKey => (
                <AnimatedMessage className={style.notificationMessage} type="error">
                  {t(i18nKey)}
                </AnimatedMessage>
              )}
            </ErrorMessage>
            {status?.success && (
              <AnimatedMessage className={style.notificationMessage} type="success">
                {t(status.success)}
              </AnimatedMessage>
            )}
            <span className={style.termsNotice}>
              <Trans i18nKey="emailSubscription.termsNotice">
                By clicking the &ldquo;Subscribe&ldquo; button, you accept the{' '}
                <Link to="docs/terms" className={classnames(style.termsLink, 'with-animated-underline')}>
                  terms
                </Link>
                . This site is protected by reCAPTCHA and the Google{' '}
                <a
                  href="https://policies.google.com/privacy"
                  className="with-animated-underline green"
                  target="_blank"
                  rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://policies.google.com/terms"
                  className="with-animated-underline green"
                  target="_blank"
                  rel="noreferrer">
                  Terms of Service
                </a>{' '}
                apply.
              </Trans>
            </span>
            <div className={style.buttonWrapper}>
              <Button className={style.button} type="submit" disabled={isSubmitting}>
                {t('emailSubscription.button')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubscriptionForm;
