import style from './MenuPanel.module.scss';
import Navigation from './Navigation';
import DataWithLabel from '../../../DataWithLaber';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {animated, config, useChain, useSpring, useSpringRef, useTransition} from '@react-spring/web';
import classnames from 'classnames';
import useMatchMedia from 'use-match-media-hook';
import useSWR from '../../../../hooks/swr/useSWR';
import PlaceholderTextLine from '../../../PlaceholderTextLine';

const MenuPanel = ({isOpen, onItemClick}) => {
  const {t} = useTranslation();
  const [isDesktop2Size, isDesktop1Size, isTabletSize] = useMatchMedia([
    '(min-width: 1920px)',
    '(min-width: 1280px)',
    '(min-width: 768px)'
  ]);
  const paddingLeft = isDesktop2Size ? '200px' : isDesktop1Size ? '120px' : isTabletSize ? '80px' : '40px';
  const paddingRight = isTabletSize ? paddingLeft : '20px';
  const containerTransitionApi = useSpringRef();
  const containerTransition = useTransition(isOpen, {
    from: {
      paddingLeft: '0px',
      paddingRight: '0px',
      width: '0vw'
    },
    enter: {
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      width: '100vw'
    },
    leave: {
      paddingLeft: '0px',
      paddingRight: '0px',
      width: '0vw'
    },
    reverse: isOpen,
    config: config.slow,
    ref: containerTransitionApi
  });
  const contentSpringApi = useSpringRef();
  const block2SpringStyle = useSpring({
    to: {
      opacity: isOpen ? 1 : 0
    },
    config: config.molasses,
    ref: contentSpringApi
  });
  useChain(isOpen ? [containerTransitionApi, contentSpringApi] : [contentSpringApi, containerTransitionApi], [
    0,
    isOpen ? 0.5 : 0.3
  ]);

  const {
    data: contactUsData,
    isLoading,
    isError
  } = useSWR([
    'contact-us',
    {params: {populate: ['socialMedias', 'socialMedias.logo', 'locations', 'locations.address', 'emails']}}
  ]);

  let contactInfoObjWithJsx;

  if (isLoading || isError) {
    contactInfoObjWithJsx = {
      phoneNumber: <PlaceholderTextLine />,
      email: <PlaceholderTextLine />,
      ourOffice: <PlaceholderTextLine />
    };
  } else {
    contactInfoObjWithJsx = {
      phoneNumber: (
        <a href={`tel:${contactUsData?.data?.attributes?.phoneNumber}`} className="with-animated-underline">
          {contactUsData.data.attributes.phoneNumber}
        </a>
      ),
      emails: contactUsData?.data?.attributes?.emails?.map(email => (
        <div key={`contact-info-footer-email-${email.address}`}>
          <a href={`mailto:${email.address}`} className="with-animated-underline">
            {email.address}
          </a>
        </div>
      )),
      ourOffice: contactUsData?.data?.attributes?.locations?.map(location => {
        const addresses = location.address.map(address => {
          const addressLine2 = address.address_line_2 ? <div>{address.address_line_2}</div> : null;
          const addressLine3 = address.address_line_3 ? <div>{address.address_line_3}</div> : null;

          return (
            <div key={`contact-info-${location.id}-${address.id}`} className={style.address}>
              <div>{address.name}</div>
              <div>{address.address_line_1}</div>
              {addressLine2}
              {addressLine3}
            </div>
          );
        });

        return (
          <div key={`office-location-${location.id}`} className={style.location}>
            <div>{location.name.toUpperCase()}</div>
            {addresses}
          </div>
        );
      })
    };
  }

  const socialMediasJsx = contactUsData?.data?.attributes?.socialMedias?.map(socialMedia => (
    <a
      href={socialMedia.link}
      target="_blank"
      rel="noreferrer"
      className="with-animated-underline"
      onClick={onItemClick}
      key={`social-media-${socialMedia.id}-header`}>
      <img
        src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${socialMedia.logo.data.attributes.url}`}
        alt={socialMedia.name}
        width={24}
        height={24}
      />
    </a>
  ));

  return containerTransition(
    (styles, item) =>
      item && (
        <animated.div className={style.container} style={styles}>
          <div className={style.wrapper}>
            <div className={style.centerWrapper}>
              <div className={style.block1}>
                <Navigation onItemClick={onItemClick} isOpen={isOpen} />
              </div>
              <animated.div className={style.block2} style={block2SpringStyle}>
                <DataWithLabel
                  label={t('contactInfo.phoneNumber')}
                  className={classnames(style.dataItem, style.phonenumber)}
                  dataProps={{className: style.text}}
                  titleProps={{removePadding: true, className: style.text}}>
                  {contactInfoObjWithJsx.phoneNumber}
                </DataWithLabel>
                <DataWithLabel
                  label={t('contactInfo.email')}
                  dataProps={{removePadding: true, className: style.text}}
                  titleProps={{removePadding: true, className: style.text}}
                  className={classnames(style.dataItem, style.email)}>
                  {contactInfoObjWithJsx.emails}
                </DataWithLabel>
                <DataWithLabel
                  label={t('contactInfo.address')}
                  className={classnames(style.dataItem, style.address)}
                  dataProps={{className: style.text}}
                  titleProps={{removePadding: true, className: style.text}}>
                  {contactInfoObjWithJsx.ourOffice}
                </DataWithLabel>
                <DataWithLabel
                  label={isTabletSize ? t('contactInfo.socialMedia') : null}
                  dataProps={{className: classnames(style.socialMediaContainer, style.text)}}
                  titleProps={{removePadding: true, className: style.text}}
                  className={classnames(style.dataItem, style.social)}>
                  {socialMediasJsx}
                </DataWithLabel>
              </animated.div>
            </div>
          </div>
        </animated.div>
      )
  );
};

MenuPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func
};

export default MenuPanel;
