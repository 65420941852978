import {lazy, Suspense} from 'react';
import {Outlet, Route} from 'react-router-dom';
import supportedLanguages from '../../../services/i18n/supportedLanguages';
import HomePage from '../../../pages/Home';
import NotFoundPage from '../../../pages/NotFound';
import SlideRoutes from '../../SlideRoutes';
import AboutUsPlaceholder from '../../../pages/AboutUs/Placeholder';
import ForInvestorsPlaceholder from '../../../pages/ForInvestors/Placeholder';
import SolutionPlaceholder from '../../../pages/Solution/Placeholder';
import TeamPlaceholder from '../../../pages/Team/Placeholder';
import TeamMemberPlaceholder from '../../../pages/TeamMember/Placeholder';
import TermsPlaceholder from '../../../pages/Terms/Placeholder';
import CFTermsPlaceholder from '../../../pages/CFTerms/Placeholder';
import DocsPlaceholder from '../../../pages/Docs/Placeholder';
import CFDocsPlaceholder from '../../../pages/CFDocs/Placeholder';
import CFDocsOtherPlaceholder from '../../../pages/CFDocs/CFDocsOther/Placeholder';
import CFBusinessPlanDocsPlaceholder from '../../../pages/CFBusinessPlanDocs/Placeholder';
import CFInvestmentTeaserDocsPlaceholder from '../../../pages/CFInvestmentTeaserDocs/Placeholder';
import CFFinancialPlanDocsPlaceholder from '../../../pages/CFFinancialPlanDocs/Placeholder';
import PartnershipAgreementPlaceholder from '../../../pages/PartnershipAgreement/Placeholder';
import PrivacyPolicyPlaceholder from '../../../pages/PrivacyPolicy/Placeholder';
import CFPrivacyPolicyPlaceholder from '../../../pages/CFPrivacyPolicy/Placeholder';
import AmlKycPolicyPlaceholder from '../../../pages/AmlKycPolicy/Placeholder';
import CFAmlKycPolicyPlaceholder from '../../../pages/CFAmlKycPolicy/Placeholder';
import BlogPlaceholder from '../../../pages/Blog/Placeholder';
import PlatformPlaceholder from '../../../pages/Platform/Placeholder';
import BlogArticlePlaceholder from '../../../pages/BlogArticle/Placeholder';
import PlatformArticlePlaceholder from '../../../pages/PlatformArticle/Placeholder';
import FaqPlaceholder from '../../../pages/Faq/Placeholder';
import ContactUsPlaceholder from '../../../pages/ContactUs/Placeholder';
import DonationsUsePlaceholder from '../../../pages/DonationsUse/Placeholder';
import DonationsUseListPlaceholder from '../../../pages/DonationsUseList/Placeholder';
import DonationsUseReportPlaceholder from '../../../pages/DonationsUseReport/Placeholder';
import BusinessPlanPlaceholder from '../../../pages/BusinessPlan/Placeholder';
import CFDocsOther from '../../../pages/CFDocs/CFDocsOther';

const AboutUs = lazy(() => import('../../../pages/AboutUs'));
const ForInvestors = lazy(() => import('../../../pages/ForInvestors'));
const Solution = lazy(() => import('../../../pages/Solution'));
const Blog = lazy(() => import('../../../pages/Blog'));
const Platform = lazy(() => import('../../../pages/Platform'));
const BlogArticle = lazy(() => import('../../../pages/BlogArticle'));
const PlatformArticle = lazy(() => import('../../../pages/PlatformArticle'));
const Docs = lazy(() => import('../../../pages/Docs'));
const CFDocs = lazy(() => import('../../../pages/CFDocs'));
const CFBusinessPlanDocs = lazy(() => import('../../../pages/CFBusinessPlanDocs'));
const CFInvestmentTeaserDocs = lazy(() => import('../../../pages/CFInvestmentTeaserDocs'));
const CFFinancialPlanDocs = lazy(() => import('../../../pages/CFFinancialPlanDocs'));
const PartnershipAgreement = lazy(() => import('../../../pages/PartnershipAgreement'));
const Team = lazy(() => import('../../../pages/Team'));
const TeamMember = lazy(() => import('../../../pages/TeamMember'));
const Terms = lazy(() => import('../../../pages/Terms'));
const CFTerms = lazy(() => import('../../../pages/CFTerms'));
const Faq = lazy(() => import('../../../pages/Faq'));
const ContactUs = lazy(() => import('../../../pages/ContactUs'));
const PrivacyPolicy = lazy(() => import('../../../pages/PrivacyPolicy'));
const CFPrivacyPolicy = lazy(() => import('../../../pages/CFPrivacyPolicy'));
const AmlKycPolicy = lazy(() => import('../../../pages/AmlKycPolicy'));
const CFAmlKycPolicy = lazy(() => import('../../../pages/CFAmlKycPolicy'));
const DonationsUse = lazy(() => import('../../../pages/DonationsUse'));
const DonationsUseList = lazy(() => import('../../../pages/DonationsUseList'));
const DonationsUseReport = lazy(() => import('../../../pages/DonationsUseReport'));
const BusinessPlan = lazy(() => import('../../../pages/BusinessPlan'));

const Main = () => {
  const langPathPrefixes = [...supportedLanguages.map(l => l.iso639_1 || l.iso3166_2), ''];

  const langRoutes = langPathPrefixes.map(langPrefix => (
    <Route path={`/${langPrefix}`} element={<Outlet />} key={`react-router-${langPrefix}`}>
      <Route index element={<HomePage />} />
      <Route
        path="about-us"
        element={
          <Suspense fallback={<AboutUsPlaceholder />}>
            <AboutUs />
          </Suspense>
        }
      />
      <Route
        path="team"
        element={
          <Suspense fallback={<TeamPlaceholder />}>
            <Team />
          </Suspense>
        }
      />
      <Route
        path="team/members/:memberId"
        element={
          <Suspense fallback={<TeamMemberPlaceholder />}>
            <TeamMember />
          </Suspense>
        }
      />
      <Route
        path="for-investors"
        element={
          <Suspense fallback={<ForInvestorsPlaceholder />}>
            <ForInvestors />
          </Suspense>
        }
      />
      <Route
        path="solution"
        element={
          <Suspense fallback={<SolutionPlaceholder />}>
            <Solution />
          </Suspense>
        }
      />
      <Route
        path="blog"
        element={
          <Suspense fallback={<BlogPlaceholder />}>
            <Blog />
          </Suspense>
        }
      />
      <Route
        path="blog/article/:articleId"
        element={
          <Suspense fallback={<BlogArticlePlaceholder />}>
            <BlogArticle />
          </Suspense>
        }
      />
      <Route
        path="platform"
        element={
          <Suspense fallback={<PlatformPlaceholder />}>
            <Platform />
          </Suspense>
        }
      />
      <Route
        path="platform/article/:articleId"
        element={
          <Suspense fallback={<PlatformArticlePlaceholder />}>
            <PlatformArticle />
          </Suspense>
        }
      />
      <Route
        path="faq"
        element={
          <Suspense fallback={<FaqPlaceholder />}>
            <Faq />
          </Suspense>
        }
      />
      <Route
        path="contact-us"
        element={
          <Suspense fallback={<ContactUsPlaceholder />}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        path="docs/terms"
        element={
          <Suspense fallback={<TermsPlaceholder />}>
            <Terms />
          </Suspense>
        }
      />
      <Route
        path="docs/privacy-policy"
        element={
          <Suspense fallback={<PrivacyPolicyPlaceholder />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path="docs/aml-kyc-policy"
        element={
          <Suspense fallback={<AmlKycPolicyPlaceholder />}>
            <AmlKycPolicy />
          </Suspense>
        }
      />
      <Route
        path="docs/partnership-agreements"
        element={
          <Suspense fallback={<PartnershipAgreementPlaceholder />}>
            <PartnershipAgreement />
          </Suspense>
        }
      />
      <Route
        path="docs"
        element={
          <Suspense fallback={<DocsPlaceholder />}>
            <Docs />
          </Suspense>
        }
      />
      <Route
        path="cf-docs/privacy-policy"
        element={
          <Suspense fallback={<CFPrivacyPolicyPlaceholder />}>
            <CFPrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path="cf-docs/aml-kyc-policy"
        element={
          <Suspense fallback={<CFAmlKycPolicyPlaceholder />}>
            <CFAmlKycPolicy />
          </Suspense>
        }
      />
      <Route
        path="cf-docs/terms"
        element={
          <Suspense fallback={<CFTermsPlaceholder />}>
            <CFTerms />
          </Suspense>
        }
      />
      <Route
        path="cf-docs/other"
        element={
          <Suspense fallback={<CFDocsOtherPlaceholder />}>
            <CFDocsOther />
          </Suspense>
        }
      />
      <Route
        path="cf-docs"
        element={
          <Suspense fallback={<CFDocsPlaceholder />}>
            <CFDocs />
          </Suspense>
        }
      />
      <Route
        path="cf-business-plan"
        element={
          <Suspense fallback={<CFBusinessPlanDocsPlaceholder />}>
            <CFBusinessPlanDocs />
          </Suspense>
        }
      />
      <Route
        path="cf-investment-teaser"
        element={
          <Suspense fallback={<CFInvestmentTeaserDocsPlaceholder />}>
            <CFInvestmentTeaserDocs />
          </Suspense>
        }
      />
      <Route
        path="cf-financial-plan"
        element={
          <Suspense fallback={<CFFinancialPlanDocsPlaceholder />}>
            <CFFinancialPlanDocs />
          </Suspense>
        }
      />
      <Route
        path="donations-use"
        element={
          <Suspense fallback={<DonationsUsePlaceholder />}>
            <DonationsUse />
          </Suspense>
        }
      />
      <Route
        path="donations-use/charity-foundation"
        element={
          <Suspense fallback={<DonationsUseListPlaceholder />}>
            <DonationsUseList
              i18nNamespace="pages/donations-use-cf"
              apiEndpoint="cf-donations-uses"
              singleEntityPagePattern="donations-use/llc/:id"
            />
          </Suspense>
        }
      />
      <Route
        path="donations-use/llc"
        element={
          <Suspense fallback={<DonationsUseListPlaceholder />}>
            <DonationsUseList
              i18nNamespace="pages/donations-use-llc"
              apiEndpoint="llc-donations-uses"
              singleEntityPagePattern="donations-use/llc/:id"
            />
          </Suspense>
        }
      />
      <Route
        path="donations-use/llc/:reportId"
        element={
          <Suspense fallback={<DonationsUseReportPlaceholder />}>
            <DonationsUseReport apiEndpointPrefix="llc-donations-uses" />
          </Suspense>
        }
      />
      <Route
        path="donations-use/charity-foundation/:reportId"
        element={
          <Suspense fallback={<DonationsUseReportPlaceholder />}>
            <DonationsUseReport apiEndpointPrefix="cf-donations-uses" />
          </Suspense>
        }
      />
      <Route
        path="business-plan"
        element={
          <Suspense fallback={<BusinessPlanPlaceholder />}>
            <BusinessPlan />
          </Suspense>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  ));

  return (
    <main>
      <SlideRoutes>{langRoutes}</SlideRoutes>
    </main>
  );
};

Main.propTypes = {};

export default Main;
