import PropTypes from 'prop-types';
import {NavLink as BaseNavLink, useLocation} from 'react-router-dom';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import useNewPathWithLangPrefix from '../hooks/useNewPathWithLangPrefix';

const NavLink = ({children, to, onClick, className: classNameProp}) => {
  const {i18n} = useTranslation();
  const currentLocation = useLocation();
  const state = {
    previousLocation: currentLocation
  };

  const newTo = useNewPathWithLangPrefix(to);
  const className = classnames(classNameProp, {
    active: currentLocation.pathname.replace(new RegExp(`(/${i18n.resolvedLanguage})?/?`), '') === to
  });

  return (
    <BaseNavLink to={newTo} className={() => className} onClick={onClick} state={state}>
      {children}
    </BaseNavLink>
  );
};

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func
};

NavLink.defaultProps = {};

export default NavLink;
