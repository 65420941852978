const options = [
  {
    i18nKey: 'footerNavigation.aboutUs',
    link: 'about-us'
  },
  {
    i18nKey: 'footerNavigation.investors',
    link: 'for-investors'
  },
  {
    i18nKey: 'footerNavigation.faq',
    link: 'faq'
  },
  {
    i18nKey: 'footerNavigation.blog',
    link: 'blog'
  },
  {
    i18nKey: 'footerNavigation.cfDocs',
    link: 'cf-docs'
  },
  {
    i18nKey: 'footerNavigation.docs',
    link: 'docs'
  },
  {
    i18nKey: 'footerNavigation.cfBusinessPlan',
    link: 'cf-business-plan'
  },
  {
    i18nKey: 'footerNavigation.cfInvestmentTeaser',
    link: 'cf-investment-teaser'
  },
  {
    i18nKey: 'footerNavigation.cfFinancialPlan',
    link: 'cf-financial-plan'
  },
  {
    i18nKey: 'footerNavigation.donationsUse',
    link: 'donations-use'
  },
  {
    i18nKey: 'footerNavigation.minSocPolicy',
    linkI18nKey: 'footerNavigation.links.minSocPolicy',
    external: true
  },
  {
    i18nKey: 'footerNavigation.whitePaper',
    linkI18nKey: 'footerNavigation.links.whitePaper',
    external: true
  },
  {
    i18nKey: 'footerNavigation.contactUs',
    link: 'contact-us'
  }
];

export default options;
