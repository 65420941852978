import style from './Roadmap.module.scss';
import roadmapDateViewStyle from '../../../../components/RoadmapDateView/RoadmapDateView.module.scss';
import roadmapDateViewPlaceholderStyle from '../../../../components/RoadmapDateView/Placeholder/Placeholder.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef, useMemo} from 'react';
import ContentWrapper from '../../../../components/ContentWrapper';
import {ReactComponent as ArrowIcon} from './arrow.svg';
import {useWindowWidth} from '@react-hook/window-size';
import {Swiper, SwiperSlide} from 'swiper/react';
import RoadmapDateView from '../../../../components/RoadmapDateView';
import RoadmapDateViewPlaceholder from '../../../../components/RoadmapDateView/Placeholder';
import classnames from 'classnames';
import {Mousewheel} from 'swiper';
import useSWR from '../../../../hooks/swr/useSWR';

const Roadmap = forwardRef((props, ref) => {
  const {t} = useTranslation('pages/home');
  const windowWidth = useWindowWidth();
  const {data: roadmapData, isLoading, isError} = useSWR(['roadmap', {params: {populate: 'points'}}]);
  let slidesJsx;

  if (isLoading || isError) {
    slidesJsx = [
      <SwiperSlide
        key="roadmap-slide-placeholder-1"
        className={classnames(
          roadmapDateViewPlaceholderStyle.slide,
          roadmapDateViewStyle.firstSwiperSlide,
          roadmapDateViewPlaceholderStyle.firstSwiperSlide
        )}>
        {({isPrev, isActive, isNext}) => (
          <RoadmapDateViewPlaceholder isActive={isActive} isNext={isNext} isPrev={isPrev} />
        )}
      </SwiperSlide>,
      <SwiperSlide key="roadmap-slide-placeholder-2" className={roadmapDateViewPlaceholderStyle.slide}>
        {({isPrev, isActive, isNext}) => (
          <RoadmapDateViewPlaceholder isActive={isActive} isNext={isNext} isPrev={isPrev} />
        )}
      </SwiperSlide>,
      <SwiperSlide
        key="roadmap-slide-placeholder-3"
        className={classnames(roadmapDateViewPlaceholderStyle.slide, roadmapDateViewPlaceholderStyle.lastSwiperSlide)}>
        {({isPrev, isActive, isNext}) => (
          <RoadmapDateViewPlaceholder isActive={isActive} isNext={isNext} isPrev={isPrev} />
        )}
      </SwiperSlide>
    ];
  } else {
    slidesJsx = roadmapData?.data?.attributes?.points?.map((point, i, points) => (
      <SwiperSlide
        key={`roadmap-slide-${i}`}
        className={classnames(roadmapDateViewStyle.slide, {
          [roadmapDateViewStyle.firstSwiperSlide]: i === 0,
          [roadmapDateViewStyle.lastSlide]: i === points.length - 1
        })}>
        {({isPrev, isActive, isNext}) => (
          <RoadmapDateView
            index={i}
            year={point.year}
            description={point.description}
            isActive={isActive}
            isNext={isNext}
            isPrev={isPrev}
          />
        )}
      </SwiperSlide>
    ));

    for (let i = 0; i < 2; i++) {
      slidesJsx?.push(
        <SwiperSlide key={`roadmap-fake-slide-${i}`} className={classnames(roadmapDateViewStyle.slide)}>
          <RoadmapDateView hidePoint />
        </SwiperSlide>
      );
    }
  }

  const slidesOffsetBefore = useMemo(() => {
    if (windowWidth >= 1920) {
      const contentWidth = 1920 - 200 * 2;
      return contentWidth / 4 + (windowWidth - contentWidth) / 2;
    } else if (windowWidth >= 1280) {
      return (windowWidth - 120 * 2) / 4 + 120;
    } else if (windowWidth >= 768) {
      return (windowWidth - 80 * 2) / 4 + 80;
    } else {
      return 20;
    }
  }, [windowWidth]);

  return (
    <section className={style.container} ref={ref}>
      <ContentWrapper>
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{t('roadmap.sectionTitle')}</h1>
          <ArrowIcon className={style.arrow} />
        </div>
      </ContentWrapper>
      <Swiper
        autoHeight
        roundLengths
        setWrapperSize
        slidesPerView={3}
        slidesOffsetBefore={slidesOffsetBefore}
        modules={[Mousewheel]}
        breakpoints={{
          1280: {
            slidesPerView: 4
          },
          1920: {
            slidesPerView: 4
          }
        }}
        mousewheel={{
          releaseOnEdges: true
        }}
        freeMode={{
          enabled: true
        }}
        className={style.swiper}>
        {slidesJsx}
      </Swiper>
    </section>
  );
});

Roadmap.displayName = 'Roadmap';
Roadmap.hashRoute = '#roadmap';

export default Roadmap;
