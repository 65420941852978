import useNewPathWithLangPrefix from '../../hooks/useNewPathWithLangPrefix';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';

const NotFound = ({to: toProp}) => {
  const to = useNewPathWithLangPrefix('');
  return <Navigate to={toProp || to} />;
};

NotFound.propTypes = {
  to: PropTypes.string
};

export default NotFound;
