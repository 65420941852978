import _useSWR from 'swr/immutable';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';

const useSWR = propKey => {
  const {i18n} = useTranslation();
  const [key, setKey] = useState(propKey);

  useEffect(() => {
    setKey(propKey);
  }, [JSON.stringify(propKey)]);

  const {data, error} = _useSWR(key);

  const result = {
    data,
    isLoading: !error && !data,
    isError: !!error,
    error
  };

  useEffect(() => {
    if (!result.isLoading && !result.isError) {
      if (!Array.isArray(result.data.data)) {
        const entity = result.data.data;
        if (entity) {
          const apiLocaleNeeded = i18n.language.replace('ua', 'uk');
          if (entity.attributes.locale !== apiLocaleNeeded) {
            console.debug(
              `Found entity with wrong locale (${entity.attributes.locale}). Trying to fetch with the right locale (${apiLocaleNeeded})`
            );
            console.debug('SWR Key:', key, 'Data', entity);
            if (entity.attributes.localizations) {
              const localizedEntity = entity.attributes.localizations.data.find(
                e => e.attributes.locale === apiLocaleNeeded
              );
              if (localizedEntity) {
                const entityId = localizedEntity.id;
                const keyIsArray = Array.isArray(key);
                const currentUrl = keyIsArray ? key[0] : key;
                const fixedUrl = `${currentUrl.substring(0, currentUrl.lastIndexOf('/'))}/${entityId}`;
                if (!key || (keyIsArray ? key[0] : key) !== fixedUrl) {
                  const fixedKey = keyIsArray ? [fixedUrl, ...key.slice(1)] : fixedUrl;
                  console.debug('Fixed SWR key:', fixedKey);
                  setKey(fixedKey);
                }
              } else {
                console.debug(`The entity with the desired localization (${apiLocaleNeeded}) was not found`);
              }
            } else {
              console.debug('The `localizations` property was not found in the API response');
            }
          }
        }
      }
    }
  }, [JSON.stringify(key), result.isLoading, result.isError, result.data, i18n.language]);

  return result;
};

export default useSWR;
