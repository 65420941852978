import PropTypes from 'prop-types';
import style from './SearchForm.module.scss';
import {useCallback, useEffect, useId, useMemo, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SearchItem from '../SearchItem';
import ContentWrapper from '../ContentWrapper';
import {Autoplay, Mousewheel} from 'swiper';
import useMatchMedia from 'use-match-media-hook';
import {Waypoint} from 'react-waypoint';

const SearchForm = ({currencyItems = [], searchInput, onItemResolved}) => {
  const searchInputId = useId();

  const [filter, setFilter] = useState('');
  const swiperRef = useRef();
  const [isFormInViewport, setIsFormInViewport] = useState(false);

  const [isTabletSize] = useMatchMedia(['(min-width: 768px)']);

  const handleOnChangeFilter = useCallback(
    e => {
      setFilter(e.target.value);
    },
    [setFilter]
  );

  const filteredSearchItems = useMemo(
    () =>
      currencyItems.filter(currencyItem => {
        if (!filter) {
          return true;
        } else {
          const lcAbbr = currencyItem.abbr.toLowerCase();
          const lcName = currencyItem.name?.toLowerCase();
          return [lcAbbr, lcName].filter(item => !!item).some(item => item?.includes(filter.toLowerCase()));
        }
      }),
    [currencyItems, filter]
  );

  useEffect(() => {
    swiperRef.current.swiper.setProgress(0, 10);
  }, [filter, swiperRef.current]);

  useEffect(() => {
    if (isFormInViewport) {
      swiperRef.current.swiper.autoplay.start();
    } else {
      swiperRef.current.swiper.autoplay.stop();
    }
  }, [swiperRef.current, isFormInViewport]);

  const isLoopAllowed =
    (filteredSearchItems.length >= 5 && !isTabletSize) || (filteredSearchItems.length >= 6 && isTabletSize);

  const onSearchItemClickHandler = useCallback(abbr => onItemResolved(abbr), [onItemResolved]);

  const slidesJsx = filteredSearchItems.map((currencyItem, i) => (
    <SwiperSlide key={`swift-swiper-slide-${i}`} className={style.slide}>
      <SearchItem
        name={currencyItem.abbr}
        icon={currencyItem.icon}
        iconType={currencyItem.iconType}
        onClick={() => onSearchItemClickHandler(currencyItem.abbr)}
      />
    </SwiperSlide>
  ));

  const onViewpointEnter = useCallback(() => {
    setIsFormInViewport(true);
  }, []);

  const onViewpointLeave = useCallback(() => {
    setIsFormInViewport(false);
  }, []);

  return (
    <Waypoint onEnter={onViewpointEnter} onLeave={onViewpointLeave} scrollableAncestor={window}>
      <div className={style.container}>
        <ContentWrapper className={style.contentWrapper}>
          <div className={style.field}>
            <label htmlFor={searchInputId}>{searchInput.label}</label>
            <input
              id={searchInputId}
              type="text"
              placeholder={searchInput.placeholder}
              value={filter}
              onChange={handleOnChangeFilter}
              className={style.inputSearch}
            />
          </div>
        </ContentWrapper>
        <div className={style.swiperWrapper}>
          <Swiper
            ref={swiperRef}
            slidesPerView={5}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            speed={15000}
            modules={[Autoplay, Mousewheel]}
            breakpoints={{
              768: {
                slidesPerView: 6
              }
            }}
            mousewheel={{
              releaseOnEdges: true
            }}
            freeMode={{
              enabled: true
            }}
            loop={isLoopAllowed}
            loopFillGroupWithBlank>
            {slidesJsx}
          </Swiper>
        </div>
      </div>
    </Waypoint>
  );
};

SearchForm.propTypes = {
  currencyItems: PropTypes.arrayOf(
    PropTypes.shape({
      abbr: PropTypes.string.isRequired,
      name: PropTypes.string,
      icon: PropTypes.string.isRequired,
      iconType: PropTypes.oneOf(['image', 'text'])
    })
  ),
  searchInput: PropTypes.shape({
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
  }).isRequired,
  onItemResolved: PropTypes.func.isRequired
};

export default SearchForm;
