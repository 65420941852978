import {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import PreviewSection from '../../../../components/PreviewSection';
import PreviewSectionPlaceholder from '../../../../components/PreviewSection/Placeholder';
import useSWR from '../../../../hooks/swr/useSWR';

const ForInvestors = forwardRef((_, ref) => {
  const {t} = useTranslation('pages/home');
  const {
    data: forInvestorPage,
    isLoading,
    isError
  } = useSWR(['for-investor', {params: {populate: ['article', 'article.cover']}}]);

  return isLoading || isError ? (
    <PreviewSectionPlaceholder reverseLayoutDirection />
  ) : (
    <PreviewSection
      title={forInvestorPage?.data?.attributes?.article?.title}
      text={forInvestorPage?.data?.attributes?.article?.previewText}
      button={{title: t('forInvestors.readMoreButton'), to: 'for-investors'}}
      imageSrc={forInvestorPage?.data?.attributes?.article?.cover?.data?.attributes?.url}
      ref={ref}
      reverseLayoutDirection
    />
  );
});

ForInvestors.displayName = 'ForInvestors';
ForInvestors.hashRoute = '#for-investors';

export default ForInvestors;
