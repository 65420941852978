import style from '../FundsCounter.module.scss';
import ContentWrapper from '../../../../../components/ContentWrapper';
import {useTranslation} from 'react-i18next';
import {forwardRef} from 'react';
import PlaceholderTextLine from '../../../../../components/PlaceholderTextLine';

const Placeholder = forwardRef((prop, ref) => {
  const {t} = useTranslation('pages/home');

  return (
    <ContentWrapper>
      <section className={style.container} ref={ref}>
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{t('fundsCounter.sectionTitle')}</h1>
        </div>
        <div className={style.donationsAmount}>
          <div className={style.counterTitle}>{t('fundsCounter.donationsAmount.title')}</div>
          <div className={style.counter}>
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
            <PlaceholderTextLine />
          </div>
        </div>
        <div className={style.goal}>
          <div className={style.commonTitleValue}>
            <div className={style.commonTitle}>{t('fundsCounter.goal.title')}</div>
            <div className={style.commonValue}>
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
            </div>
          </div>
          <div className={style.commonTitleValue}>
            <div className={style.commonTitle}>{t('fundsCounter.remainsToTheGoal.title')}</div>
            <div className={style.commonValue}>
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
            </div>
          </div>
        </div>
        <div className={style.other}>
          <div className={style.commonTitleValue}>
            <div className={style.commonTitle}>{t('fundsCounter.donationsNumber.title')}</div>
            <div className={style.commonValue}>
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
            </div>
          </div>
          <div className={style.commonTitleValue}>
            <div className={style.commonTitle}>{t('fundsCounter.averageDonation.title')}</div>
            <div className={style.commonValue}>
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
              <PlaceholderTextLine />
            </div>
          </div>
        </div>
      </section>
    </ContentWrapper>
  );
});

Placeholder.displayName = 'Placeholder';

export default Placeholder;
