import PropTypes from 'prop-types';
import {Link as BaseLink, useLocation} from 'react-router-dom';
import useNewPathWithLangPrefix from '../hooks/useNewPathWithLangPrefix';

const Link = ({children, to, onClick, className}) => {
  const newTo = useNewPathWithLangPrefix(to);
  const currentLocation = useLocation();
  const state = {
    previousLocation: currentLocation
  };

  return (
    <BaseLink to={newTo} className={className} onClick={onClick} state={state}>
      {children}
    </BaseLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func
};

Link.defaultProps = {};

export default Link;
