import {createRef} from 'react';
import SCROLL_DIRECTION from './constants';

const state = {
  top: createRef(),
  direction: createRef(),
  listeners: new Set(),
  prevDirectionPosition: 0,
  setScrollTop: value => {
    state.calculateDirection(value);
    state.top.current = value;
    state.listeners.forEach(listener => listener(value));
  },
  getScrollTop: () => state.top.current || 0,
  calculateDirection: value => {
    if (!state.top.current) {
      state.direction.current = SCROLL_DIRECTION.INIT;
    } else {
      const offset = state.prevDirectionPosition - value;
      if (state.top.current > value) {
        if (state.direction.current !== SCROLL_DIRECTION.UP) {
          state.prevDirectionPosition = state.top.current;
        }
        state.direction.current = SCROLL_DIRECTION.UP;
      } else {
        if (state.direction.current !== SCROLL_DIRECTION.DOWN && Math.abs(offset) > 100) {
          state.prevDirectionPosition = state.top.current;
        }
        if (offset < -100) {
          state.direction.current = SCROLL_DIRECTION.DOWN;
        }
      }
    }
  },
  getDirection: () => state.direction.current,
  subscribe: callback => {
    state.listeners.add(callback);
    return () => state.listeners.delete(callback);
  }
};

export default state;
