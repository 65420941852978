import style from './Placeholder.module.scss';

const Placeholder = () => {
  return (
    <div className={style.container}>
      <div className={style.block1}>
        <div className={style.title} />
        <div className={style.subtitle} />
      </div>
      <div className={style.block2}>
        <div className={style.button} />
      </div>
    </div>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
