import PropTypes from 'prop-types';
import Button from '../index';
import {useState} from 'react';

const LoadingButton = ({children, loadingTitle, onClick, ...props}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = async () => {
    if (typeof onClick?.then === 'function') {
      setIsLoading(true);
      await onClick();
      setIsLoading(false);
    } else {
      onClick?.();
    }
  };

  let _childrenJsx = children;

  if (isLoading) {
    _childrenJsx = loadingTitle;
  }

  return (
    <Button onClick={onClickHandler} {...props}>
      {_childrenJsx}
    </Button>
  );
};

LoadingButton.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf(['green', 'red']),
  loadingTitle: PropTypes.string
};

LoadingButton.defaultProps = {
  type: 'button',
  size: 'md',
  color: 'green',
  loadingTitle: 'Loading'
};

export default LoadingButton;
