import {useEffect} from 'react';
import state from '../components/MainContainer/scrollTopStore';

const withScrollListener = WrappedComponent => {
  const HOC = props => {
    useEffect(() => {
      const handleScroll = () => {
        state.setScrollTop(window.scrollY);
      };

      window.addEventListener('scroll', handleScroll, {passive: true});

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };

  const componentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  HOC.displayName = `WithScrollListener(${componentName})`;
  HOC.propTypes = WrappedComponent.propTypes;

  return HOC;
};
export default withScrollListener;
