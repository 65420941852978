import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const LexxWidget = () => {
  const {i18n} = useTranslation();

  useEffect(() => {
    window.HDWidgetConfig = {
      token: process.env.REACT_APP_LEXX_TOKEN,
      lang: i18n.language
    };
  }, [i18n.language]);

  useEffect(() => {
    (function (d, s, u, e, p) {
      // noinspection CommaExpressionJS
      (p = d.getElementsByTagName(s)[0]),
        (e = d.createElement(s)),
        (e.async = 1),
        (e.src = u),
        p.parentNode.insertBefore(e, p);
    })(document, 'script', 'https://cdn.lexx.me/prod/widget2/init.js');
  }, []);

  return null;
};

LexxWidget.propTypes = {};

export default LexxWidget;
