import FunctionalPage from '../../Functional';
import {useTranslation} from 'react-i18next';
import useScrollTo from 'react-spring-scroll-to-hook';
import {config} from '@react-spring/web';
import {useEffect} from 'react';
import classnames from 'classnames';
import usePageTitle from '../../../hooks/usePageTitle';

const CFDocsOther = () => {
  const {t} = useTranslation('pages/cf-docs-other');

  usePageTitle(t('title'));

  const {scrollTo} = useScrollTo(config.molasses);
  useEffect(() => scrollTo(), []);

  const partnershipAgreementLinksJsx = t('links', {returnObjects: true}).map((link, i) => (
    <li key={`links-${i}`}>
      <a href={link.url} target="_blank" rel="noreferrer" className={classnames('with-animated-underline')}>
        {link.title}
      </a>
    </li>
  ));

  return (
    <FunctionalPage title={t('title')}>
      <ul>{partnershipAgreementLinksJsx}</ul>
    </FunctionalPage>
  );
};

CFDocsOther.propTypes = {};

export default CFDocsOther;
