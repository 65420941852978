import style from './Media.module.scss';
import {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import ContentWrapper from '../../../../components/ContentWrapper';
import LogoExternalLink from '../../../../components/LogoExternalLink';
import shuffle from 'lodash-es/shuffle';
import useSWR from '../../../../hooks/swr/useSWR';
import PlaceholderTextLine from '../../../../components/PlaceholderTextLine';

const Media = forwardRef((_, ref) => {
  const {t} = useTranslation('pages/home');
  const {data: mediaData, isLoading, isError} = useSWR(['media', {params: {populate: ['items', 'items.logo']}}]);

  let mediaItemsJsx;

  if (isLoading || isError) {
    mediaItemsJsx = [
      <PlaceholderTextLine key="partner-placholder-1" />,
      <PlaceholderTextLine key="partner-placholder-2" />,
      <PlaceholderTextLine key="partner-placholder-3" />,
      <PlaceholderTextLine key="partner-placholder-4" />,
      <PlaceholderTextLine key="partner-placholder-5" />,
      <PlaceholderTextLine key="partner-placholder-6" />,
      <PlaceholderTextLine key="partner-placholder-7" />,
      <PlaceholderTextLine key="partner-placholder-8" />
    ];
  } else {
    mediaItemsJsx = shuffle(mediaData.data.attributes.items).map((media, i) => (
      <LogoExternalLink
        key={`partner-${i}-${media.name}`}
        name={media.name}
        img={media.logo?.data?.attributes?.url}
        url={media.url}
      />
    ));
  }

  return (
    <ContentWrapper>
      <section className={style.container} ref={ref}>
        <svg className={style.defsOnly}>
          <filter id="monochrome" colorInterpolationFilters="sRGB" x="0" y="0" height="100%" width="100%">
            <feColorMatrix
              type="matrix"
              values="0.3334 0      0      0 0
                    0      0.8196 0      0 0
                    0      0      0.6471 0 0
                    0      0      0      1 0"
            />
          </filter>
        </svg>
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{t('media.sectionTitle')}</h1>
        </div>
        <div className={style.content}>{mediaItemsJsx}</div>
      </section>
    </ContentWrapper>
  );
});

Media.displayName = 'Media';
Media.hashRoute = '#media';

export default Media;
