import React from 'react';
import PropTypes from 'prop-types';
import {Routes, useLocation} from 'react-router-dom';
import {animated, config, useSpring, useTransition} from '@react-spring/web';
import style from './SlideRoutes.module.scss';
import {useRef, useState} from 'react';
import useElementSize from '../../hooks/useElementSize';
import state from './slideRoutesStore';

const SlideRoutes = ({children}) => {
  const location = useLocation();
  const [animatedRef, setAnimatedRef] = useState(useRef(null));
  const [{height: containerHeight}] = useElementSize(animatedRef);
  const [shouldAnimateImmediately, setShouldAnimateImmediately] = useState(true);

  const containerSpringStyle = useSpring({
    minHeight: `${containerHeight}px`,
    immediate: shouldAnimateImmediately,
    onRest: () => {
      shouldAnimateImmediately && setShouldAnimateImmediately(prevState => !prevState);
    },
    config: config.stiff
  });

  const transition = useTransition(location, {
    from: {
      x: '100%',
      opacity: 0.0
    },
    enter: {
      x: '0%',
      opacity: 1.0
    },
    leave: {
      x: '-100%',
      opacity: 0.0
    },
    key: location.pathname,
    immediate: shouldAnimateImmediately,
    onStart: () => {
      state.incrementStartedItems();
      setAnimatedRef(React.createRef());
    },
    onRest: () => {
      state.decrementStartedItems();
    }
  });

  return (
    <animated.div className={style.container} style={containerSpringStyle}>
      {transition((springStyle, location) => {
        return (
          <animated.div key={location.pathname} style={springStyle} className={style.wrapper} ref={animatedRef}>
            <Routes location={location}>{children}</Routes>
          </animated.div>
        );
      })}
    </animated.div>
  );
};

SlideRoutes.propTypes = {
  children: PropTypes.any.isRequired
};

export default SlideRoutes;
