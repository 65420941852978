const data = {
  sickInAYear: {
    common: 19_292_789,
    men: 10_065_305,
    women: 9_227_484
  },
  diedInAYear: {
    common: 9_958_133
  },
  reasons: {
    breast: 11.7,
    lung: 11.4,
    colorectum: 10,
    prostate: 7.3,
    stomach: 5.6,
    other: 53.9
  }
};

export default data;
