import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import supportedLanguages from './supportedLanguages';
import {DateTime} from 'luxon';

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'ua',
    supportedLngs: supportedLanguages.map(l => l.iso639_1 || l.iso3166_2),
    ns: ['ui', 'pages/home'],
    defaultNS: 'ui',
    load: 'languageOnly',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    detection: {
      order: ['path', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag']
    },
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false
    },
    debug: process.env.NODE_ENV === 'development'
  });

i18next.services.formatter.add('DATE_FULL', (value, lng) => {
  const luxonLocale = lng === 'ua' ? 'uk-UA' : 'en-US';
  return DateTime.fromJSDate(value).setLocale(luxonLocale).toLocaleString(DateTime.DATE_FULL);
});

export default i18next;
