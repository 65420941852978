import originStyle from '../PartnershipAgreement.module.scss';
import PlaceholderTextLine from '../../../components/PlaceholderTextLine';
import FunctionalPagePlaceholder from '../../Functional/Placeholder';

const Placeholder = () => {
  return (
    <FunctionalPagePlaceholder>
      <div className={originStyle.container}>
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
      </div>
    </FunctionalPagePlaceholder>
  );
};

Placeholder.propTypes = {};

export default Placeholder;
