import style from './ContentWrapper.module.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {forwardRef} from 'react';

const ContentWrapper = forwardRef(({className, children, ...otherProps}, ref) => (
  <div className={classnames(style.contentWrapper, className)} ref={ref} {...otherProps}>
    {children}
  </div>
));

ContentWrapper.displayName = 'ContentWrapper';
ContentWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default ContentWrapper;
