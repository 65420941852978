/* eslint-disable */
import style from './ApplePayButton.module.scss';
import classnames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useCountryCode from '../../../hooks/useCountryCode';

const ApplePayButton = ({className, onClick, disabled, totalPrice, ...otherProps}) => {
  const [shouldToShow, setShouldToShow] = useState(false);
  // noinspection JSUnusedLocalSymbols
  const [applePaySession, setApplePaySession] = useState(null);
  const countyCode = useCountryCode();

  useEffect(() => {
    if (shouldToShow && totalPrice && countyCode) {
      const request = {
        countyCode,
        currencyCode: 'USD',
        supportedNetworks: ['visa', 'masterCard'],
        merchantCapabilities: ['supports3DS'],
        total: {label: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_NAME, amount: totalPrice.toString()}
      };

      const session = new window.ApplePaySession(3, request);
      setApplePaySession(session);
    }
  }, [shouldToShow, totalPrice, countyCode]);

  useEffect(() => {
    if (window.ApplePaySession) {
      setShouldToShow(true);
    }
  }, [setShouldToShow]);

  const onKeyDownHandler = useCallback(e => {
    if ([32, 13].includes(e.keyCode)) {
      e.preventDefault();
      onClick();
    }
  }, []);

  return !shouldToShow ? null : (
    <div
      role="button"
      className={classnames(style.container, className)}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDownHandler}
      {...otherProps}
    />
  );
};

ApplePayButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  totalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ApplePayButton.defaultProps = {};

export default ApplePayButton;
