import MainContainer from './components/MainContainer';
import {SWRConfig} from 'swr';
import {useMemo} from 'react';
import fetcher from './hooks/swr/fetcher';
import i18nMiddleware from './hooks/swr/middleware/i18n';
import {useGLTF} from '@react-three/drei';
import {Route, Routes} from 'react-router-dom';
import Unsubscribe from './pages/Unsubscribe';
import SuccessPayment from './pages/payments/SuccessPayment';
import ErrorPayment from './pages/payments/ErrorPayment';
import DonateCancel from './pages/DonateCancel';
import TestForm from './pages/TestForm';

const Application = () => {
  useGLTF(`${process.env.PUBLIC_URL}/stem-cell.gltf`);

  const swrOptions = useMemo(
    () => ({
      fetcher,
      use: [i18nMiddleware]
    }),
    []
  );

  return (
    <SWRConfig value={swrOptions}>
      <Routes>
        <Route path="/*" element={<MainContainer />} />
        <Route path="/ua/unsubscribe" element={<Unsubscribe />} />
        <Route path="/en/unsubscribe" element={<Unsubscribe />} />
        <Route path="/ua/payments/success" element={<SuccessPayment />} />
        <Route path="/en/payments/success" element={<SuccessPayment />} />
        <Route path="/ua/payments/fail" element={<ErrorPayment />} />
        <Route path="/en/payments/fail" element={<ErrorPayment />} />
        <Route path="/ua/donate-subscription-cancel" element={<DonateCancel />} />
        <Route path="/en/donate-subscription-cancel" element={<DonateCancel />} />
        <Route path="/en/test-form" element={<TestForm />} />
      </Routes>
    </SWRConfig>
  );
};

export default Application;
