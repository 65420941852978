import style from './Donate.module.scss';
import {useTranslation} from 'react-i18next';
import {forwardRef} from 'react';
import MonobankDonateForm from '../../../../components/MonobankDonateForm';
import ContentWrapper from '../../../../components/ContentWrapper';
import useSWR from '../../../../hooks/swr/useSWR';
import PlaceholderTextLine from '../../../../components/PlaceholderTextLine';

const MonobankDonate = forwardRef((props, ref) => {
  const {t} = useTranslation('pages/home');
  const {data: supportUsReasonData, isLoading, isError} = useSWR(['support-us-reason']);
  let supportUsReasonJsx;

  if (isLoading || isError) {
    supportUsReasonJsx = (
      <div className={style.supportUsReason}>
        <PlaceholderTextLine />
        <PlaceholderTextLine />
        <PlaceholderTextLine />
      </div>
    );
  } else {
    supportUsReasonJsx = (
      <div className={style.supportUsReason}>
        <span
          dangerouslySetInnerHTML={{
            __html: supportUsReasonData.data.attributes.content
          }}
        />
      </div>
    );
  }

  return (
    <ContentWrapper className={style.contentWrapper}>
      <section className={style.container} ref={ref}>
        {supportUsReasonJsx}

        <div className={style.titleWrapper}>
          <h1 className={style.title}>{t('donate.sectionTitle')}</h1>
        </div>

        <div className={style.content}>
          <MonobankDonateForm />
        </div>
      </section>
    </ContentWrapper>
  );
});

MonobankDonate.displayName = 'MonobankDonate';
MonobankDonate.hashRoute = '#monobank-donate';

export default MonobankDonate;
