import PropTypes from 'prop-types';
import logo from './logo.svg';
import logoText from './logo-text.svg';
import NavLink from '../../NavLink';
import style from './Logo.module.scss';
import classnames from 'classnames';
import useMatchMedia from 'use-match-media-hook';

const Logo = ({altText, route, className, onClick}) => {
  const [isTabletSize] = useMatchMedia(['(min-width: 768px)']);
  const logoSrc = isTabletSize ? logo : logoText;
  return (
    <NavLink to={route} className={classnames(style.logo, className)} onClick={onClick}>
      <img src={logoSrc} alt={altText} className={style.img} width={146} height={12} />
    </NavLink>
  );
};

Logo.propTypes = {
  altText: PropTypes.string,
  route: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

Logo.defaultProps = {
  altText: 'Geoscell Logo',
  route: ''
};

export default Logo;
