import PropTypes from 'prop-types';
import style from './VideoItem.module.scss';
import {ReactComponent as PlayButton} from './playButton.svg';
import {useCallback, useState} from 'react';
import ImagePlaceholder from './placeholder.webp';

const VideoItem = ({title = 'Loading...', vimeoId, previewImageSources = []}) => {
  const [isClickedPlay, setIsClickedPlay] = useState(false);

  const onVideoClick = useCallback(() => setIsClickedPlay(true), []);

  const imageSourcesJsx = previewImageSources.map(source => (
    <source
      key={`${title}-${source.type}`}
      type={source.type}
      srcSet={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${source.src}`}
    />
  ));

  const defaultImageSource =
    previewImageSources.find(source => source.isDefault) || previewImageSources[previewImageSources.length - 1];

  const src = defaultImageSource
    ? `${process.env.REACT_APP_STRAPI_ENTRY_PATH}${defaultImageSource.src}`
    : ImagePlaceholder;

  const defaultImageJsx = (
    <img src={src} alt={title} className={style.videoPlaceholderImage} width={238} height={135} />
  );

  const videoContentJsx = isClickedPlay ? (
    <iframe
      title={title}
      src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&muted=0&controls=1&playsinline=0`}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    />
  ) : (
    <div className={style.videoPlaceholder}>
      <picture className={style.videoPlaceholderImage}>
        {imageSourcesJsx}
        {defaultImageJsx}
      </picture>
      <div
        className={style.videoPlaceholderBackplate}
        onClick={onVideoClick}
        onKeyDown={onVideoClick}
        role="button"
        tabIndex={0}>
        <PlayButton className={style.playButton} />
      </div>
    </div>
  );

  return (
    <div className={style.videoItem}>
      <div className={style.titleWrapper}>
        <h1 className={style.title}>{title}</h1>
      </div>
      <div className={style.arrowBlock} />
      <div className={style.videoBlock}>{videoContentJsx}</div>
    </div>
  );
};

VideoItem.propTypes = {
  vimeoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  previewImageSources: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      isDefault: PropTypes.bool
    }).isRequired
  )
};

export default VideoItem;
