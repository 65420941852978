import axios from '../../services/axios';

const fetcher = (lang, url, axiosConfig = {}) => {
  const locale = lang.replace('ua', 'uk').replace('uk-UA', 'uk').replace('en-US', 'en');
  let populateParam = axiosConfig?.params?.populate;

  if (!populateParam) {
    populateParam = ['localizations'];
  } else if (Array.isArray(populateParam)) {
    populateParam = populateParam.concat(['localizations']);
  } else {
    populateParam = [populateParam, 'localizations'];
  }

  let params = {
    locale,
    populate: populateParam
  };

  if (axiosConfig.params) {
    params = {
      ...axiosConfig.params,
      ...params
    };
  }

  return axios.get(url, {...axiosConfig, params}).then(res => res.data);
};

export default fetcher;
