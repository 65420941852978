/* eslint-disable */
import style from './ArticlePreview.module.scss';
import PropTypes from 'prop-types';
import useNewPathWithLangPrefix from '../../hooks/useNewPathWithLangPrefix';
import {useLocation, useNavigate} from 'react-router-dom';
import {useCallback} from 'react';

const ArticlePreview = ({id, title, previewText, previewImage, previewImageSources, linkTemplate}) => {
  const pictureSourcesJsx = previewImageSources.map(previewImageSource => (
    <source
      type={previewImageSource.type}
      srcSet={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${previewImageSource.src}`}
      key={`${previewImageSource.src}-source`}
    />
  ));

  const toFullArticle = useNewPathWithLangPrefix(linkTemplate.replace(':id', id));
  const currentLocation = useLocation();
  const state = {
    previousLocation: currentLocation
  };
  const navigate = useNavigate();

  const onMoreClickHandler = useCallback(() => {
    navigate(toFullArticle, {state});
  }, [toFullArticle, currentLocation.key]);

  return (
    <div
      className={style.container}
      role="link"
      tabIndex={0}
      onClick={onMoreClickHandler}
      onKeyDown={onMoreClickHandler}>
      <div className={style.imagePreviewWrapper}>
        <picture className={style.img}>
          {pictureSourcesJsx}
          <img
            src={`${process.env.REACT_APP_STRAPI_ENTRY_PATH}${previewImage}`}
            className={style.img}
            alt="Article Preview"
            width={240}
            height={136}
          />
        </picture>
      </div>
      <div className={style.articleHeader}>{title}</div>
      <div className={style.textPreview}>{previewText}</div>
      <div className={style.dotsWrapper}>
        <div className={style.dots}>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

ArticlePreview.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  previewText: PropTypes.string.isRequired,
  previewImage: PropTypes.string.isRequired,
  previewImageSources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string
    })
  ),
  linkTemplate: PropTypes.string
};

ArticlePreview.defaultProps = {
  previewImageSources: [],
  linkTemplate: '/article/:id'
};

export default ArticlePreview;
