import style from './MainContainer.module.scss';
import Header from '../Header';
import Footer from '../Footer';
import CookieConsentModal from '../CookieConsentModal';
import withScrollListener from '../../hocs/withScrollListener';
import withScreenBreakpointName from '../../hocs/withScreenBreakpointName';
import LexxWidget from '../LexxWidget';
import Main from './Main';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import supportedLanguages from '../../services/i18n/supportedLanguages';

const MainContainer = () => {
  const {i18n} = useTranslation();

  useEffect(() => {
    const l = supportedLanguages.find(l => (l.iso639_1 || l.iso3166_2) === i18n.resolvedLanguage);
    document.documentElement.lang = l.locale;
  }, [i18n.resolvedLanguage]);

  return (
    <div className={style.mainComponent}>
      <Header />
      <Main />
      <Footer />
      <CookieConsentModal />
      <LexxWidget />
    </div>
  );
};

MainContainer.propTypes = {};

export const MainContainerWithScrollListener = withScrollListener(MainContainer);

export default process.env.REACT_APP_WITH_SCREEN_BREAKPOINT_NAME === 'true' && process.env.NODE_ENV !== 'production'
  ? withScreenBreakpointName(MainContainerWithScrollListener)
  : MainContainerWithScrollListener;
