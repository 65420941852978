import {createRef} from 'react';

const state = {
  startedItems: createRef(),
  listeners: new Set(),
  incrementStartedItems: () => {
    if (state.startedItems.current) {
      state.startedItems.current++;
    } else {
      state.startedItems.current = 1;
    }
    state.listeners.forEach(listener => listener(state.startedItems.current));
  },
  decrementStartedItems: () => {
    state.startedItems.current--;
    state.listeners.forEach(listener => listener(state.startedItems.current));
  },
  getStartedItems: () => state.startedItems.current,
  isTransitionInProgress: () => !!state.getStartedItems(),
  subscribe: callback => {
    state.listeners.add(callback);
    return () => state.listeners.delete(callback);
  }
};

export default state;
