import * as T from 'three';

import fragment from './shaders/fragment.glsl';
import vertex from './shaders/vertex.glsl';

class Gradient {
  constructor(canvas, colors) {
    this.colors = colors.map(color => new T.Color(color));
    this.canvas = canvas;

    this.scene = new T.Scene();

    this.camera = new T.PerspectiveCamera(75, this.canvas.clientWidth / this.canvas.clientHeight, 0.1, 100);
    this.camera.position.set(0, 0, 0.3);
    this.scene.add(this.camera);

    this.renderer = new T.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true
    });
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    this.clock = new T.Clock();

    this.setLights();
    this.setGeometry();
    this.render();
    this.setResize();
  }

  setLights() {
    this.ambientLight = new T.AmbientLight(new T.Color(1, 1, 1, 1));
    this.scene.add(this.ambientLight);
  }

  setGeometry() {
    this.planeGeometry = new T.PlaneGeometry(1.2, 1.2, 128, 128);
    this.planeMaterial = new T.ShaderMaterial({
      side: T.DoubleSide,
      fragmentShader: fragment,
      vertexShader: vertex,
      uniforms: {
        uColor: {value: this.colors},
        time: {value: 0}
      }
    });

    this.planeMesh = new T.Mesh(this.planeGeometry, this.planeMaterial);
    this.scene.add(this.planeMesh);
  }

  setColors(colors) {
    this.colors = colors.map(color => new T.Color(color));
    this.planeMaterial.uniforms.uColor.value = this.colors;
  }

  render() {
    const elapsedTime = this.clock.getElapsedTime();

    this.planeMaterial.uniforms.time.value = elapsedTime * 0.025;

    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.render.bind(this));
  }

  setResize() {
    this.canvas.addEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    this.camera.aspect = this.canvas.clientWidth / this.canvas.clientHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }
}

export default Gradient;
