import PropTypes from 'prop-types';
import Link from '../Link';
import style from './ButtonLink.module.scss';
import classnames from 'classnames';

const ButtonLink = ({className, size, ...otherProps}) => {
  const buttonClassName = classnames(style.buttonLink, style[`${size}Size`], className);
  return <Link {...otherProps} className={buttonClassName} />;
};

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

ButtonLink.defaultProps = {
  size: 'md'
};

export default ButtonLink;
