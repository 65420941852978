import style from './CellScene.module.scss';
import {Canvas} from '@react-three/fiber';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Suspense, useRef, useSyncExternalStore} from 'react';
import state from '../../SlideRoutes/slideRoutesStore';
import {AdaptiveDpr, AdaptiveEvents, Preload, Stage} from '@react-three/drei';

const CellScene = ({className, children}) => {
  const shouldToShow = useSyncExternalStore(state.subscribe, () => !state.isTransitionInProgress());
  const ref = useRef();

  return shouldToShow ? (
    <div className={classnames(style.container, className)}>
      <Canvas shadows dpr={[-1, 1]} camera={{fov: 24}}>
        <Suspense fallback={null}>
          <Stage
            controls={ref}
            preset="upfront"
            intensity={0}
            contactShadow={false}
            shadows={false}
            environment="lobby">
            <AdaptiveDpr pixelated />
            <AdaptiveEvents />
            <Preload all />
            {children}
          </Stage>
        </Suspense>
      </Canvas>
    </div>
  ) : null;
};

CellScene.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element
};

export default CellScene;
