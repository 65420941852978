function secondsOfYear() {
  const dateObj = new Date();
  let dateInit = new Date(dateObj.getFullYear(), 0, 0);
  dateInit = Math.round(dateInit.getTime() / 1000);

  const dateCurrent = Math.round(dateObj.getTime() / 1000);

  return dateCurrent - dateInit;
}

export default secondsOfYear;
