import PropTypes from 'prop-types';
import style from './Placeholder.module.scss';
import originStyle from '../Functional.module.scss';
import ContentWrapper from '../../../components/ContentWrapper';
import PlaceholderTextLine from '../../../components/PlaceholderTextLine';
import classnames from 'classnames';

const Placeholder = ({children}) => {
  return (
    <section className={classnames(originStyle.container, style.container)}>
      <ContentWrapper>
        <div className={originStyle.titleWrapper}>
          <PlaceholderTextLine className={style.title} />
        </div>
        <div className={originStyle.content}>{children}</div>
      </ContentWrapper>
    </section>
  );
};

Placeholder.propTypes = {
  children: PropTypes.any
};

export default Placeholder;
